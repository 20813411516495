

export const shoppingCartConstants = {

    ADD: 'ADD_TO_CART',

    DELETE : 'DELETE_FROM_CART',

    CLEAR_SHOPPING_CART : 'CLEAR_SHOPPING_CART',

    SET_SHOPPING_CART : 'SET_SHOPPING_CART',

    DELETE_ITEM_FROM_CART : 'DELETE_ITEM_FROM_CART',

    SET_BILLING_SHOPPING_CART : "SET_BILLING_SHOPPING_CART"
};
