import React,{useEffect,useState} from 'react'
import { PDFViewer,PDFDownloadLink } from '@react-pdf/renderer';
import { MyDocument } from '../PDFGeneration/PDFGeneration'
import { connect } from 'react-redux'
import './PDFViewer.css'
import { userService } from '../../_services';
import { isBrowser } from 'react-device-detect';



const PDFViewerComponent = (props) =>{

  const [orderShoppingCart,setOrderShoppingCart] = useState({})
  const [loaded,setLoaded] = useState(false)
  const [order,setOrder] = useState(null)
  useEffect(()=>{
    userService.getOrderById(props.match.params.id)
      .then(res => {
        setOrderShoppingCart(res.data)
        setOrder(res.data)
        setLoaded(true)
       

      })
  },[])

  return (
<div className="pdf-page">
    {/* {loaded&&<PDFViewer className="pdf-document">
    <MyDocument  shoppingCart={orderShoppingCart} orderId={props.match.params.id} order={order}/>
  </PDFViewer>} */}


    {loaded? isBrowser ?
      <PDFViewer className="pdf-document">
      <MyDocument  shoppingCart={orderShoppingCart} orderId={props.match.params.id} order={order}/>
    </PDFViewer>
    :
    <PDFDownloadLink document={  <MyDocument  shoppingCart={orderShoppingCart} orderId={props.match.params.id} order={order}/>} fileName="Facture Alien Dimension.pdf">
    {({ blob, url, loading, error }) =>
      loading ? 'Chargement de la facture...' : 'Telecharger votre facture!'
    }
  </PDFDownloadLink>
  :null}
</div>
  
)};


export {PDFViewerComponent}