import React, { useState, useEffect } from 'react'
import './ShoppingCart.css'
import {ShoppingCartOutlined,PlusOutlined,MinusOutlined,CaretLeftOutlined,CaretRightOutlined} from '@ant-design/icons';
import {Dropdown,Button} from 'antd'
import {Link, Redirect} from 'react-router-dom'
import {orderActions, redirectionActions, shoppingCartActions, userActions} from '../../_actions'
import {history} from '../../_helpers'
import {connect} from 'react-redux'
import { userService } from '../../_services';
import { toast } from 'react-toastify';
import {firebaseAnalytics} from '../../firebase'


const ShoppingCart = ( props ) => {

    const [amount,setAmount] = useState(0)
    const [orderId,setOrderId] = useState(null)
    const [finalizeOrder,setFinalizeOrder] =useState(false)

    useEffect(()=>{
        props.updateUrl('/shoppingcart')
        document.documentElement.scrollTop = 0
        let total = 0
        setTimeout(()=>{
            props.shoppingCart.items.map(item => {
                total = total+ item.price * item.quantity})  
                setAmount(total)
        },500)

       
           
        
     
    },[props])

    const handlShoppingCartDropdownClick = ( ) => {
    }

    const onAddItem = (id ) =>{
        const product = {
            id
        }
        props.addToCart(product)
    }

    const onRemoveItem = (id,quantity ) =>{
        if(quantity>1){
            const product = {
                id
            }
            props.removeFromCart(product)
        }else{
            toast.dismiss()
            toast("Veuillez supprimer l'article.")
        }

      
    }
    const onRedirectToHome = () => {
        // window.location = "http://localhost:3000/#/"
         history.push('/')
    }
    const onRedirectToProfile = () => {
    }

    const handleDeleteItem =(title) => {
        props.deleteItem(title)
    }

    const handleRedirectToCheckout = ( ) => {
        if(props.shoppingCart.items.length > 0) {
            firebaseAnalytics.logEvent("USER_PROCEED_TO_CHECKOUT");

            history.push("/checkoutOrder") }
        else {
            toast.dismiss()
            toast('Veuiller ajouter au moins un article.')}
    
    }

 
    if(props.authentication.loggedIn && finalizeOrder){
        return <Redirect to={`/checkoutOrder/${orderId}`}/>
    }else{
        return (
            <div className="shopping-cart-page">
                
                <div className="shopping-cart__navbar">
                    <span>Pannier</span>
                    <div className="shopping-cart__navbar__links">
                        <span className="redirect-to-home" onClick={onRedirectToHome}>Accueil</span>
                        <span>&#62;</span>
                        <span>Pannier</span>
                    </div>
                </div>  
                <div className="subtotal-bar">
                    <span><b>Subtotal</b></span><span>TND{props.shoppingCart.total}</span></div>  
                <div className="shopping-cart__body">
                    <Link to="/products"><div className="poursuivre-cta">Poursuiver l'achat</div></Link>
                    <div className="shopping-cart__main-section">
                        <div className="shopping-cart__items">
                            {props.shoppingCart.items.length > 0 ?
                                props.shoppingCart.items.map(item => <div className="shopping-cart__item">
                                <div className="item__cover" >
                                <Link to={`/product/${item.id}/overview`}><img src={item.coverUrl} alt="shoppind-cart-item-image" style={{width:"100%",height:"100%"}} className="item-image" /></Link>
                                {item.forgroundUrl&&<Link to={`/product/${item.id}/overview`}><img src={item.forgroundUrl} alt="shoppind-cart-item-foreground-image"  className="item-foreground-image" /></Link>}    
                                </div>
                                <div className="item__info">
                                    <div className="item__info__header">
                                        <h3 className="item__title">Paquet {item.title}</h3>
                                        <span className="item__price">{item.price} TND</span>
                                    </div>
                                    <p className="item__description">{item.description}.</p>
                                    <div className="item__quantity-section">
                                        <span className="item__quantity-label">Quantité</span>
                                        <CaretLeftOutlined className="quantity-cta" style={{fontSize:".9rem"}} onClick={()=> onRemoveItem(item.id,item.quantity)}/>
                                        <span className="item__quantity">{item.quantity}</span>
                                        <CaretRightOutlined className="quantity-cta" style={{fontSize:".9rem"}}   onClick={()=> onAddItem(item.id)}/>
                                    </div>
                                    <span className="delete-item-cta" onClick={()=>handleDeleteItem(item.title)}>supprimer</span>
                                   
                                </div>
                            </div>)
                            :
                            <span style={{marginTop:"5rem"}}>Votre panier est vide</span>
                            }
                            {props.shoppingCart.items.length > 0 && <div className="empty-cart-section" >
                                <span onClick={props.clearShoppingCart} className="empty-shopping-cart-cta">Vider le panier</span>
                                </div>}
                        </div>
                        <div className="shopping-cart__side-section">
                            <span className="side-section__label">Total</span>
                            <div className="subtotal-section">
                                <span className="subtotal-label">Frais de paquets</span>
                                <span className="subtotal">{props.shoppingCart.total} TND </span>
                            </div>
                            {props.shoppingCart.items.length > 0 && <div className="dilivery-fees-section">
                                <span className="delivery-fees-label">Frais de livraison </span>
                                <span className="delivery-fees">8.00 TND</span>
                            </div>}
                            <hr/>
                            <div className="total-section">
                                <span className="total-label">Net à payer</span>
                                <span className="total">{props.shoppingCart.items.length>0 ? props.shoppingCart.total + 8:0} TND</span>
                            </div>
                            <hr/>
                            <div className="buy-cta" onClick={handleRedirectToCheckout}>Acheter</div>
                        </div>
                    </div>
                   
                    <div className="suggestions">
                        <p style={{color:"rgba(0,0,0,1)"}}>Vous aimer peut-etre</p>
                        <div className="suggestions__items">
                            
                            {props.experiences.items.length> 0 && props.experiences.items.map((item,index) => <div className="suggestions__item">
                                <div className="suggestion__item__cover">
                                <Link to={`/product/${item.id}/overview`}><img src={item.body.coverUrl} alt="shoppind-cart-item-image" style={{width:"100%",height:"100%"}} className="item-image" /></Link>
                                <Link to={`/product/${item.id}/overview`}><img src={item.body.forgroundUrl} alt="shoppind-cart-item-foreground-image"  className="item-foreground-image" /></Link>                                
                                </div>
                                <div style={{padding:"3%"}}>
                                    <p style={{color:"rgba(0,0,0,1)",width:"40vw"}} className="suggestion__item__title"><b>{item.body.title.fr}</b></p>
                                    <span style={{color:"#4c4c4c"}}><b>{ item.body.discountPrice || item.body.price } dt</b> {item.body.discountPercentage >0  && <strike>{item.body.price} dt</strike>}</span>
                                </div>
                              
                            </div>)
                            }
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
    
}

 const mapState=(state)=>{
     const {shoppingCart,authentication,experiences} = state
     return {
         shoppingCart,
         authentication,
         experiences
     }
 }
 const actionCreators={addToCart : shoppingCartActions.addToCart,
     removeFromCart : shoppingCartActions.removeFromCart,
     createOrder : orderActions.createOrder,
     showModal : userActions.showModal,
     updateUrl : redirectionActions.updateUrl,
     clearShoppingCart : shoppingCartActions.clearShoppingCart,
     deleteItem : shoppingCartActions.deleteItem
     
 }
 const connectedShoppingCart = connect(mapState,actionCreators)(ShoppingCart)

export {connectedShoppingCart as ShoppingCart }