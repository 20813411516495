import React,{useState,useEffect} from 'react'
import './ExperienceCard.css'
import {Link} from 'react-router-dom'
import {Button} from 'antd'
import {HeartOutlined,HeartFilled,LikeOutlined,LikeFilled,ShoppingCartOutlined,HeartTwoTone,CheckOutlined} from '@ant-design/icons'
import { shoppingCartActions, userActions } from '../../../_actions'
import {connect} from 'react-redux'
import {isBrowser,isMobile} from 'react-device-detect'
import {history} from '../../../_helpers'
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import {firebaseAnalytics} from '../../../firebase'





const  ExperienceCard= (props)=>{

	const [backgroundImage,setBackgroundImage] =useState(null)
	const [mountCount,setMountCount ] = useState(0)
	const [likes,setLikes] = useState(null)

	const refresh =( ) => {
		setMountCount(mountCount+1)
	}

	useEffect(()=>{
		setBackgroundImage(`url(${props.coverUrl})`)
		setLikes(props.likes)
	},[])

	useEffect(()=>{
	setLikes(props.likes)
	},[props.likes])


	const likeExperience=(id,likes,category)=>{
		if(!props.updating){
			const found = props.user.body.liked.find(element => element=== id);

        if(!found)
        {
         
        const newLikedExperiences = props.user.body.liked;
        newLikedExperiences.push(id)

		const req1= {
			experience :{
				id,
				category,
				likes :1,
				token : props.token
			}
		}

        const req2 = {
            user :{
                id: props.user.id,
                liked :newLikedExperiences,
                token : props.token
            }
        }
        props.updateLikes(req1,req2)
        }else{
            toast('Vous avez deja aimé cette experiences.')
        }
		}else{
		}
        
    }
    /**
     * Handles removing an experience from the wishlist by calling the UpdateFavorite action creator
     *
     */



    const unlikeExperience=(id,likes,category)=>{
		if(!props.updating){
			if(props.user.body.liked.find(element => element === id))
        {
            const newLikedExperiences = props.user.body.liked.filter(experience=> experience!==id);

			const req1 = {
				experience :{
					id,
					category,
					likes :-1,
					token : props.token
				}
			}

        const req2 = {
            user :{
                id: props.user.id,
                liked :newLikedExperiences,
                token:props.token
            }

        }

        props.updateLikes(req1,req2)

        }else{
            toast("Vouz n'avez pas aimé cette experience.")
        }
		}else{
		}
		

      }


    const addFavorite=(id,name,description,price,category,coverUrl,forgroundUrl)=>{
		if(!props.updating){
			const found = props.user.body.wishlists.find(element => element.id === id);

			if(!found)
			{
			const newwishlists = props.user.body.wishlists;
			newwishlists.push({id,name,description,price,category,coverUrl,forgroundUrl })
			const req = {
				id: props.user.id,
				wishlists :newwishlists,
				token : props.token
			}
			props.updateFavorite(req)
			}else{
				toast("vouz ne pouvez pas ajouter cetter experience au favoris")
			}
		}else{
		}
       
    }
    /**
     * Handles removing an experience from the wishlist by calling the UpdateFavorite action creator
     *
     */



    const removeFavorite=(id)=>{

		if(!props.updating){
			const newwishlists = props.user.body.wishlists.filter(experience=> experience.id!==id);
         const req = {
             id: props.user.id,
             wishlists :newwishlists,
             token:props.token
         }
         props.updateFavorite(req)
		}else{
			console.log('update in progress')
		}
         

           
       }





	// function onAddFavorite(id,title,description,price,category,coverUrl,forgroundUrl){
	// 	try {
	// 		props.addFavorite(id,title,description,price,category,coverUrl,forgroundUrl)
	
	// 	} catch (error) {
	
	// 	}
	// }

	// function onRemoveFavorite(id){

	// 	props.removeFavorite(id)
	// }

	// function onlikeExperience(id,likes,category){
	// 	try {
		
	// 		props.likeExperience(id,likes,category)
			
	// 	} catch (error) {
		
	// 	}
	// }

	// function onUnlikeExperience(id,likes,category){
	// 	console.log("HERE 1")
	// 	try {
	// 		props.unlikeExperience(id,likes,category)

	// 	} catch (error) {
		
	// 	}
		
	// }

	const onAddExperienceToCard= ( id,coverUrl,title,description,price,category,foregroundImage,discountPrice,discountPercentage)=>{
		
		const productPrice = discountPrice> 0 ? discountPrice : price
		
		const product= {
			id,
			coverUrl,
			title,
			description,
			price : productPrice,
			category,
			initialPrice:price,
			foregroundImage,
			discountPrice,
			discountPercentage
		}
		try {
			props.addToCart(product)
			toast('Experience ajoutée au panier')
			// ReactGA.event({
			// 	category: 'User-Experience',
			// 	action: 'Added Experience To Cart'
			//   });
			firebaseAnalytics.logEvent("ADD_TO_CART")

		} catch (error) {
			toast("Erreur")
		}
	}

	const redirectToProduct = ( ) => {
		// window.location = `http://localhost:3000/#/product/${props.id}/overview`
		 history.push(`/product/${props.id}/overview`)
	}


	const showLoginModal = () => {
		props.showLoginModal()
	}

    return(
	
		<div className="experience-card">
			
			<div className="expereience__cover__container">
				<Link to={`/product/${props.id}/overview`}><img className="experience__cover" src={props.coverUrl} alt="experience-cover"/></Link>
				<img src={props.foregroundImage} onClick={redirectToProduct} className={`experience-foreground-image ${isMobile && props.showCtas?"experience-foreground-image-scroll" : ""}`} alt="foreground-image"></img>
				<div className={`experience__ctas ${isMobile && props.viewedExperienceIndex === props.index? "experience__ctas-scroll": ""}`}>
					{props.loggedIn ?
                	       props.user.body.wishlists.filter(fav => fav.id === props.id).length===0? 
				 		<div className="checkbox-disabled"  onClick={()=> addFavorite(props.id,props.title,props.description,props.price,props.category,props.coverUrl,props.foregroundImage)}></div>
					: 
						<div className="checkbox-enabled" onClick={()=> removeFavorite(props.id)} > <CheckOutlined className="checked-icon"/></div> 
				:null}

	 				{props.loggedIn ?
                        (props.user.body.liked.filter(liked => liked === props.id)).length===0? 
                      	<Button type="primary" ghost shape="circle" className="cta-button" icon={<HeartOutlined   className="cta-icon"/>} onClick={()=> likeExperience(props.id,props.likes,props.category)} />
					: 
					 	<Button type="primary" ghost shape="circle" className="cta-button" icon={<HeartFilled  className="cta-icon active"/>} onClick={()=> unlikeExperience(props.id,props.likes,props.category)} />
				:null}
				
				 {/*props.loggedIn ?
                          addedToFavorites? 
				 		  <div className="checkbox-enabled" onClick={()=> onRemoveFavorite(props.id)} > <CheckOutlined className="checked-icon"/></div> 

				 		:
				 		<div className="checkbox-disabled"  onClick={()=> onAddFavorite(props.id,props.title,props.description,props.price,props.category,props.coverUrl,props.foregroundImage)}></div>

				 :null*/}	
				 	{/*props.loggedIn ?
                         !liked? 
                           <Button type="primary" ghost shape="circle" className="cta-button" icon={<HeartOutlined   className="cta-icon"/>} onClick={()=> onlikeExperience(props.id,props.likes,props.category)} />
                   
				 		:
				 		 <Button type="primary" ghost shape="circle" className="cta-button" icon={<HeartFilled  className="cta-icon active"/>} onClick={()=> onUnlikeExperience(props.id,props.likes,props.category)} />
					
					 :null*/}
					
					<span className="experience__likes" >{!props.loggedIn &&<HeartFilled className="logged-out-heart" onClick={showLoginModal}/>}{likes}</span>
				</div>
				<Button type="primary" ghost className={`cta-button-buy ${isMobile && props.viewedExperienceIndex === props.index? "cta-button-buy-scroll" :""}`} icon={<ShoppingCartOutlined/>} onClick={()=> onAddExperienceToCard(
					props.id,props.coverUrl,
					props.title,
					props.description,
					props.price,
					props.category,
					props.foregroundImage,
					props.discountPrice,
					props.discountPercentage)}  >Acheter</Button>
			</div>
			<div className="experience__info">
				<span className="experience__category">{props.category}</span>
				<Link to={`/product/${props.id}`}><h3 className="expreince__title">{props.title}</h3></Link>
				<span className="experience__price">{props.discountPrice > 0 ? props.discountPrice : props.price} TND </span>
				<hr/>
				<p className="experience__description">{props.description}</p>
			</div>

		</div>

    )
}
	
const mapState= ( state )=>{
	const { shoppingCart } = state
	const {loggedIn,user,token,updating} = state.authentication
	return{
		shoppingCart,
		loggedIn,
		user,
		token,
		updating
	}
}

const actionCreators = {
	addToCart : shoppingCartActions.addToCart,
	updateLikes :userActions.updateLikes,
	updateFavorite : userActions.updateFavorite,
	showLoginModal : userActions.showModal
}

const connectedExperienceCard = connect(mapState,actionCreators)(ExperienceCard)
export  {connectedExperienceCard as ExperienceCard}