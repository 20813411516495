import React,{ useState,useEffect } from 'react'
import './ResetPasswordRequestPage.css'
import { Form, Input, Button,Alert} from 'antd';
import { userService } from '../../_services';
import {history} from '../../_helpers'
import {connect} from 'react-redux'
import { userActions } from '../../_actions';

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 20 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 20 },
};


function ResetPasswordRequestPage(props){

  const [resetPasswordRequestSuccess,setResetPasswordRequestSuccess ] = useState(false)
  const [resetPasswordRequestError,setResetPasswordRequestError ] = useState(false)

  useEffect(()=>{
    props.hideModal()
  },[])

        const onFinish = values => {
          userService.resetPassword({email:values.email}) 
            .then(res => {
              setResetPasswordRequestError(false)
              setResetPasswordRequestSuccess(true)
              setTimeout(()=>{
                // window.location="http://localhost:3000/#/"
                 history.push('/')
              },2000)
            })
            .catch(err=> {
              setResetPasswordRequestError(true)
              setResetPasswordRequestSuccess(false)
            })
           
        };
        
      
        const onFinishFailed = errorInfo => {
        };
    return(
        <div className="reset-password-request-page">
            <div className="reset-password-request-section">
                <h3>Réinitialisation du mot de passe</h3>
                <div style={{marginTop:'5%'}}>
                <p style={{marginLeft:'2%'}}>Veuillez entrer votre e-mail sur lequel vous recevrez le lien de réinitialisation du mot de passe</p>
                <Form   
                      style={{width :"100%"}}
                      {...layout}
                      name="basic"
                      initialValues={{ remember: true }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                    >
                    {resetPasswordRequestSuccess? <Alert message="We've sent a password reset email to your adress" type="success"/>:null}
                    {resetPasswordRequestError? <Alert message="make sure you entered a valid email" type="Error"/>:null}
                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                          {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                          },
                          {
                            required: true,
                            message: 'Please input your E-mail!',
                          },
                        ]}
                        >
                        <Input />
                    </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                          Valider
                        </Button>
                      </Form.Item>
                </Form>
                </div>
            </div>
        </div>
    )
}

const mapState = ( state ) => {
  const {modalVisible} = state.authentication
  return [
    modalVisible
  ]
}

const actionCreators= {
  hideModal : userActions.hideModal
}

const connectedResetPasswordRequestPage = connect(mapState,actionCreators)(ResetPasswordRequestPage)

export {connectedResetPasswordRequestPage as ResetPasswordRequestPage}