import React, { useEffect, useState } from 'react';
import './AntComment.css'
import { Comment, Tooltip, Avatar,Button } from 'antd';
import moment from 'moment';
import { DownCircleOutlined, DownCircleFilled,UpCircleOutlined,UpCircleFilled } from '@ant-design/icons';
import {Link} from 'react-router-dom'
import { connect } from 'react-redux';
import { communitiesService } from '../../../_services';
import { userActions } from '../../../_actions';

const AntComment = (props) => {

  const [liked, setLiked] = useState(props.user.body &&props.user.body.liked? props.user.body.liked.includes(props.contributionId):null)
  const [disliked,setDisliked] = useState(props.user.body &&props.user.body.disliked?props.user.body.disliked.includes(props.contributionId):null)
  const [numberOfLikes,setNumberOfLikes]=useState(props.likes)
  const [numberOfUpdates,setNumberOfUpdates]=useState(0)
  const [likeButtonDisabled,setLikeButtonDisabled] =useState(false)
  const [dislikeButtonDisabled,setDislikeButtonDisabled] =useState(false)


 

  useEffect(()=>{
    if(props.loggedIn &&props.user.body.liked &&props.user.body.disliked){
      setLiked(props.user.body.liked.includes(props.contributionId))
      setDisliked(props.user.body.disliked.includes(props.contributionId))
      setNumberOfLikes(props.likes)
    }
   
  },[])
  useEffect(()=>{
    if(props.loggedIn){
      setLiked(props.user.body.liked.includes(props.contributionId))
      setDisliked(props.user.body.disliked.includes(props.contributionId))
      setNumberOfLikes(props.likes)
    }
    refresh()
  },[props])

  const refresh=()=>{
    setNumberOfUpdates(numberOfUpdates+1)
  }
/*
  useEffect(()=>{
    if(props.type==="Comment"){
      setLiked(props.user.body.likedComments.includes(props.contributionId))
    }else if(props.type==="Post"){
      setLiked(props.user.body.likedPosts.includes(props.contributionId))
    }else if(props.type==="Reply"){
      setLiked(props.user.body.likedReplies.includes(props.contributionId))
    }
  },[])
  */


  const like = () => {
    setLikeButtonDisabled(true)
    const newLikedContributions = props.user.body.liked
    newLikedContributions.push(props.contributionId)

    let req={
      contribution : {id:props.contributionId,
      likes:numberOfLikes+1,
      token:props.token },
      user:{
        id:props.user.id,
        liked: newLikedContributions,
        disliked : props.user.body.disliked
      }
    }

    if(!disliked){
      if(props.type==="Post") {
 
        communitiesService.updatePostVotes(req)
          .then(res=> {
      
            setLiked(true)
            setNumberOfLikes(res.data.posted.resp.body.likes)
            props.updateUserUpvotes(req.contribution.id,"UPVOTE")
            setLikeButtonDisabled(false)
          }).catch(err=> console.error(err))
      }
      else if(props.type==="Comment") {

          communitiesService.updateCommentVotes(req)
          .then(res=> {
      
            setLiked(true)
            setNumberOfLikes(res.data.posted.resp.body.likes)
            props.updateUserUpvotes(req.contribution.id,"UPVOTE")
            setLikeButtonDisabled(false)
          }).catch(err=> console.error(err))
      }
      else if(props.type==="Reply"){

          communitiesService.updateReplyVotes(req)
          .then(res=> {
    
            setLiked(true)
            setNumberOfLikes(res.data.posted.resp.body.likes)
            props.updateUserUpvotes(req.contribution.id,"UPVOTE")
            setLikeButtonDisabled(false)
          }).catch(err=> console.error(err))
        }
    }else{

      props.updateUserUpvotes(req.contribution.id,"REMOVE_DOWNVOTE")
      req.contribution.likes= req.contribution.likes+1
      req.user.disliked=req.user.disliked.filter(contribution => contribution !== props.contributionId)
  
   
      setDisliked(false)
      

      if(props.type==="Post") {

        communitiesService.updatePostVotes(req)
          .then(res=> {
     
            setLiked(true)
            setNumberOfLikes(res.data.posted.resp.body.likes)
            props.updateUserUpvotes(req.contribution.id,"UPVOTE")
            setLikeButtonDisabled(false)
          }).catch(err=> console.error(err))
      }
      else if(props.type==="Comment") {

          communitiesService.updateCommentVotes(req)
          .then(res=> {

            setLiked(true)
            setNumberOfLikes(res.data.posted.resp.body.likes)
            props.updateUserUpvotes(req.contribution.id,"UPVOTE")
            setLikeButtonDisabled(false)
          }).catch(err=> console.error(err))
      }
      else if(props.type==="Reply"){

          communitiesService.updateReplyVotes(req)
          .then(res=> {
         
            setLiked(true)
            setNumberOfLikes(res.data.posted.resp.body.likes)
            props.updateUserUpvotes(req.contribution.id,"UPVOTE")
            setLikeButtonDisabled(false)
          }).catch(err=> console.error(err))
        }
    }
   
 
  };


  const unlike = () => {

    if(liked){ 
  
      const newLikedContributions = props.user.body.liked.filter(contributionId => contributionId!== props.contributionId)


      let req={
        contribution : {
        id:props.contributionId,
        likes:numberOfLikes-1,
        token:props.token },
        user:{
          id:props.user.id,
          liked: newLikedContributions,
          disliked : props.user.body.disliked
        }
      }



    if(props.type==="Post") {
      communitiesService.updatePostVotes(req)
        .then(res=> {
     
          setLiked(false)
          setNumberOfLikes(res.data.posted.resp.body.likes)
          props.updateUserUpvotes(req.contribution.id,"REMOVE_UPVOTE")
          
        }).catch(err=> console.error(err))
    }
    else if(props.type==="Comment") {
        communitiesService.updateCommentVotes(req)
        .then(res=> {
        
          setLiked(false)
          setNumberOfLikes(res.data.posted.resp.body.likes)
          props.updateUserUpvotes(req.contribution.id,"REMOVE_UPVOTE")
        }).catch(err=> console.error(err))
    }
    else if(props.type==="Reply"){
        communitiesService.updateReplyVotes(req)
        .then(res=> {
    
          setLiked(false)
          setNumberOfLikes(res.data.posted.resp.body.likes)
          props.updateUserUpvotes(req.contribution.id,"REMOVE_UPVOTE")
          
        }).catch(err=> console.error(err))
      }}
 
  };

  const dislike = () => {
    setDislikeButtonDisabled(true)
    const newLikedContributions = props.user.body.liked
    const newDisikedContributions = props.user.body.disliked
    newDisikedContributions.push(props.contributionId)
    let req={
      contribution : {id:props.contributionId,
      likes:numberOfLikes-1,
      token:props.token },
      user:{
        id:props.user.id,
        liked: newLikedContributions,
        disliked : newDisikedContributions
      }
    }
    if(!liked){
 
    
      if(props.type==="Post") {

        communitiesService.updatePostVotes(req)
          .then(res=> {
          
            setDisliked(true)
            setNumberOfLikes(numberOfLikes-1)
            props.updateUserUpvotes(req.contribution.id,"DOWNVOTE")
            setDislikeButtonDisabled(false)
          }).catch(err=> console.error(err))
      }
      else if(props.type==="Comment") {
        //test
          communitiesService.updateCommentVotes(req)
          .then(res=> {
        
            setDisliked(true)
            setNumberOfLikes(numberOfLikes-1)
            props.updateUserUpvotes(req.contribution.id,"DOWNVOTE")
            setDislikeButtonDisabled(false)
          }).catch(err=> console.error(err))
      }
      else if(props.type==="Reply"){

          communitiesService.updateReplyVotes(req)
          .then(res=> {
          
           setDisliked(true)
            setNumberOfLikes(res.data.posted.resp.body.likes)
            props.updateUserUpvotes(req.contribution.id,"DOWNVOTE")
            setDislikeButtonDisabled(false)
          }).catch(err=> console.error(err))
        }
    }else{
      props.updateUserUpvotes(req.contribution.id,"REMOVE_UPVOTE")
      req.user.liked = req.user.liked.filter(contribution => contribution!== props.contributionId)
      setLiked(false)
      req.contribution.likes = req.contribution.likes-1
     
     
     
      if(props.type==="Post") {

        communitiesService.updatePostVotes(req)
          .then(res=> {
    
            setDisliked(true)
            setNumberOfLikes(res.data.posted.resp.body.likes)
            props.updateUserUpvotes(req.contribution.id,"DOWNVOTE")
            setDislikeButtonDisabled(false)
          }).catch(err=> console.error(err))
      }
      else if(props.type==="Comment") {

          communitiesService.updateCommentVotes(req)
          .then(res=> {
        
            setDisliked(true)
            setNumberOfLikes(res.data.posted.resp.body.likes)
            props.updateUserUpvotes(req.contribution.id,"DOWNVOTE")
            setDislikeButtonDisabled(false)
          }).catch(err=> console.error(err))
      }
      else if(props.type==="Reply"){

          communitiesService.updateReplyVotes(req)
          .then(res=> {
          
            setDisliked(true)
            setNumberOfLikes(res.data.posted.resp.body.likes)
            props.updateUserUpvotes(req.contribution.id,"DOWNVOTE")
            setDislikeButtonDisabled(false)
          }).catch(err=> console.error(err))
        }
    } 
   
 
  };


  const undislike = () => {
    
    if(disliked){ 
      const newDisikedContributions = props.user.body.disliked.filter(contributionId => contributionId!== props.contributionId)
      
      let req={
        contribution : {
        id:props.contributionId,
        likes:numberOfLikes+1,
        token:props.token },
        user:{
          id:props.user.id,
          liked: props.user.body.liked,
          disliked : newDisikedContributions
        }
      }

    if(props.type==="Post") {
      communitiesService.updatePostVotes(req)
        .then(res=> {
         
          setDisliked(false)
          setNumberOfLikes(res.data.posted.resp.body.likes)
          props.updateUserUpvotes(req.contribution.id,"REMOVE_DOWNVOTE")
          
        }).catch(err=> console.error(err))
    }
    else if(props.type==="Comment") {
        communitiesService.updateCommentVotes(req)
        .then(res=> {
         
          setDisliked(false)
          setNumberOfLikes(res.data.posted.resp.body.likes)
          props.updateUserUpvotes(req.contribution.id,"REMOVE_DOWNVOTE")
          
        }).catch(err=> console.error(err))
    }
    else if(props.type==="Reply"){
        communitiesService.updateReplyVotes(req)
        .then(res=> {
        
          setDisliked(false)
          setNumberOfLikes(res.data.posted.resp.body.likes)
          props.updateUserUpvotes(req.contribution.id,"REMOVE_DOWNVOTE")
          
        }).catch(err=> console.error(err))
      }}
 
  };

  return (
    
    <Comment
    data-test="ant-comment"
      author={<Link to={`/user/${props.author.id}`}>{props.author.name}</Link>}
      avatar={
        <Avatar
          src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
          alt="Han Solo"
        />
      }
      content={<div   style={{margin:"1rem 0"}} >
      <h3>{props.subject}</h3>
      <p>{props.body}</p>
      <div className="actions">
        
        {props.loggedIn  && !props.notValidated?
          !liked?
          <Tooltip title="Like">
          <Button shape="circle" onClick={like} disabled={likeButtonDisabled}  icon={ <UpCircleOutlined style={{fontSize:"1.5rem"}} />} className="action-like"/>
          </Tooltip>
          :
          <Tooltip title="Remove Like">
           <Button shape="circle" onClick={unlike}  icon={ <UpCircleFilled style={{fontSize:"1.5rem",color:"black"}} />} className="action-unlike"/>
          </Tooltip>
          :null
          
        }
             <span >{numberOfLikes}</span>
        {
          props.loggedIn && !props.notValidated ?
          !disliked?
          <Tooltip title="Dislike">
          <Button shape="circle" onClick={dislike} disabled={dislikeButtonDisabled}  icon={ <DownCircleOutlined style={{fontSize:"1.5rem"}} />} className="action-dislike"/>
          </Tooltip>
          :
          <Tooltip title="Remove Dislike">
          <Button shape="circle" onClick={undislike}  icon={ <DownCircleFilled style={{fontSize:"1.5rem",color:"black"}} />} className="action-undislike"/>
          </Tooltip>
          :null
        }
      </div>
     
      </div>
      }
      datetime={
        <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
          <span>{moment().fromNow()}</span>
        </Tooltip>
      }
    />
  );
};

const mapStateToProps=(state)=>{
  const {user,loggedIn,token} = state.authentication
  return{
    user,loggedIn,token
  }
}

const ActionCreators={
  updateUserUpvotes: userActions.updateUserUpvotes
}

const connectedAntComment = connect(mapStateToProps,ActionCreators)(AntComment)

export {connectedAntComment as AntComment}

