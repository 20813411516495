import { communitiesConstants } from '../_constants';
/**
 * defining the initial state of the communities attribute in redux store
 *    - items : array of communities got from DataBase,
 *    - loaded : boolean indicating whether communities are loaded,
 */
const initialState= {items : [],loaded : false}

/**
 * exporting a function that handles every action related to the communities
 * 
 * @param {object} state this is the state of the communities attribute in redux store
 * @param {object} action this is the action dispatched from communities reducer
 * @exports Reducers__Communities
 */
export function communities(state = initialState, action) {
  switch (action.type) {
    case communitiesConstants.GETALL_REQUEST:
      return {
        ...state
      };
    case communitiesConstants.GETALL_SUCCESS:

      return {
        ...state,
        loaded:true,
        items: action.communities
      };
    case communitiesConstants.GETALL_FAILURE:
      return {
          ...state, 
        error: action.error
      };
    default:
      return state
  }
}