import {serverConstants} from '../_constants'
import axios from 'axios'

/**
 * exporting an object containting the defined functions that affect the communities requests as methods
 * @exports Services__Communities
 */
export const communitiesService = {
    getAll,
    getPostsByCommunity,
    getCommentsByPost,
    getCommunityById,
    getRepliesByComment,
    addCommentToPost,
    addReplyToComment,
    submitPost,
    getQuizzes,
    submitQuizz,
    getLastSubmissionCreationDate,
    generateCertificate,
    updatePostVotes,
    updateCommentVotes,
    updateReplyVotes,
    getQuizzById,
    getPostById
};

/**
 * sending a Get request with no params to get an array of communities
 * @returns a promise
 */
function getAll() {
    return axios.get(`${serverConstants.DOMAIN_NAME}/communities`);
}

/**
 * sending a Get request to get an array of posts from a specific community
 * @param {string} id id of the community
 * @returns a promise
 */
function getPostsByCommunity(communityId, createdOn=0,keywords ='',category='',likes=9999999 ){
    return axios.get(`${serverConstants.DOMAIN_NAME}/communities/${communityId}/posts/search?keywords=${keywords}&type=${category}&createdOn=${createdOn}&likes=${likes}`)
}
/**
 * sending a Get request to get a specific community 
 * @param {string} id id of community
 */
function getCommunityById(id){
    return axios.get(`${serverConstants.DOMAIN_NAME}/communities/${id}`)
}

/**
 * sending a Get request to get an array of comments from a specific post 
 * @param {string} id  id of post
 * @returns a promise
 */
function getCommentsByPost(id){
    return axios.get(`${serverConstants.DOMAIN_NAME}/communities/posts/${id}/comments`)
}
/**
 * sending a Get request to get an array of replies from a specific comment
 * @param {string} id if of comment
 * @returns a promise
 */
function getRepliesByComment(id){
    return axios.get(`${serverConstants.DOMAIN_NAME}/communities/posts/comments/${id}/replies`)
}

/**
 * sending a POST request to add a comment to specific post
 * @param {object} req this is the request body with the comment's infos and the post id
 * @returns a promise
 */
function addCommentToPost(req){

    return axios.post(`${serverConstants.DOMAIN_NAME}/communities/posts/comments/add`,req)
}
/**
 * sending a POST request to add a reply to a specific comment
 * @param {object} req this is the request body with the replie's infos and the comment id
 * @returns a promise
 */
function addReplyToComment(req){
    return axios.post(`${serverConstants.DOMAIN_NAME}/communities/posts/comments/replies/add`,req)
}

/**
 * sending a POST request to add a post to a specific community
 * @param {object} req this is the request body with the post's infos and the Community id
 * @returns a promise
 */
function submitPost(req){
    return axios.post(`${serverConstants.DOMAIN_NAME}/communities/posts/add`,req)
}

function getQuizzes(){
    return axios.get(`${serverConstants.DOMAIN_NAME}/quizzes/`) 
}


function submitQuizz(req){
    return axios.post(`${serverConstants.DOMAIN_NAME}/quizzes/submit`,req) 
}

function getLastSubmissionCreationDate(userId,quizId){
    return axios.get(`${serverConstants.DOMAIN_NAME}/quizzes/submissions/get?quizId=${quizId}&userId=${userId}`) 
}


function generateCertificate(req){
    return axios.post(`${serverConstants.DOMAIN_NAME}/certificates/generate`,req) 
}

function updatePostVotes(req){
    return axios.put(`${serverConstants.DOMAIN_NAME}/communities/posts/like`,req) 
}
function updateCommentVotes(req){
    return axios.put(`${serverConstants.DOMAIN_NAME}/communities/posts/comments/like`,req) 
}
function updateReplyVotes(req){
    return axios.put(`${serverConstants.DOMAIN_NAME}/communities/posts/comments/replies/like`,req) 
}


function getQuizzById(id){
    return axios.get(`${serverConstants.DOMAIN_NAME}/quizzes/${id}`)
}

function getPostById(id){
    return axios.get(`${serverConstants.DOMAIN_NAME}/communities/posts/${id}`)
}