    import React,{useEffect, useState} from 'react'
import './NewLandingPage.css'
import {Button} from 'antd'
import {connect} from 'react-redux'
import { redirectionActions } from '../../_actions'
import {history} from '../../_helpers'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { InteractiveCard } from '../InteractiveCard/InteractiveCard'
import { browserName} from 'react-device-detect'

const NewLandingPage= (props) =>{

      useEffect(()=>{
        document.documentElement.scrollTop = 0
        document.getElementById('navbar').style.display = ""
        document.getElementById('footer').style.display = ""
        props.updateUrl("/")
    },[])

    const redirectToStore = (title) => {

        const foundExperience = props.experiences.items.find(experience => experience.body.title.fr.includes(title))
        // window.location=`http://localhost:3000/#/product/${foundExperience.id}/overview`
         history.push(`/product/${foundExperience.id}/overview`)
    }

    const redirectToProduct =(title) => {
        const foundExperience = props.experiences.items.find(experience => experience.body.title.fr.includes(title))
        // window.location=`http://localhost:3000/#//product/${foundExperience.id}/tech-spechs`
         history.push(`/product/${foundExperience.id}/tech-spechs`)
    }


    const handleDownload =()=>{
        window.location = 'https://play.google.com/store/apps/details?id=com.AlienDimension.AlienDimension'
    }

    const firstCard={
        imageIndex : 1,
        content : [
            {title:"+100 de modeles",description :"Pour une comprehension optimale de l'anatomie humaine"},
            {title:"5X Plus rapide",description: "une experience en RA vaux mieux qu'un long discours une experience en RA vaux mieux qu'un long discours"}
        ],
        button:{
            text:"Tester",
        }
    }

    const secondCard={
        imageIndex : 2,
        content : [
            {title:"Un livre accrédité par des médecins",description :"Toutes les informations sont accréditées par des médecins et des professeurs."},
            {title:"Un code d’accés",description: "Une carte à gratter contient le code d'accès pour l’experience anatomie du corps humain."}
        ],
        button:{
            text:"Acheter",
            experience:'Anatomie'
        }
    }

    const thirdCard={
        imageIndex : 3,
        content : [
            {title:"La lune",description :"Toutes les informations sont accréditées par des Toutes les informations sont accréditées par Toutes les informations sont accréditées par des Toutes les informations sont accréditées par"},
        ],
        button:{
            text:"Tester",
        }
    }

    const fourthCard={
        imageIndex : 4,
        content : [
            {title:"Un livre en tri-langue",description :"Un livre contenant plus d’information sur lesplanètes et les satellites dans notre système solaire."},
            {title:"Un code d’accés",description: "Une carte à gratter contient le code d'accès pour l’experience anatomie du corps humain."}
        ],
        button:{
            text:"Acheter",
            experience:'solaire'
        }
    }

    return(
        <div className="landing-page" >
            
                
                <div className="landing-section" style={{width:"100vw",overflow:"hidden"}}>
                    <Carousel
                    autoPlay={true}
                    interval={5000}
                    infiniteLoop
                    >
                    <div className="first-carousel-item">
                        <h1 className="first-carousel-item__header">Donner vie à votre imagination<br/>Explorer de nouveaux horizons</h1>

                        <img className="curiosity" src={require('../../assets/images/Curiosity.png')}/>
                        <img className="moon" src={require('../../assets/images/Moon.png')}/>
                        <img className="dns" src={require('../../assets/images/DNS.png')}/>
                    </div>
                    <div className="second-carousel-item">
                        <h1 className="second-carousel-item__header">
                        La Nouvelle Tendance d'Apprentissage 
                        </h1>
                        <div className="second-carousel-content">
                            <div className="first">
                            <span>Profiter de diverses expériences éducatives interactives, basées sur la pratique.
                                Transformer votre propre environnement; votre bureau, votre chambre et même le salon de votre maison,  en un laboratoire bien équipé ! 
                                </span><br/>
                                <button className="telecharger-button" onClick={handleDownload}><b>Télécharger</b></button>
                            </div>
                            <div className="second-carousel-content-right"></div>
                        </div>
                    </div>

                 
                    </Carousel>
                </div>
                
                <div className="anatomie-section">
                    <h2 className="anatomie-section__header">{props.experiences.loaded && props.experiences.items[0].body.title.fr}</h2>
                    <h3 className="anatomie-section__sub-header">{props.experiences.loaded && props.experiences.items[0].body.caption.fr}</h3>
                    <p className="anatomie-section__description">{props.experiences.loaded && props.experiences.items[0].body.shortDescription.fr}</p>
                    <div className="anatomie-section__cta-section">
                        <span className="anatomie-section__cta-section__item" onClick={()=>redirectToProduct('Anatomie')}><span>Plus d'informations</span> &#62;</span>
                        <span className="anatomie-section__cta-section__item" onClick={()=> redirectToStore('Anatomie')}><span>Acheter</span> &#62;</span>
                    </div>
                    <div className="anatomie-section__images">
                        <div className="anatomie-section__images__left">
                            <InteractiveCard card={firstCard} />
                        </div>
                        <div className="anatomie-section__images__center">

                        </div>
                        <div className="anatomie-section__images__right">
                             <InteractiveCard card={secondCard} />

                        </div>
                    </div>
                </div>

                <div className="solar-system-section">
                    <h2 className="solar-system-section__header">{props.experiences.loaded && props.experiences.items[1].body.title.fr}</h2>
                    <h3 className="solar-system-section__sub-header">{props.experiences.loaded && props.experiences.items[1].body.caption.fr}</h3>
                    <p className="solar-system-section__description">{props.experiences.loaded && props.experiences.items[1].body.shortDescription.fr}
                    </p>
                    <div className="solar-system-section__cta-section">
                        <span className="solar-system-section__cta-section__item" onClick={()=>redirectToProduct('Exploration')}>Plus d'informations &#62;</span>
                        <span className="solar-system-section__cta-section__item" onClick={()=>redirectToStore('Exploration')}>Acheter &#62;</span>
                    </div>
                    <div className="solar-system-section__images">
                        <div className="solar-system-section__images__left">
                            <InteractiveCard card={thirdCard} />
                        </div>
                        <div className="solar-system-section__images__center">

                        </div>
                        <div className="solar-system-section__images__right">
                            <InteractiveCard card={fourthCard} />
                        </div>
                    </div>
                </div>

                <div className="fourth-section">
                    <div className="fourth-section__main">
                        <h1 className="fourth-section__main__header">Imaginer, Créez et Augmenter!</h1>
                        <p className="fourth-section__main__description">
                            
                            <b>Imaginer</b> vivre une expérience éducative <b>hors du commun</b>. 
                            Télécharger l'application mobile Alien Dimension et <b>Créer</b> votre propre Labo de sciences.
                            Faites vites et <b>Augmenter</b> votre réalité.
                            
                        </p>
                        <button className="fourth-section__cta" onClick={handleDownload}>Essayer la démo</button>
                    </div>
                    <div className="fourth-section__image">

                    </div>
                </div>
        </div>
    )
}

const mapState = (state) => {
    const {experiences,redirection} = state
    return  {
        experiences,
        redirection
    }
}

const actionCreators = {
    updateUrl : redirectionActions.updateUrl
}

const connectedNewLandingPage = connect(mapState,actionCreators)(NewLandingPage)
export {connectedNewLandingPage as NewLandingPage}