import { Form, Input, Button, Checkbox, Select} from 'antd';
import { UserOutlined, LockOutlined,LoadingOutlined,PhoneOutlined,MailOutlined } from '@ant-design/icons';
import React from 'react';
import './RegisterPage.css'
import {Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { messaging} from '../../firebase'
import { userActions } from '../../_actions';
import  { history } from '../../_helpers'
import { LoginHooks } from '../GoogleLogin/LoginHooks';
import { LoginWithFacebook } from '../LoginWithFacebook';
import {browserName} from 'react-device-detect'

/**
 * The Alien dimension Register Page
 * 
 * @exports Register__Page
 */

class RegisterPage extends React.Component{

    constructor(props){
      super(props)

      this.state= {
        user :{},
        submitted : false,
        registerButtonClicked: false,
        loginButtonPressed : false
      }
    }

/**
 * Renders the register form
 * 
 */

    componentDidMount(){

      document.getElementById('navbar').style.display = "none"
      document.getElementById('footer').style.display = "none"

    
    }

notificationPermission = async ()=> {
  let permissionGranted = false;
  try {
      /* request permission if not granted */
      if (Notification.permission !== 'granted') {
          await messaging.requestPermission();
      }
      /* get instance token if not available */
      if (localStorage.getItem("INSTANCE_TOKEN")) {
          permissionGranted = true;
      } else {
          const token = await messaging.getToken(); // returns the same token on every invocation until refreshed by browser
        
          localStorage.setItem("INSTANCE_TOKEN", token);
          permissionGranted = true;
      }
  } catch (err) {
      console.log(err);
     
  } finally {
      return permissionGranted;
  }
}


    handleLoginButtonPressed = ( )=>{
      this.setState({loginButtonPressed:true})
    }

    render(){

      const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
      const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
      };
        /**
         * This function handles sending the form content to the server to create a new profile
         * 
         */
        const onFinish = async values => {
          this.setState({registerButtonClicked:true})
          let req = {}
          const permission = await this.notificationPermission()
         this.setState({submitted : true})
        
          if(permission){
            req = {
              user :{
                name: values.First_Name,
                surname : values.Last_Name,
                password : values.password,
                email : values.Email,
                subscriptions:['GENERAL','EXPERIENCES'],
                phoneNumber : parseInt(values.Phone_Number),
                role : "user",
                subscriptionToken: localStorage.getItem("INSTANCE_TOKEN")
              },
              subscription : {
                token : localStorage.getItem("INSTANCE_TOKEN"),
                topic : 'GENERAL'
              }
            }
          }else {
            req = {
              user :{
                name: values.First_Name,
                surname : values.Last_Name,
                password : values.password,
                email : values.Email,
                phoneNumber : parseInt(values.Phone_Number),
                role : "user"
              },
              subscription : {
                token : ' '
              }
            }
           
          }
          
            if(req.user.name&& req.user.password && req.user.phoneNumber && req.user.surname &&req.user.email)
            {
              this.props.register(req)
              
            }

           
        };

        

  
        const handleRedirectToLogin = ( ) => {
          // window.location="http://localhost:3000/#/sign-in"
              history.push('/sign-in')
        }
        if(this.props.loggedIn && this.state.loginButtonPressed)window.location="http://localhost:3000/#/"
  
        const { Option } = Select;
        if(!this.props.loggedIn || !this.state.registerButtonClicked){
          return(
      
          <div className="register-page">
             <Link to='/'><div className="logo-section"></div></Link>
            <div className="register-container">

          
              <Form
              className="register-form"
             name="normal_register"
             initialValues={{ remember: true }}
             onFinish={onFinish}
           >
              <Form.Item
                 className="form-item"
                 name="Last_Name"
                 rules={[{ required: true, message: 'Please input your Last Name!' }]}
               >
                 <Input className="form-item__input" placeholder="Nom de famille" />
               </Form.Item>
             <Form.Item
               className="form-item"
               name="First_Name"
               rules={[{ required: true, message: 'Please input your First Name!' }]}
             >
               <Input className="form-item__input"   placeholder="Prenom" />
             </Form.Item>
              
    
               <Form.Item
                 className="form-item"
                 name="Phone_Number"
                 rules={[{ required: true, message: 'Please input a valid Phone Number!' }]}
               >
                 <Input placeholder="Numéro du téléphone"  className="form-item__input"/>
               </Form.Item>
    
               <Form.Item
                 className="form-item"
                 name="Email"
                 rules={[{ required: true,type:'email', message: "please input a valid Email" }]}
               >
                 <Input  placeholder="Adresse E-mail" className="form-item__input" />
                
               </Form.Item>
            
    
               <Form.Item
                 className="form-item"
                 name="password"
                 rules={[{ required: true,message:'Please input your password' }]}
               >
                 <Input
                   className="form-item__input"
                   type="password"
                   placeholder="Mot de passe"
                 />
               </Form.Item>

               <Form.Item
                 className="form-item"
                 name="retyped-password"
                 rules={[{ required: true, message: 'Please Re-Type Password!' }]}
               >
                 <Input
                   className="form-item__input"
                   type="password"
                   placeholder="Re-saisir le mot de passe"
                 />
               </Form.Item>
    
               <Form.Item name="Gender" rules={[{required : true, message:'select a gender'}]} >
                   <Select style={{ width : 100 }} placeholder="Sexe">
                   <Option value="Male">Mâle</Option>
                   <Option value="Female">Femelle</Option>
                 </Select>
               </Form.Item>

             {this.props.registerError ? <span style={{color:"rgb(100,100,100)"}}>{this.props.registerError.error.replace('Error: Error: Error:','')}</span> :null}

                
                
               <Form.Item className="register-button">
                 
                 <Button type="primary" htmlType="submit" className="register-form-button" style={{ height: 30}}>
                   S'inscrire
                   {this.props.registering || this.props.loggingIn? <LoadingOutlined/>:null}
                 </Button>
               </Form.Item>
               <Form.Item onClick={this.handleLoginButtonPressed} style={{marginTop:'12%'}}>
               {!browserName.toUpperCase().includes('FACEBOOK') && <LoginHooks  style={{margin:"25px"}}/>}
              <LoginWithFacebook  backgroundColor="blue"/>
               </Form.Item>
             

               <Form.Item
                   name="agreement"
                   valuePropName="checked"
                   
                 >
                   <div style={{display:"flex",justifyContent:"center",marginTop:"5%",alignItems:"center"}}>
                       <span className="information">Vous avez déja un compte ?</span><span onClick={handleRedirectToLogin} className="register-page__login-cta">Se connecter</span>

                   </div>
                 </Form.Item>

             </Form>
           
             </div>
          </div>
          )
        }else{
          return <Redirect to={`/newuser/${this.props.user.id}`}/>
        }
        
      
    }
 
}

function mapState(state) {
  const { registerError,registerSuccess,user,loggedIn,registering,loggingIn } = state.authentication;
  return { registerError,registerSuccess,user,loggedIn,registering,loggingIn };
}

const actionCreators = {
  register: userActions.register
}

const connectedRegisterPage = connect(mapState, actionCreators)(RegisterPage);

export {connectedRegisterPage as RegisterPage}