import React, { useEffect, useState } from "react";
import './App.css';
import {connect} from 'react-redux'

import {Navbar} from '../Navbar'
import {LandingPage} from '../LandingPage'
import {ProductsPage} from '../ProductsPage'
import {ProductPage} from '../ProductPage'

import {RegisterPage} from '../RegisterPage'
import {Route,Redirect, Router} from 'react-router-dom'
import {newUser} from '../newUser'
import {history } from '../../_helpers'
import { ResetPasswordRequestPage } from '../ResetPasswordRequestPage';
import { NewCommunityPage } from '../NewCommunityPage';
import { UserSettings } from "../UserSettings";
import { QuizzPage } from '../QuizzPage'
import { PostPage } from "../PostPage.js";
import { NewLandingPage } from "../NewLandingPage";
import { NewNavBar } from "../NewNavBar";
import {ShoppingCart} from '../ShoppingCart'
import { PDFViewerComponent } from '../PDFViewer'
import { NewCheckoutPage } from "../NewCheckoutPage";
import {PaymentSuccess} from '../PaymentSuccess'
import {PaymentFailure} from '../PaymentFailure'
import {NewProductsPage} from '../NewProductsPage'
import {ExperienceCard} from '../Shared/ExperienceCard/ExperienceCard'
import {EmailVerification} from '../EmailVerification'
import {SignInPage} from '../SignInPage'
import {Footer} from '../Footer'
import {RedirectionComponent} from '../RedirectionComponent'
import {AboutUs} from '../AboutUs'
import {InteractiveCard} from '../InteractiveCard'
import {NotificationsPage} from '../NotificationsPage'
import {OrderConfirmation} from '../OrderConfirmation'
import { NewTestingScroll } from "../NewTestingScroll/NewTestingScroll";
import { userService } from "../../_services";
import { userActions } from "../../_actions";
import { browserName, engineName, getUA } from "react-device-detect";
import ReactGA from 'react-ga';
import RouteChangeTracker from "../RouteChangeTracker/RouteChangeTracker";
import { My404Component } from '../My404Component'
 



// const TRACKING_ID = "UA-197109762-2"; // YOUR_OWN_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);
/**
 * App component
 *
 * @returns react router component with all the web app's routes
 * @exports App__Component
 */
function App(props){

    const [mountCounter,setMountCounter] = useState(0)

    const refresh = ( ) => {
        setMountCounter(mountCounter+1)
    }

    useEffect(()=>{

        if(props.loggedIn){
            const req= {
                refreshToken: props.refreshToken
            }
             props.getNewToken(req)
        }

    },[props.refreshToken])
    if(props.loggedIn){
        setTimeout(()=>{
            const req= {
                refreshToken: props.refreshToken
            }

             props.getNewToken(req)
             refresh()
        },1800000)
    }

    // if(window.location.toString().includes('/#/')){
    //     const loc = window.location.toString().replace('https://www.aliendimension.com/',"http://localhost:3000/#/sign-in")
    //     window.location =  loc.slice(0,loc.length-2)
    // }

    return (
      <Router history={history}>
              <div className="app" data-test='appComponent'>
                  {/* <RouteChangeTracker/> */}
                  <Navbar data-test='navbarComponent' history={history}/>
                  <Route data-test='LandingPage' exact path="/" component={NewLandingPage}/>
                  <Route data-test='ProductsPage' path="/products" component={ProductsPage}/>
                  <Route data-test='ProductPage' path="/product/:id/:section" component={ProductPage}/>
                  <Route data-test='RegisterPage' path="/register" component={RegisterPage}/>
                  <Route data-test='NewCommunityPage' exact path="/communities/:id" component={NewCommunityPage}/>
                  <Route data-test='NewUser' exact path="/newuser/:id" component={newUser}/>
                  <Route data-test='ResetPasswordRequestPage' path="/resetpasswordrequest" component={ResetPasswordRequestPage}/>
                  <Route data-test='UserSettings' path="/newuser/:id/settings" component={UserSettings}/>
                  <Route data-test="QuizzPage" path="/quizzes/:id" component={QuizzPage}/>
                  <Route data-test="PostPage" path="/communities/:communityName/post/:id" component={PostPage}/>
                  <Route data-test="NewLandingPage" exact path="/landingpage" component={NewLandingPage}/>
                  <Route data-test="NewNavBar" exact path="/newnavbar" component={NewNavBar}/>
                  <Route data-test="shoppingcart" exact path="/shoppingcart" component={ShoppingCart}/>
                  <Route data-test="checkoutpage" exact path="/checkoutOrder" component={NewCheckoutPage}/>
                  <Route data-test="PaymentSuccess" exact path="/paymentSuccess/" component={PaymentSuccess}/>
                  <Route data-test="PaymentFailure" exact path="/paymentFailure/" component={PaymentFailure}/>
                  <Route data-test="PDFViewer" exact path="/generate-bill/:id" component={PDFViewerComponent}/>
                  <Route data-test="NewProductsPage" exact path="/NewProductsPage/" component={NewProductsPage}/>
                  <Route data-test="ExperienceCard" exact path="/ExperienceCard/" component={ExperienceCard}/>
                  <Route data-test="TestingScroll" exact path="/TestingScroll/" component={NewTestingScroll}/>
                  <Route data-test="EmailVerification" exact path="/EmailVerification/" component={EmailVerification}/>
                  <Route data-test="SignInPage" exact path="/sign-in/" component={SignInPage}/>
                  <Route data-test="RedirectionComponent" exact path="/redirect" component={RedirectionComponent}/>
                  <Route data-test="AboutUs" exact path="/about-us" component={AboutUs}/>
                  <Route data-test="InteractiveCard" exact path="/interactive-card" component={InteractiveCard}/>
                  <Route data-test="NotificationsPage" exact path="/notifications" component={NotificationsPage}/>
                  <Route data-test="OrderConfirmation" exact path="/order-confirmation" component={OrderConfirmation}/>
                  <Route  path="/produit/anatomie/">
                      <Redirect to="/product/ZHhg3g13ChLnOXWGdado/overview"/>
                  </Route>
                  <Footer/>
              </div>

          </Router>
    );
}

const mapState=(state)=>{
    const {shoppingCart,order} = state
    const {user,loggedIn,refreshToken} = state.authentication
    return{
        shoppingCart,
        order,
        user,
        loggedIn,
        refreshToken
    }
}

const actionCreators = {
    getNewToken : userActions.getNewToken
}

const connectedApp = connect(mapState,actionCreators)(App)
export {connectedApp as App};
