import React, { useState, useEffect } from 'react'
import './PreviewSection.css'
import {NewTestingScroll} from '../NewTestingScroll/NewTestingScroll'
import { Modal } from 'antd';
import { isBrowser } from 'react-device-detect';
import { PhoneAnimation } from '../PhoneAnimation/PhoneAnimation';

const PreviewSection = ( props ) =>{
    const [videoVisible,setVideoVisible] = useState(false)
    const [maxScrollTop,setmaxScrollTop] = useState(null)
    useEffect(()=>{
        setTimeout(() => {
            if(document.getElementById('landing-section-holder')){
                setmaxScrollTop(document.getElementById('landing-section-holder').offsetHeight)
    
            }
        }, 555);
        
    },[])

    const showVideo = () =>{
        setVideoVisible(true)
    }

    const hideVideo = () => {
        setVideoVisible(false);
      };

  

    return(<div id="preview-section">
                    {isBrowser ? 
                    <div  className="product-page__first-section " >
                    <h1 id="overview" className="first-section__catch-phrase">Sous Plusieurs Angles</h1>
                    <p className="first-section__description">Plus que 100 entrées offrant des dizianes de <br/> 
                                descriptions complétes pour une <br/> compréhension optimale de <br/>
                                l'anatomie humaine</p>

                    {props.experience.body.discountPrice && <p className="first-section__promotion">{props.experience.body.discountPercentage}% de remise: <b>{props.experience.body.discountPrice}TND</b> au lieu de <strike>{props.experience.body.price}TND</strike><br/>
                    <span className="voir-video-cta" onClick={showVideo}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 10 10" style={{transform:"translateY(.5rem)translateX(.2rem)"}}>
                      <path id="Add-more-items" d="M2.5,0,5,5H0Z" transform="translate(5) rotate(90)" fill="#4c4c4c"/>
                    </svg>
                     Voir video
                        </span></p>}
                        {/* <Modal visible={videoVisible} onCancel={handleCancel}>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/q95-SDumsDY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen width="1000" height="600px"></iframe>
                        </Modal> */}
                       {videoVisible && <div onClick={hideVideo} className="video-modal">
                        <iframe src="https://www.youtube.com/embed/q95-SDumsDY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen ></iframe>
                        </div>}

                    {/* <div id="first-animation" className="first-section__animation-holder" style={{width:"100vw",height:"100vh"}}> */}
                    <div id="first-animation" className="first-section__animation-holder" >
                         <div style={{position:"sticky",top:"0%"}}>
                            {/* <NewTestingScroll path="../../assets/animations/First-Section/S1" frameCount={45}/> */}
                           
                             <NewTestingScroll/>
                            
                            
                        </div>

                    </div>
                    </div>
                    :
                    <div id="landing-section-holder" style={{height:"500vh"}} >
                        <div  className="product-page__first-section sticky">
                                <h1 id="overview" className="first-section__catch-phrase">Sous Plusieurs Angles</h1>
                                <p className="first-section__description">Plus que 100 entrées offrant des dizianes de descriptions complétes pour une compréhension optimale de l'anatomie humaine</p>

                                {props.experience.body.discountPrice && <p className="first-section__promotion">{props.experience.body.discountPercentage}% de remise: <b>{props.experience.body.discountPrice}dt</b> au lieu de <strike>{props.experience.body.price}dt</strike><br/>
                                <span className="voir-video-cta" onClick={showVideo}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 10 10" style={{transform:"translateY(.5rem)translateX(.2rem)"}}>
                                  <path id="Add-more-items" d="M2.5,0,5,5H0Z" transform="translate(5) rotate(90)" fill="#4c4c4c"/>
                                </svg>
                                 Voir video
                                    </span></p>}
                                
                                   {videoVisible && <div onClick={hideVideo} className="video-modal">
                                    <iframe src="https://www.youtube.com/embed/q95-SDumsDY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen ></iframe>
                                    </div>}

                                {/* <div id="first-animation" className="first-section__animation-holder" style={{width:"100vw",height:"100vh"}}> */}
                                <div id="first-animation" className="first-section__animation-holder" >
                                     <div style={{position:"sticky",top:"0%"}}>
                                        {/* <NewTestingScroll path="../../assets/animations/First-Section/S1" frameCount={45}/> */}
                                        {maxScrollTop && <PhoneAnimation maxScrollTop={maxScrollTop}/>}
                                   
                                        
                                    </div>

                                </div>
                        </div>
                    </div>}
                    
                    <div className="product-page__second-section">
                            {/* <div id="second-animation" className="second-section__animation-holder" style={{width:"30vw",height:"90vh"}}> */}
                            <div id="second-animation" className="second-section__animation-holder" >
                                {/* <NewTestingScroll path="../../assets/animations/second-section/S2" frameCount={50}/> */}
                            </div>
                            <div className="second-section__info">
                                <h1 className="second-section__catch-phrase-1">5X Rapide</h1>
                                <h1 className="second-section__catch-phrase-2">WOOOOOW.</h1>
                                <p className="second-section__description">Avec Alien Dimension, vous faites <br/>appel aux sens, aux émitions et aux <br/>
                                fonctions cognitives du cerveau, en <br/>exploitant les aspects les plus<br/>
                                puissants de la rétention de concepts<br/>complexes.</p>
                                <span className="second-section__catch-phrase-3">Apprendre rapidement avec la RA.</span>
                                <span className="voir-comment-cta"><a href='http://www.aliengates.com/alien-dimension/ra-education/ 
                                '>Voir comment</a></span>
                            </div>
                    </div>
                    <div className="product-page__third-section">
                            <div className="third-section__header">
                                <div className="alien-dimension-logo"></div>
                                <div className="alien-dimension-logo-text">Ton premier Livre RA</div>
                            </div>
                            <h1 className="third-section__catch-phrase">Informations Accréditées.</h1>
                            <p className="third-section__description">
                                Notre Livre RA accrédité pour l'enseignement et l'apprentissage est<br/>
                                complétée sur mesure en fonction des besoins individuels et pédagogiques.
                            </p>
                        
                            <div className="third-section__animation-holder"> 
                            </div>
                    </div>
    </div>)
}

export {PreviewSection}