import React,{useEffect,useState} from 'react';
import Logo from '../../assets/logos/AD.png'
import Signature from '../../assets/logos/signature.png'

import { Page, Text, View, Document, StyleSheet,Image,Font } from '@react-pdf/renderer';
import { order } from '../../_reducers/order.reducer';

// Create styles




const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    padding:"5%"
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    fontSize:15
  },
  header:{
    width :"100%",
    padding:"5%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    fontSize:14
  },
  logo:{
    width:"60%",
    height:"100%",
  },
  facture_header:{
    width:"40%"
  },
  address_section:{
    width :"100%",
    padding:"5%",
    fontSize:12,
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
  },
  residency_address:{
    width:"50%"
  },
  payment_address:{
    width:"50%",
    marginLeft:25
  },
  products_section:{
    width:"100%"
  },
  products_section_header:{
    fontSize:12,
    borderBottomWidth:1,
    display:"flex",
    flexDirection:"row",
    height:"13%",
    alignItems:"center"
  },
  products_section_item:{
    padding:"1% 0%",

    fontSize:12,
    display:"flex",
    flexDirection:"row",
    alignItems:"center"
  },
  products_header_Name:{
    width:"25%",
    borderRightColor:"#8c8c8c"
  },
  products_header_Price:{
    width:"15%",
    textAlign:"center",
    borderRightColor:"#8c8c8c"
  },
  products_header_Quantity:{
    width:"15%",
    textAlign:"center",
    borderRightColor:"#8c8c8c"

  },
  products_header_Subtotal:{
    width:"15%",
    textAlign:"right",

  },
  total_section:{
    textAlign:"right",
    border:"1px solid black",
    width:"45%",
    height:"7vh",
    marginTop:20,
    fontSize:10,
  },
  gray :{
    color:"#505050"
  },
  total_section_label :{
    width:"50%",
    textAlign:"left"
  },
  total_section_value:{
    width:"50%",
    textAlign:"right"
  },
  total_section_label_gray:{
    width:"50%",
    textAlign:"left",
    color:"#505050"
  }
  ,
  total_section_value_gray:{
    width:"50%",
    textAlign:"right",
    color:"#505050"
  },
  total_section_label_grand:{
    width:"50%",
    textAlign:"left",
    fontSize:13 
  }
  ,
  total_section_value_grand:{
    width:"50%",
    textAlign:"right",
    fontSize:13 
  },
  products_section_item__secondary:{
    padding:"1% 0%",
    fontSize:10,
    display:"flex",
    flexDirection:"row",
    alignItems:"center"
  }

});


// Create Document Component
const MyDocument = (props) => {

  const [dd,setDD] = useState(null)
  const [mm,setMM] = useState(null)
  const [yyyy,setYYYY] = useState(null)

  useEffect(()=>{

    var today = new Date(props.order.createdOn);
    var dd = today.getDate();
    
    var mm = today.getMonth()+1; 
    var yyyy = today.getFullYear();
    if(dd<10) 
    {
        dd='0'+dd;
    } 
    
    if(mm<10) 
    {
        mm='0'+mm;
    } 

   
    setDD(dd)
    setMM(mm)
    setYYYY(yyyy)
  },[])


  const subTotal=props.shoppingCart.total
  const tva=props.shoppingCart.total*19/100
  const reduction = props.shoppingCart.total/10
  const deliveryFees = 8

  if(props.shoppingCart.items<1 ){
    return <h1>Loading...</h1>
  }else{
    return(
  
      <Document >
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
              <Image src={Logo} style={{width:"30%",height:"90%"}}></Image>
    
          
            <View style={styles.facture_header}>
              <Text style={{fontSize:25}}>Facture </Text>
              <View style={{display:"flex",flexDirection:"row"}}>
                <Text style={{width:"40%"}}>Numero</Text>
                <Text style={styles.gray,{fontSize:11,marginTop:2.5}}> {props.order.bill}</Text>
              </View>
              <View style={{display:"flex",flexDirection:"row"}}>
                <Text style={{width:"40%"}}>Référence</Text>
                <Text style={styles.gray,{fontSize:11,marginTop:2.5}}> {props.orderId}</Text>
              </View>
              <View style={{display:"flex",flexDirection:"row"}}>
                <Text style={{width:"40%"}}>Date</Text>
                <Text style={styles.gray,{fontSize:11,marginTop:2.5}} >{dd&&mm&&yyyy ? dd.toString()+'-'+mm.toString()+'-'+yyyy.toString():null}</Text>
              </View>
            </View>
    
          </View>
          <View style={styles.address_section}> 
              <View style={styles.residency_address}>
                <Text style={{marginBottom:5,fontSize:15}}>Adresse de livraison</Text>
                {/* <Text style={styles.gray}>Hedi Ben Fraj</Text> */}
                <Text style={styles.gray}>{props.shoppingCart.deliveryAddress}</Text>
                {/* <Text style={styles.gray}>Tunis</Text>
                <Text style={styles.gray}>27146059</Text> */}
              </View>
              <View style={styles.payment_address}>
                <Text style={{marginBottom:5,fontSize:15}}>Adresse de paiement</Text>
                {/* <Text style={styles.gray}>Hedi Ben Fraj</Text> */}
                <Text style={styles.gray}>{props.shoppingCart.residencyAddress}</Text>
                {/* <Text style={styles.gray}>Tunis</Text>
                <Text style={styles.gray}>27146089</Text> */}
              </View>
          </View>
          <View style={styles.products_section}>
            <View style={styles.products_section_header}>
                <Text style={styles.products_header_Name}>Désignation</Text>
                <Text style={styles.products_header_Price}>Unité</Text>
                <Text style={styles.products_header_Quantity}>QTE</Text>
                <Text style={styles.products_header_Price}>Prix Unitaire HT (TND)</Text>
                <Text style={styles.products_header_Subtotal}>Remise (%)</Text>
                <Text style={styles.products_header_Subtotal}>Total aprés remise (TND)</Text>
            </View>
            {props.shoppingCart.items && props.shoppingCart.items.map(item => <View style={{
    borderBottomWidth:1,
    borderBottomColor:"#999999",}}>
              <View style={styles.products_section_item}>

                <Text style={styles.products_header_Name}>{item.boughtExperienceName}</Text>
                <Text style={styles.products_header_Price}>Paquet</Text>
                <Text style={styles.products_header_Quantity}>{item.quantity}</Text>
                <Text style={styles.products_header_Price}>{item.boughtExperiencePrice}</Text>
                <Text style={styles.products_header_Subtotal}>{item.boughtExperienceDiscountPercentage}</Text>
                <Text style={styles.products_header_Subtotal}>{item.boughtExperienceDiscountPercentage>0 ?item.quantity * item.boughtExperienceDiscountPrice:item.quantity * item.boughtExperiencePrice}</Text>
              </View>

              <View style={styles.products_section_item__secondary}>

                  <Text style={styles.products_header_Name}>Livre {item.boughtExperienceName}</Text>
                  <Text style={styles.products_header_Price}></Text>
                  <Text style={styles.products_header_Quantity}></Text>
                  <Text style={styles.products_header_Price}>{item.boughtExperiencePrice}</Text>
                  <Text style={styles.products_header_Subtotal}></Text>
                  <Text style={styles.products_header_Subtotal}></Text>
              </View>
              <View style={styles.products_section_item__secondary}>

                <Text style={styles.products_header_Name}>Carte à grater</Text>
                <Text style={styles.products_header_Price}></Text>
                <Text style={styles.products_header_Quantity}></Text>
                <Text style={styles.products_header_Price}>0.00</Text>
                <Text style={styles.products_header_Subtotal}></Text>
                <Text style={styles.products_header_Subtotal}></Text>
            </View>
            </View>)}
            <View style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"flex-end"}}>
              <View style={styles.total_section}>
                <View style={{display:"flex",flexDirection:"row",marginTop:3}}>
                  <Text style={styles.total_section_label}>TOTAL HT</Text>
                  <Text style={styles.total_section_value} >{props.order.total - props.order.stamp - props.order.fees} DT</Text> 
                </View>
                <View style={{display:"flex",flexDirection:"row",marginTop:3}}>
                  <Text style={styles.total_section_label_gray} >TVA (NA à la TVA) 0% </Text>
                  <Text style={styles.total_section_value_gray} >0.00 DT</Text>
                </View>
                <View style={{display:"flex",flexDirection:"row",marginTop:3}}>
                  <Text style={styles.total_section_label_gray}>TTC </Text>
                  <Text style={styles.total_section_value_gray}  >{props.order.total - props.order.stamp - props.order.fees} DT</Text>
                </View>
                <View style={{display:"flex",flexDirection:"row",marginTop:3}}>
                  <Text style={styles.total_section_label_gray}>FRAIS DE LIVRASION </Text>
                  <Text style={styles.total_section_value_gray}  >{props.order.fees} DT</Text>
                </View>
                <View style={{display:"flex",flexDirection:"row",marginTop:3}}>
                  <Text style={styles.total_section_label_gray}> T.FISCALE</Text>
                  <Text style={styles.total_section_value_gray}>{props.order.stamp} DT</Text>
                </View>
                <View style={{display:"flex",flexDirection:"row",marginTop:8,borderTopWidth:1,paddingTop:5}}>
                  <Text style={styles.total_section_label_grand}>Net à payer </Text>
                  <Text style={styles.total_section_value_grand}  >{props.order.total} DT</Text>
                </View>
              </View>
            </View>
            <View style={{marginTop:45}}>
              <Text style={{fontSize:13}}>Methode de paiement</Text>
              <Text style={{fontSize:10,color:"#505050",marginTop:2}}>{props.shoppingCart.method === 'on-delivery' ? "à la livraison" : "en ligne"}</Text>
            </View>
            <View style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%"}}>
              <View style={{marginTop:21}}>
                <Text style={{fontSize:13}}>Contact</Text>
                <Text style={{fontSize:10,color:"#505050",marginTop:2}}>+216 58 55 77 52</Text>
                <Text style={{fontSize:10,color:"#505050",marginTop:2}}>www.aliendimension.com</Text>
              </View>
              <Image src={Signature} style={{width:"30%",height:"90%"}}></Image>
            </View>
           
            <View>
              <Text style={{fontSize:13}}>Termes et conditions</Text>
              <Text style={{fontSize:10,color:"#505050",marginTop:2}}>Tous les droits sont réservés</Text>
            </View>
          </View>
          
        </Page>
      </Document>
    );
  }
  
}

export {MyDocument}