// import React, { useState, useEffect } from 'react'
// import './NewTestingScroll.css'

// const NewTestingScroll = (props) => {

//   useEffect(()=>{

// const html = document.documentElement;
// const canvas = document.getElementById("hero-lightpass");
// const context = canvas.getContext("2d");

// const frameCount = 45;
// const currentFrame = index => (require(
//   `../../assets/animations/First-Section/S1_${index.toString().padStart(5, '0')}.jpg`
// ))

// const preloadImages = () => {
//   for (let i = 1; i < frameCount; i++) {
//     const img = new Image();
//     img.src = currentFrame(i);
//   }
// };

// const img = new Image()
// img.src = currentFrame(1);
// canvas.width=1367;
// canvas.height=768;
// img.onload=function(){
//   context.drawImage(img, -290, 0);
// }

// const updateImage = index => {
//   img.src = currentFrame(index);
//   context.drawImage(img, -290, 0);
// }

// window.addEventListener('scroll', () => {  
//   const scrollTop = html.scrollTop;
//   const maxScrollTop = html.scrollHeight - window.innerHeight;
//   const scrollFraction = scrollTop / maxScrollTop;
//   const frameIndex = Math.min(
//     frameCount - 1,
//     Math.ceil(scrollFraction * frameCount)
//   );
  
//   requestAnimationFrame(() => updateImage(frameIndex + 1))
// });

// preloadImages()
//   },[])

//     return(
//         <div className="animation-container">
//             <canvas id="hero-lightpass" />
//         </div>
//     )
// }




// export {NewTestingScroll}

import React, { useState, useEffect } from 'react'
import './NewTestingScroll.css'

const NewTestingScroll = (props) => {

  useEffect(()=>{

const html = document.documentElement;
const canvas = document.getElementById("hero-lightpass-browser");
const context = canvas.getContext("2d");

const frameCount = 45;
const currentFrame = index => (require(
  `../../assets/animations/First\ Section/Web/S1_${index.toString().padStart(5, '0')}.jpg`
))

const preloadImages = () => {
  for (let i = 1; i < frameCount; i++) {
    const img = new Image();
    img.src = currentFrame(i);
  }
};

const img = new Image()
img.src = currentFrame(1);
canvas.width=1367;
canvas.height=768;
img.onload=function(){
  context.drawImage(img, -266, 0);
}

const updateImage = index => {
  img.src = currentFrame(index);
  context.drawImage(img, -266, 0);
}

window.addEventListener('scroll', () => {  
  const scrollTop = html.scrollTop;
  const maxScrollTop = html.scrollHeight - window.innerHeight;
  const scrollFraction = scrollTop / maxScrollTop;
  const frameIndex = Math.min(
    frameCount - 1,
    Math.ceil(scrollFraction * frameCount)
  );
  
  requestAnimationFrame(() => updateImage(frameIndex + 1))
});

const handleScroll=() =>{
  
}

preloadImages()
  },[])

    return(
        <div className="animation-container-browser">
            <canvas id="hero-lightpass-browser" />
        </div>
    )
}




export {NewTestingScroll}