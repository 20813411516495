import React from 'react'
import { communitiesService } from '../../../_services'
import {AntComment} from '../AntComment/AntComment'
import {BellFilled, BellOutlined,WarningOutlined} from '@ant-design/icons'
import {messaging} from '../../../firebase'
import { Input,Tag,Button,Tooltip } from 'antd';
import {connect} from 'react-redux'
import './Comment.css'
import Reply from '../Reply/Reply'
import { userActions } from '../../../_actions'

class Comment extends React.Component{
    constructor(props){
        super(props)

        this.state={
            replies : [],
            repliesLoaded : false,
            value :"",
            showReplies : false
        }
    }

        componentDidMount(){
            if(this.props.mode && this.props.mode ==='SinglePage'){
                this.showReplies()
            }
        }


        //SUBSCRIPTION TO NOTIFICATIONS HANDLE
        notificationPermission = async ()=> {
            let permissionGranted = false;
            try {
      
                if (Notification.permission !== 'granted') {
                    await messaging.requestPermission();
                }
           
                if (localStorage.getItem("INSTANCE_TOKEN")) {
                    permissionGranted = true;
                } else {
                    const token = await messaging.getToken(); // returns the same token on every invocation until refreshed by browser
                  
                    localStorage.setItem("INSTANCE_TOKEN", token);
                    permissionGranted = true;
                }
            } catch (err) {
                console.log(err);
               
            } finally {
                return permissionGranted;
            }
          }
    
          
        handleUserSubscriptionToComment = async ( topic ) => {

            /*
            const newSubscriptions = []
         
            if(this.props.authentication.user.body.subscriptions.length >1){
                 newSubscriptions = this.props.authentication.user.body.subscriptions.filter(subTopic =>  subTopic !== topic)
        
            }
            
     
            const req= {
                id :this.props.authentication.user.id ,
                subscriptions : newSubscriptions,
                token : this.props.authentication.token
                }
            this.props.updateSubscriptions(req)
            */
            const permission = await this.notificationPermission()

            if(this.props.loggedIn&& permission){

                const subscriptions = this.props.user.body.subscriptions
                subscriptions.push(topic)
                const req = {
                user : {
                    id: this.props.user.id,
                    token : this.props.token,
                    subscriptions
                },
                subscription : {
                    token : localStorage.getItem('INSTANCE_TOKEN'),
                    topic : topic
                }
              }
    
              this.props.updateSubscriptions(req,'subscribe')
               
            }
    
          
          
        }
        
        handleUserUnsubscriptionToComment = async (topic) => {

            /*
          const newSubscriptions = this.props.authentication.user.body.subscriptions
          newSubscriptions.push(topic)
    
          const req= {
              user :{
                  id :this.props.authentication.user.id ,
                  subscriptions : newSubscriptions,
                  token : this.props.authentication.token
              },
              subscription : {
                  topic
              }
                  
          }
          this.props.updateSubscriptions(req)
           */
          
          
          if(this.props.loggedIn){

            const subscriptions = this.props.user.body.subscriptions.filter(sub => sub !== topic)

            const req = {
                user: {
                    id: this.props.user.id,
                    token : this.props.token,
                    subscriptions
                },
                subscription : {
                    token : localStorage.getItem('INSTANCE_TOKEN'),
                    topic : topic
                }
            }
    
            this.props.updateSubscriptions(req,'unsubscribe') 
                
    
          }
           
        }
    
        // SUBSCRIPTION TO NOTIFICATIONS HANDLE

    onChange = ({ target: { value } }) => {
        this.setState({ value });
      };
    
      addReplyToComment =async (commentId)=>{
 


        const permission = await this.notificationPermission()
        const req={

            reply : {commentId,
                body: this.state.value,
                auth:{
                    id : this.props.user.id,
                    name: this.props.user.body.name,
                    image :'whatecver',
                    subscriptionToken: localStorage.getItem('INSTANCE_TOKEN')
                },
                communityName:this.props.communityName
            }
        }

        communitiesService.addReplyToComment(req)
            .then(res=> {
                    const newreply = {
                        id: res.data.posted.id,
                        body : {
                            body : this.state.value,
                            auth:{
                            id : this.props.user.id,
                            name: this.props.user.body.name,
                            image :'whatecver'
                        },
                        communityName:this.props.communityName
                        }
                    }
                    const newReplies= this.state.replies
                    newReplies.push(newreply)

                    const subscriptions = this.props.user.body.subscriptions
                 subscriptions.push(`COMMENT${commentId}`)
                 const req = {
                        user :{
                            id: this.props.user.id,
                         token : this.props.token,
                         subscriptions
                        },
                        subscription:{
                            token : localStorage.getItem('INSTANCE_TOKEN'),
                            topic : `COMMENT${commentId}`
                        }
                         
                     
                 }


               if(permission){
                   this.props.updateSubscriptions(req,'subscribe')
               }

                    this.setState({value :"",
                                replies : newReplies})
                    
            })
            .catch(err => console.error(err))
    }

    showReplies = ()=>{
        communitiesService.getRepliesByComment(this.props.commentId)
            .then(res => {
       
                this.setState({
                replies : res.data,
                repliesLoaded : true
                })
            })
        this.setState({
            showReplies : true
        })
    }

    hideReplies = ( ) => {
        this.setState({showReplies : false})
    }

    render(){
        const { TextArea } = Input;
        const subscribedToThisComment = this.props.loggedIn && this.props.user.body.subscriptions.includes(`COMMENT${this.props.commentId}`)
        const ownComment = this.props.author.id === this.props.user.id
        return(
            <div className="comment">
                {
                            this.props.loggedIn && !ownComment? subscribedToThisComment?
                            <div ><Button type="primary" ghost shape="circle" style={{ fontSize:"1.2rem",color:"#fbb72c", border :"none"}} onClick={()=>this.handleUserUnsubscriptionToComment(`COMMENT${this.props.commentId}`)} icon={<BellFilled />}/></div>
                             :
                            <div ><Button type="primary" ghost shape="circle" style={{fontSize:"1.2rem", color:"#fbb72c", border :"none"}} onClick={()=>this.handleUserSubscriptionToComment(`COMMENT${this.props.commentId}`)} icon={<BellOutlined />}/></div> 
                            : null
                         
                }
                {this.props.notValidated?
                    <Tooltip title="your post will be visible to other users after validation">
                        < WarningOutlined  style={{color:'yellow',fontSize:'1.2rem'}}/>
                  </Tooltip>
                    :null}
                <AntComment refreshContributions={this.props.refreshContributions} subject={this.props.subject} body={this.props.body} author={this.props.author} type="Comment" contributionId={this.props.commentId} likes={this.props.likes}/>
                <Tag color="rgb(158, 225, 245)" style={{borderRadius : '50%'}}>{this.state.replies.length}</Tag>
                {
                    this.props.repliesCount ?
                        this.state.showReplies ? <Button size="small"  onClick={this.hideReplies} >Hide replies</Button>:<Button size="small"  onClick={this.showReplies} >View replies</Button>
                    :
                        null
                    }

                {this.state.showReplies? 
                    this.state.replies.length>0?
                        this.state.replies.map(reply => {
                            return <Reply showReplies={this.state.showReplies} refreshContributions={this.props.refreshContributions} className="post-reply" replyId={reply.id}  body={reply.body.body} author={reply.body.auth} notValidated={reply.body.notValidated} likes={reply.body.likes}></Reply>
                        })
                    :
                        <span style={{marginLeft:"0.5rem",textAlign:"center",color:"rgb(140,140,140)"}}>This post has no comments</span>     
                :null }
                {this.props.loggedIn ? 
                    <div className="post-reply-section">
                        <TextArea
                        className="text-input"
                        value={this.state.value}
                        onChange={this.onChange}
                        placeholder="Write your reply here"
                        autoSize={{ minRows: 1, maxRows: 5 }}
                        />
                        <Button className="post-reply-button" onClick={() => this.addReplyToComment(this.props.commentId)}>Reply</Button>
                    </div>
                : null
            }
            </div>
        )
    }
}

const mapState =(state)=>{
    const {authentication} = state
    const {user,token,loggedIn} = authentication
    return{
        user,
        token,
        loggedIn
    }
}

const actionCreators = {
    updateSubscriptions : userActions.updateSubscriptions
}

const connectedComment = connect(mapState,actionCreators)(Comment)

export{connectedComment as Comment}