import {redirectionConstants } from '../_constants'

export const redirectionActions = {
    updateUrl
};

function updateUrl(url) {
    return dispatch => {
      

   
                    dispatch(updateUrl(url))
                  
            
    };
    /**
     * these are the actions to be dispatched 
     */
    
    function updateUrl(url) { return { type: redirectionConstants.UPDATE_REDIRECTION_URL,url } }
    
}