import React,{useState,useEffect} from 'react'
import './PostPage.css'
import {Post} from '../Shared/Post'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import { communitiesService } from '../../_services'
import { communitiesActions } from '../../_actions'


const PostPage = (props)=>{


    const [post,setPost] = useState(null)
    const [communities,setCommunities] = useState(null)

    useEffect(()=>{
        communitiesService.getPostById(props.match.params.id)
            .then(res => setPost(res.data[0]))
            .catch(err => console.error(err))
        
        if(!props.communities.loaded){
            props.getAll()
            setTimeout(()=>{
                setCommunities(props.communities.items)
            },1000 )
        }else{
            setCommunities(props.communities.items)
        }
        
   
    },[])

    useEffect(()=>{
    },props.communities)

    return(
        <div className="post-page">
            <div className="left-section">
                {communities ?communities.map(community => <Link className="community-link" to={`/communities/${community.id}`}>{community.body.name}</Link >):null}
            </div>
            <div className="mid-section">
               
                <div className="main-content__body">
                    {post ? 
                        <Post   
                        mode='SinglePage'
                        postId={post.id} 
                        postType={post.body.type}
                        subject={post.body.subject}
                        body={post.body.body}
                        author={post.body.auth}
                        notValidated={post.body.notValidated}
                        likes={post.body.likes}
                        communityName={props.match.params.communityName}
                        />

                    :
                        null
                    }
                </div>
                
            </div>
          
        </div>
    )
}


const mapState=(state)=>{
    const {communities} = state
    return {
        communities
    }
}

const actionCreators={
    getAll : communitiesActions.getAll
}

const connectedPostPage = connect(mapState,actionCreators)(PostPage)

export { connectedPostPage as PostPage}