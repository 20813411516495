import React from 'react'
import './ProductPage.css'
import {Button} from 'antd'
import {experiencesService } from '../../_services'
import { Link } from 'react-router-dom'
import { redirectionActions, shoppingCartActions } from '../../_actions'
import {connect} from 'react-redux'
import { TechSpecsPage } from '../TechSpecsPage'
import {history} from '../../_helpers'
import { NewTestingScroll } from '../NewTestingScroll/NewTestingScroll'
import { toast } from 'react-toastify'
import { PreviewSection } from '../PreviewSection'
import {firebaseAnalytics } from '../../firebase'

/**
 * The Alien dimension Experience Page
 * 
 * @exports Experience__Page
 */
class ProductPage extends React.Component{

    state={
        product : null,
        loaded : false,
        scrollValue : 0,
        associatedQuizz: null,
        selectedSection :"overview",
        scrolledToSection : false
    }

/**
 * this function updates the scrooll value Upon scrolling the web app 
 * Which will be handy for visualizing the experience cta.
 */ 




/**
 * When calling the componentDiDmount hook we add the the event scroll event listener to our page
 * and we call the experiences servive with the getById function and pass the id from URL
 * 
 */
    componentDidMount(){
        document.scrollingElement.scrollTop = 0
        window.addEventListener('scroll',this.onScroll)

        if(this.state.loaded && this.props.match.params.section === 'tech-spechs' ){
                this.setState({selectedSection : 'technical-specs'})
          
        }
        experiencesService.getById(this.props.match.params.id)
            .then(response => {
                const product = response.data[0]
                this.setState({
                    product,
                    loaded: true
                })
                experiencesService.getQuizzByExperienceId(this.props.match.params.id)
                    .then(res=>this.setState({associatedQuizz:res.data[0]}))
                    .catch(err=>console.error(err))

            })
            .catch(err => console.log("Error"))
            this.props.updateUrl(`/product/${this.props.match.params.id}/overview`)

    }



  

     isInViewport=(el)=> {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    
        );
    }


    onScroll =()=>{
        this.setState({scrollValue : window.scrollY})
     
        if(document.getElementById('technial-specs-anchor') && this.isInViewport(document.getElementById('technial-specs-anchor'))){
            this.setState({selectedSection:"technical-specs"})
        }else if (document.getElementById('overview') && this.isInViewport(document.getElementById('overview'))){
            this.setState({selectedSection:"overview"})
        }
    }

/**
 * This hook handles navigating from an exerience page to another becuase Only the id changes in the url
 * normally reactDom Rerenders only if we change the route not the id so this is a way to handle this
 * problem by checking if the ID in the url changes and forcing another getBYId call 
 */
    componentWillReceiveProps(nextProps){
        if(nextProps.match.params.id !== this.props.match.params.id){
            experiencesService.getById(nextProps.match.params.id)
            .then(res => this.setState({product:res.data[0]}))
        }
      }

    handleSearchChnage=(e)=>{
        this.setState({searchTerm : e.target.value})
    }

    onAddExperienceToCard= ( id,coverUrl,title,description,price,category,discountPrice,discountPercentage)=>{

        let productPrice = discountPrice> 0 ? discountPrice : price
		const product= {
			id,
			coverUrl,
			title,
			description,
			price : productPrice,
            initialPrice: price,
			category,
            discountPrice,
            discountPercentage
		}
		this.props.addToCart(product)
        toast('Experience ajoutée au panier')
        firebaseAnalytics.logEvent("ADD_TO_CART");

	}
    

    selectSection = (section ) =>  {
        if(section === "technical-specs"){
            history.push("tech-spechs")
            document.getElementById('technial-specs-anchor').scrollIntoView()}
        else if(section==="apercu"){
            history.push("overview")
            document.getElementById('apercu').scrollIntoView()}

        this.setState({selectedSection:section})
    }

    render(){

        if(!this.state.scrolledToSection && document.getElementById('technial-specs-anchor') && this.props.match.params.section === 'tech-spechs' ){
            document.getElementById('technial-specs-anchor').scrollIntoView()
            this.setState({scrolledToSection : true})
        }

    
        if(this.state.loaded){

            return(
                <div id="apercu" className="product-page" >
                    <div className="product-page__navbar">
                            <span>{this.state.product.body.title.fr}</span>
                            <div className="product-page__navbar__navigations" >
                                {this.state.product.body.withPreview && <span className={`apercu ${this.state.selectedSection === 'overview' && "highlited"}`}><span onClick={()=>this.selectSection("apercu")}>Aperçu</span></span>}
                                <span className={`specs-tech ${this.state.selectedSection === 'technical-specs'  && "highlited highlited-techspecs"}`}><span  onClick={()=>this.selectSection("technical-specs")} >Specs</span></span>
                                <span className="acheter-cta" onClick={()=> this.onAddExperienceToCard(
                                    this.state.product.id,
                                    this.state.product.body.images[0].url,
                                    this.state.product.body.title.fr,
                                    this.state.product.body.description.fr,
                                    this.state.product.body.price,
                                    this.state.product.body.category.fr,
                                    this.state.product.body.discountPrice,
                                    this.state.product.body.discountPercentage)}>Acheter</span>
                            </div>
                    </div>
                     {this.state.product.body.withPreview && <PreviewSection 
                        experience={this.state.product}
                     />}
                    <div  id="technial-specs">
                        <div id="technial-specs-anchor"></div>
                    <TechSpecsPage experience={this.state.product}/>

                    </div>
                </div>
            )
        }else{
            return(
                <div className="product-page">
                    ...Loading
                </div>
            )
        }
           
        
        
    }
    
}

const mapState =(state)=>{
    const {experiences} = state
    return{
        experiences
    }
} 

const actionCreators = {
    updateUrl : redirectionActions.updateUrl,
    addToCart : shoppingCartActions.addToCart
}
const connectedProductPage = connect(mapState,actionCreators)(ProductPage)
export  {connectedProductPage as ProductPage}