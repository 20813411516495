import React from 'react'
import { communitiesService } from '../../../_services'
import {AntComment} from '../AntComment/AntComment'
import { Input,Button,Tag,Tooltip } from 'antd';
import './Post.css'
import {BellFilled, BellOutlined,WarningOutlined,MoreOutlined} from '@ant-design/icons'
import {userActions} from '../../../_actions'
import {connect} from 'react-redux'
import {messaging} from '../../../firebase'
import {history} from '../../../_helpers'
import {Comment} from '../Comment'
class Post extends React.Component{
    constructor(props){
        super(props)

        this.state={
            comments : [],
            commentsLoaded : false,
            value :"",
            showComments: false
        }
    }

    componentDidMount(){

        if(this.props.mode && this.props.mode ==='SinglePage'){
            this.showComments()
        }
        if(!this.props.notValidated ){
            this.setState({
                comments: [],
                commentsLoaded: true
            })
        }
        
    }


       //SUBSCRIPTION TO NOTIFICATIONS HANDLE
       notificationPermission = async ()=> {
        let permissionGranted = false;
        try {
  
            if (Notification.permission !== 'granted') {
                await messaging.requestPermission();
            }
       
            if (localStorage.getItem("INSTANCE_TOKEN")) {
                permissionGranted = true;
            } else {
                const token = await messaging.getToken(); // returns the same token on every invocation until refreshed by browser
              
                localStorage.setItem("INSTANCE_TOKEN", token);
                permissionGranted = true;
            }
        } catch (err) {
            console.log(err);
            
        } finally {
            return permissionGranted;
        }
      }

      
    handleUserSubscriptionToPost = async ( topic ) => {
        
        /*
        const newSubscriptions = []
       
        if(this.props.authentication.user.body.subscriptions.length >1){
             newSubscriptions = this.props.authentication.user.body.subscriptions.filter(subTopic =>  subTopic !== topic)
    
        }
        
       
        const req= {
            id :this.props.authentication.user.id ,
            subscriptions : newSubscriptions,
            token : this.props.authentication.token
            }
        this.props.updateSubscriptions(req)
        */
        const permission = await this.notificationPermission()

        if(this.props.loggedIn&& permission){
           
            const subscriptions = this.props.user.body.subscriptions
            subscriptions.push(topic)
            const req = {
            user : {
                id: this.props.user.id,
                token : this.props.token,
                subscriptions
            },
            subscription : {
                token : localStorage.getItem('INSTANCE_TOKEN'),
                topic : topic
            }
          }
          this.props.updateSubscriptions(req,'subscribe')
           
        }

      
      
    }
    
    handleUserUnsubscriptionToPost = async (topic) => {

        /*
      const newSubscriptions = this.props.authentication.user.body.subscriptions
      newSubscriptions.push(topic)

      const req= {
          user :{
              id :this.props.authentication.user.id ,
              subscriptions : newSubscriptions,
              token : this.props.authentication.token
          },
          subscription : {
              topic
          }
              
      }
      this.props.updateSubscriptions(req)
       */
      
      
      if(this.props.loggedIn){
       
        const subscriptions = this.props.user.body.subscriptions.filter(sub => sub !== topic)
     
        const req = {
            user: {
                id: this.props.user.id,
                token : this.props.token,
                subscriptions
            },
            subscription : {
                token : localStorage.getItem('INSTANCE_TOKEN'),
                topic : topic
            }
        }

        this.props.updateSubscriptions(req,'unsubscribe') 
            

      }
       
    }

    // SUBSCRIPTION TO NOTIFICATIONS HANDLE


  
    onChange = ({ target: { value } }) => {
        this.setState({ value });
      };

    showComments=()=>{
        communitiesService.getCommentsByPost(this.props.postId)
        .then(res => {
            this.setState({
            comments : res.data,
            commentsLoaded : true
            })
        })
        this.setState({
            showComments:true
        })
    }

    hideComments=()=>{
        this.setState({showComments:false})    
    }
    
    addCommentToPost = async (postId)=>{
        
        const permission = await this.notificationPermission()

        const req={
            comment : {postId: postId,
            body: this.state.value,
            auth:{
                id : this.props.user.id,
                name: this.props.user.body.name,
                image :'whatecver',
                subscriptionToken : localStorage.getItem('INSTANCE_TOKEN')
            },
            communityName:this.props.communityName
        }
            
        }

        communitiesService.addCommentToPost(req)
            .then(async res=>{ 
           
                const newComment = {
                    id: res.data.posted.id,
                    body : {
                        body : this.state.value,
                        auth:{
                        id : this.props.user.id,
                        name: this.props.user.body.name,
                        image :'whatecver'
                        }
                    }
                    
                    
                }
                const newComments= this.state.comments
                newComments.push(newComment)
            


                const subscriptions = this.props.user.body.subscriptions
                 subscriptions.push(`POST${postId}`)
                 const req = {
                        user :{
                            id: this.props.user.id,
                            token : this.props.token,
                            subscriptions
                        },
                        subscription:{
                            token : localStorage.getItem('INSTANCE_TOKEN'),
                            topic : `POST${postId}`
                        }
                 }
                 
                 subscriptions.push(`COMMENT${res.data.posted.id}`)
                 const secondReq= {
                    user :{
                        id: this.props.user.id,
                        token : this.props.token,
                        subscriptions
                    },
                    subscription:{
                        token : localStorage.getItem('INSTANCE_TOKEN'),
                        topic : `COMMENT${res.data.posted.id}`
                    }
                 }
                 this.setState({value :"",
                 comments : newComments})
               if(permission){
                   this.props.updateSubscriptions(req,'subscribe')
                   this.props.updateSubscriptions(secondReq,'subscribe')
               }

               this.setState({value :"",
                 comments : newComments})


              
        })
            .catch(err => console.error(err))
    }

    redirectToPostPage=()=>{
        // window.location=`http://localhost:3000/#/communities/${this.props.communityName}/post/${this.props.postId}`
         history.push(`/communities/${this.props.communityName}/post/${this.props.postId}`)
    }

    render(){
 
        const subscribedToThisPost = this.props.loggedIn && this.props.user.body.subscriptions.includes(`POST${this.props.postId}`)
        const { TextArea } = Input;

        return(
            <div className="post">
                <div className="tags">
                    {this.props.postType==='Review' ?
                    <Tag color="geekblue" className="post-type">{this.props.postType}</Tag>:
                        this.props.postType ==='Question' ? 
                        <Tag color="green" className="post-type">{this.props.postType}</Tag>:
                            this.props.postType === 'News' ? <Tag color="magenta" className="post-type">{this.props.postType}</Tag> : null
                    }
                    {this.props.notValidated?
                    <Tooltip title="your post will be visible to other users after validation">
                        < WarningOutlined  style={{color:'yellow',fontSize:'1.2rem'}}/>
                  </Tooltip>
                    :null}
                  {!(this.props.mode && this.props.mode ==='SinglePage')?  <Button  shape="circle" onClick={this.redirectToPostPage} icon={<MoreOutlined/>}/>:null}
                </div>
                {
                            this.props.loggedIn && this.props.user.id !== this.props.author.id? subscribedToThisPost?
                            <div ><Button type="primary" ghost shape="circle" style={{ fontSize:"1.2rem",color:"#fbb72c", border :"none"}} onClick={()=>this.handleUserUnsubscriptionToPost(`POST${this.props.postId}`)} icon={<BellFilled />}/></div>
                             :
                            <div ><Button type="primary" ghost shape="circle" style={{fontSize:"1.2rem", color:"#fbb72c", border :"none"}} onClick={()=>this.handleUserSubscriptionToPost(`POST${this.props.postId}`)} icon={<BellOutlined />}/></div> 
                            : null
                         
                }
                <AntComment refreshContributions={this.props.refreshContributions} subject={this.props.subject} body={this.props.body} author={this.props.author} notValidated={this.props.notValidated} type="Post" contributionId={this.props.postId} likes={this.props.likes}/>
                <Tag color="rgb(158, 225, 245)" style={{borderRadius : '50%'}}>{this.state.comments.length}</Tag>
                {
                this.props.commentsCount> 0 ?    
                    this.state.showComments ? <Button size="small" onClick={this.hideComments} >Hide comments</Button>:<Button size="small" onClick={this.showComments} >View comments</Button>
                :
                null
                }
                        {this.state.showComments?
                            this.state.comments.length> 0? 
                                this.state.comments.map(comment => {
                                
                                    return <Comment 
                                    mode='SinglePage'
                                    viewComments={this.state.showComments} 
                                    refreshContributions={this.props.refreshContributions} 
                                    communityName={this.props.communityName} 
                                    className="post-comment" commentId={comment.id} 
                                    body={comment.body.body} author={comment.body.auth} 
                                    notValidated={comment.body.notValidated} 
                                    likes={comment.body.likes}
                                    repliesCount={comment.body.repliesCount}
                                    ></Comment>
                                })
                                :
                                <span style={{marginLeft:"0.5rem",textAlign:"center",color:"rgb(140,140,140)"}}>This post has no comments</span>
                        :null }
                {this.props.loggedIn ?  <div className="post-comment-button">
                    <TextArea
                    style={{width :"90%"}}
                    value={this.state.value}
                    onChange={this.onChange}
                    onPressEnter={() => this.addCommentToPost(this.props.postId)}
                    placeholder="Write your comment here"
                    autoSize={{ minRows: 1, maxRows: 5 }}
                    />
                    <Button onClick={() => this.addCommentToPost(this.props.postId)}>Comment</Button>
                </div> : null}
               
            </div>
        )
    }
}

function mapState(state){
    const {authentication} = state
    const {loggedIn,token,user} = authentication
    return {
        loggedIn,token,user
    }
}

const actionCreators = {
    updateSubscriptions : userActions.updateSubscriptions
  };

const connectedPost=connect(mapState,actionCreators)(Post)
export { connectedPost as Post}

