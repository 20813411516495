import React, { useState, useEffect } from 'react'
import './RedirectionComponent.css'
import {history}from '../../_helpers'
import {connect} from 'react-redux'
const RedirectionComponent = (props) => {
    useEffect(()=>{
        setTimeout(()=>{
            // window.location=`http://localhost:3000/#${props.redirection.url}`
             history.push(props.redirection.url)
        },300)
    })

    return (
        <div className="redirection-page">
            
        </div>
    )
}

const mapState = (state)=>{
    const {redirection} = state
    return {
        redirection
    }
}

const connectedRedirectionComponent = connect(mapState)(RedirectionComponent)

export {connectedRedirectionComponent as RedirectionComponent}