/**
 * importing alertConstants to make assigning action type easier
 */
import { alertConstants } from '../_constants';
/**
 * we export an object called alertActions with the functions as its methods
* @exports Action-creators__Alerts
 */
export const alertActions = {
    success,
    error,
    clear
};

/**
 * This is the SUCCESS alert action creator 
 * it dispatches a success action 
 * @param {string} message this param is the message that is going to be logged after calling this function  
 */

function success(message) {
    return { type: alertConstants.SUCCESS, message };
}

/**
 * This is the FAILURE alert action creator 
 * it dispatches a failure action 
 * 
 * @param {string} message  this param is the message that is going to be logged after calling this function  
 */
function error(message) {
    return { type: alertConstants.ERROR, message };
}

/**
 * this is the Clear alert action creator
 */
function clear() {
    return { type: alertConstants.CLEAR };
}