import React, { useState, useEffect } from 'react'
import { shoppingCartActions, userActions } from '../../_actions'
import { Carousel } from 'react-responsive-carousel';
import {connect} from 'react-redux'
import { toast } from 'react-toastify'
import './TechSpecsPage.css'
import {firebaseAnalytics} from '../../firebase'
import { Fragment } from 'react';
import { experiencesService } from '../../_services/experiences.service';
import {history } from '../../_helpers'

const TechSpecsPage =( props) => {

    const [quizz,setQuizz ]= useState(null)
    
    useEffect(()=>{
            experiencesService.getQuizzByExperienceId(props.experience.id)  
                .then(res =>{ 
                    setQuizz(res.data[0])
                })
                .catch(err => console.log(err))

    },[])


    const onAddExperienceToCard= ( id,coverUrl,title,description,price,category,discountPrice,discountPercentage)=>{
        firebaseAnalytics.logEvent("ADD_TO_CART");

        const productPrice = discountPrice > 0 ? discountPrice : price
		const product= {
			id,
			coverUrl,
			title,
			description,
			price:productPrice,
            initialPrice:price,
			category,
            discountPrice,
            discountPercentage
		}
		props.addToCart(product)
        toast('Experience ajoutée au panier.')
	}

    const addFavorite=(id,name,description,price,category,coverUrl)=>{
        if(props.authentication.loggedIn){
            const found = props.authentication.user.body.wishlists.find(element => element.id === id);

        if(!found)
        {
        const newwishlists = props.authentication.user.body.wishlists;
        newwishlists.push({id,name,description,price,category,Url: 'whatever' })
        const req = {
            id:props.authentication.user.id,
            wishlists :newwishlists,
            token : props.authentication.token
        }
        props.updateFavorite(req)
        
        toast('Experience ajoutée au favoris')
        }else{
            toast("Cette experience existe dèja dans vos favoris.")
        }
        }else{
            props.showModal()
        }

        
    }

    const onRedirectToQuizz = ( )=> {
        if(quizz.id) history.push(`/quizzes/${quizz.id}`)
        else toast("Bientot disponible")
    }

    return(
        <div className="tech-specs-page" >
            <div className="product-info-first-section">
                <div className="product-main-info">
                    <div className="main-info__header">
                        {props.experience.body.discountPercentage>0&&<div className="sale">{props.experience.body.discountPercentage}%</div>}
                        <div className="rate"></div>
                    </div>
                        <h3 className="experience-title">{props.experience.body.title.fr}</h3>
                    <div className="price-section">
                        <span></span><span style={{marginLeft:'.6rem'}}>{props.experience.body.discountPrice || props.experience.body.price}TND</span>
                    </div>
                    <div className="main-info__ctas">
                        <div className="ajouter-aux-favoris" onClick={()=> addFavorite(props.experience.id,props.experience.body.title.fr,props.experience.body.description.fr,props.experience.body.price,props.experience.body.category.fr,props.experience.body.coverUrl)}>Ajouter aux favoris</div>
                        <div className="acheter"  onClick={()=> onAddExperienceToCard(
                            props.experience.id,
                            props.experience.body.coverUrl,
                            props.experience.body.title.fr,
                            props.experience.body.description.fr,
                            props.experience.body.price,
                            props.experience.body.category.fr,
                            props.experience.body.discountPrice,
                            props.experience.body.discountPercentage)}> Acheter </div>
                    </div>
                    <hr/>
                    <div className="main-info__description-section">
                        <h3 className="section-title">Description</h3>
                        <p>{props.experience.body.description.fr}
                        </p>
                    </div>
                </div>
                <div className="product-main-image">
                        <Carousel>
                            {props.experience.body.images.map(image => <img src={image.url} className="carousel-image-1" alt="carousel-image"/>)}
                        </Carousel>
                </div>
            </div>
            <div className="lessons-info-section">
                <div className="lessons-section-image">
                         <img src={props.experience.body.lessonsImage.url} className="lessons-image" alt='lessons-image'/>
                </div>
                <div className="lessons-info">
                    <h3 className="section-title">Leçons</h3>
                    <p>{props.experience.body.lessonsDescription.fr}
                    </p>
                </div>
            </div>
            <div className="features-section">
                <div className="features-section-info">
                    <h3 className="section-title">Autres services</h3>
                    <p>{props.experience.body.features.description.fr}
                    </p>
                </div>
                <div className="features-section-images-holder">
                    <div className="features-section-image-holder"> 
                        <div className="features-section-image">
                           <img src={props.experience.body.features.community.image.url} alt='feature-1-image'/>
                        </div>
                        <div className="features-section-title">
                        Communauté
                        </div>
                    </div>
                    <div className="features-section-image-holder"> 
                        <div className="features-section-image sat-logo-container">
                            {props.experience.body.title.fr.includes('spatiale')?
                                    <img src={require('../../assets/logos/logo-sat.png')} alt='feature-2-image' className="sat-logo" />
                            :
                                <img src={props.experience.body.features.partner.image.url} alt='feature-2-image'/>
}

                        </div>
                        <div className="features-section-title">
                        Partenaires
                        </div>
                    </div>
                    <div className="features-section-image-holder"> 
                        <div className="features-section-image">
                        <img src={props.experience.body.features.quiz.image.url} alt='feature-1-image'/>

                        </div>
                        
                        <div onClick={onRedirectToQuizz} className="features-section-title quizz-feature">
                        Quiz
                        </div>
                    </div>
                </div>
            </div>
            {props.experience.body.tips.length>0 && <div className="FAQs">
                <h3 className="section-title">Conseils</h3>
                {props.experience.body.tips.map(tip=><span>{tip.text}<a href={tip.link}>Link</a></span>)}
            </div>}
        </div>
    )
}

const mapState =( state) => {
    const {authentication} = state
    return{
        authentication
    }
}

const actionCreators = {
    addToCart: shoppingCartActions.addToCart,
    updateFavorite : userActions.updateFavorite,
    showModal : userActions.showModal
}

const connectedTechSpecsPage = connect(mapState,actionCreators)(TechSpecsPage)


export {connectedTechSpecsPage as TechSpecsPage}