import React from 'react'
import './PaymentFailure.css'
import {CloseCircleTwoTone } from '@ant-design/icons'
import {Link} from 'react-router-dom'

const PaymentFailure = (props) =>{
    return(
       
        <div className="payment-failure-page">
               
        <div className="first-section">
             <div className="background-image">
                 
             </div>
        </div>
        <div className="second-section">
            <h1>Paiment refusé</h1>
            <p>Votre paiment n'est pas reussi merci de réessayer une autre fois.
            </p>

        </div>
</div>
    )
}

export {PaymentFailure}