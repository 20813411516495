import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {LoadingOutlined} from '@ant-design/icons';
import {history} from '../../_helpers'
import { Redirect } from 'react-router-dom'
import { userActions } from '../../_actions'
import {Link} from 'react-router-dom'
import './SignInPage.css'
import { LoginHooks } from '../GoogleLogin/LoginHooks';
import { LoginWithFacebook } from '../LoginWithFacebook';
import { browserName } from 'react-device-detect';

const SignInPage = props => {

    const [loginButtonPressed,setLoginButtonPressed] = useState(false)
    const handleLogIn =(e ) =>{
        setLoginButtonPressed(true)
        const email =document.getElementById('email-input').value
        const password = document.getElementById('password-input').value
        props.login(email,password)
    }

    useEffect(()=>{
        document.getElementById('navbar').style.display = "none"
        document.getElementById('footer').style.display = "none"

    })

    const handleLoginButtonPressed = ( )=>{
        setLoginButtonPressed(true)
    }

    const handleRedirectToRegister = () => {
        // window.location="http://localhost:3000/#/register"
         history.push("/register")
    }
//  console.log("loginButtonPressed",loginButtonPressed,"props.loggedIn",props.authentication.loggedIn)
//     if(loginButtonPressed&& props.authentication.loggedIn){
//         console.log("HEZZZZ")
//         window.location ="http://localhost:3000/#/"
//     }
        return(
            <div className="sign-in-page">
                <Link to='/'><div className="logo-section"></div></Link>

                <div className="sign-in-container">
                    <div style={{width:"80%"}}>
                        <div className="sign-in-form">
                            <input id="email-input" type="email" placeholder="Email"/> 
                            <input id="password-input" type="password" placeholder="Mot de passe"/> 
                            {loginButtonPressed&& props.authentication.logInError ?<span style={{marginLeft:"1rem",marginTop:"1rem",color:"rgb(100,100,100)",fontSize:".8rem"}}>Vérifier vos informations d'identification et réessayer</span> :null }

                            <div style={{display:"flex",padding:" 4%",justifyContent:"space-between",alignItems:"center"}}>
                            <Link to="/resetpasswordrequest"><span className="forgot-password">Mot de passe oublié ?</span></Link>
                                <button onClick={handleLogIn} className="login-cta" >
                                    Se connecter
                                    {props.authentication.loggingIn && <LoadingOutlined/>}
                                    </button>
                            </div>
                        </div>
                        <div onClick={handleLoginButtonPressed} style={{marginTop:"5%",padding:"5%",display:"flex",flexDirection:"column",alignItems:"center"}}>
                            {!browserName.toUpperCase().includes('FACEBOOK') && <LoginHooks  style={{height:"25px"}}/>}
                            <LoginWithFacebook onClick={handleLoginButtonPressed} backgroundColor="blue"/>
                        </div>
                    </div>
                  
                    
                    <div style={{display:"flex",flexDirection:"column"}}>
                        <span className="dont-have-account">Vous n'avez pas de compte ?</span>
                        <button className="register-cta" onClick={handleRedirectToRegister}>S'inscrire</button>
                    </div>
                    
                </div>
            </div>
        )
    
        
    
    
}

const mapState =(state) => {
    const {authentication} = state
    return{
        authentication
    }
}

const actionCreators={
        login : userActions.login
}

const connectedSIgnInPage = connect(mapState,actionCreators)(SignInPage)

export {connectedSIgnInPage as SignInPage}