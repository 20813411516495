import { notificationsConstants } from '../_constants';

const initialState= { items : []}

export function notifications(state = initialState, action) {
  switch (action.type) {
    case notificationsConstants.PUSH_NOTIFICATION:
        const addedNotifications = state.items
        addedNotifications.push(action.notification)
       return {
        items : addedNotifications
    } 
    case notificationsConstants.POP_NOTIFICATION : 
        const reducedNotifications = state.items
        reducedNotifications.splice(action.index,1)
    return{
      items:reducedNotifications
    }
    default:
      return state
  }
}