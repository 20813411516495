import { experiencesConstants, userConstants } from '../_constants';
import { experiencesService, userService } from '../_services';
import { alertActions } from './';
import { shoppingCartConstants } from '../_constants';
import {history} from '../_helpers'
import { toast } from 'react-toastify';
import { experiencesActions } from './experiences.actions';
import {firebaseAnalytics} from '../firebase'


/**
 * exporting a userActions object with the user actions dispatching the user actions as methods
 * @exports Action-creators__Users
 */
export const userActions = {
    login,
    logout,
    register,
    getAll,
    update,
    updateFavorite,
    updateCommunities,
    showModal,
    hideModal,
    showEditModal,
    hideEditModal,
    updateLikes,
    updateSubscriptions,
    logInWithFacebook,
    updateUserUpvotes,
    updateBoughtExperiences,
    updateShoppingCart,
    resetUpdated,
    updateAddress,
    logInWithGoogle,
    getNewToken
};


/**
 * this is the login action creator 
 * it handles dispatching actions to inform about the status of the request and handles the response
 * @param {string} email 
 * @param {string} password 
 */
function login(email, password) {

    return dispatch => {
        dispatch(request({ email }));

        userService.login(email, password)
            .then(res => { 
              
                    const user = res.data.posted
                    dispatch(success(user)) 
                    if(res.data.posted.body.cartItems.length>0){
                        dispatch(setShoppingCart(res.data.posted.body.cartItems))

                    }
                    firebaseAnalytics.logEvent("USER_LOGIN");

                }).catch(err=> {
                    if(err.response)dispatch(failure(err.response.data))
                    else dispatch(failure(err))
                })
            
            
    };

    function setShoppingCart(cartItems) { return { type: shoppingCartConstants.SET_SHOPPING_CART, cartItems } }
    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE,error } }
}


/**
 * This is the logOut actior creator that handles the user logOut
 */
function logout() {
    userService.logout();
    firebaseAnalytics.logEvent("USER_LOGOUT");

    return { type: userConstants.LOGOUT };
}
/**
 * This is the register actions creator
 * 
 * @param {object} user 
 */
function register(req) {
    return dispatch => {
        dispatch(registerRequest(req))

       userService.register(req)
            .then(res => { 
              
                         history.push('/emailverification')
                        // window.location = "http://localhost:3000/#/emailverification"
                        firebaseAnalytics.logEvent("USER_CREATE_ACCOUNT");

                    dispatch(registerSuccess(req.user));
                    dispatch(alertActions.success('Registration successful'));
                
                })
            .catch(err=> {
                dispatch(registerFailure(err.response.data))
            })
            
           
    };
    
    function registerRequest(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function registerSuccess(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function registerFailure(error) { return { type: userConstants.REGISTER_FAILURE, error } }


    
}
    

/**
 * this is the user getAll action creator
 */
function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

/**
 * this is the user info update action creator which 
 * 
 * @param {object} newUser 
 */

function update(newUser) {
    return dispatch => {

        dispatch(request(newUser));

       userService.update(newUser)
            .then(res => { 
                
                userService.getById(newUser.id)
                    .then(res=> {
                        const user= res.data[0]
                        dispatch(success(user));
                        dispatch(alertActions.success('Update successfull'));
                    })
                    
                })
            .catch(
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    
                })
           
    };

    function request(newUser) { return { type: userConstants.UPDATE_REQUEST,newUser } }
    function success(user) { return { type: userConstants.UPDATE_SUCCESS,user } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}


/**
 * this is the updateFavorite action creator which handles adding and deleting favorite experiences
 * 
 * @param {object} req 
 */
function updateFavorite(req){
    return dispatch =>{
        dispatch(request(req))
        toast('Opération en cours.')
        userService.updateFavorite(req)
            .then(res=>{   
                userService.getById(req.id)
                    .then(res => {
                        const user = res.data[0]
                        firebaseAnalytics.logEvent("USER_UPDATE_FAVORITES");
                        dispatch(success(user))
                        dispatch(alertActions.success("FAVORITES UPDATED SUCCESSFULLY"))
                        toast.dismiss()
                        toast('Operation effectuée avec succès.')
                    })
                
                
                
            })
            .catch(
                error =>{
                    toast('Erreur')
                    dispatch(failure(error))
                    dispatch(alertActions.error(error))
                }
            )
    }

    function request(req) { return {type : userConstants.UPDATE_FAVORITE_REQUEST,req}}
    function success(user) { return {type : userConstants.UPDATE_FAVORITE_SUCCESS,user}}
    function failure(error) { return {type : userConstants.UPDATE_FAVORITE_FAILURE,error}}
}

/**
 * This is tha show Login modal action creator
 */
function showModal(){
    return dispatch=>{
        dispatch({type:userConstants.SHOW_MODAL})
    }
}

function showEditModal(){
    return dispatch => {
        dispatch({type : userConstants.SHOW_EDIT_MODAL})
    }
}

/**
 * This is tha hide Login modal action creator
 */
function hideEditModal(){
    return dispatch =>{
        dispatch({type: userConstants.HIDE_EDIT_MODAL})
    }
}


/**
 * This is tha hide Edit modal action creator
 */
function hideModal(){
    return dispatch => {
        dispatch({type : userConstants.HIDE_MODAL})
    }
}

function updateCommunities(req){
    return dispatch =>{
        dispatch(request(req))

        userService.updateCommunities(req)
            .then(res=>{  

                userService.getById(req.id)
                    .then(res => {
                        const user = res.data[0]
                        dispatch(success(user))
                        dispatch(alertActions.success("COMMUNITIES UPDATED SUCCESSFULLY"))
                    })    
            })
            .catch(
                error =>{
                    dispatch(failure(error))
                    dispatch(alertActions.error(error))
                }
            )
    }

    function request(req) { return {type : userConstants.UPDATE_COMMUNITIES_REQUEST,req}}
    function success(user) { return {type : userConstants.UPDATE_COMMUNITIES_SUCCESS,user}}
    function failure(error) { return {type : userConstants.UPDATE_COMMUNITIES_FAILURE,error}}
}

function updateSubscriptions(req,mode){
    return dispatch =>{
        dispatch(request(req))

        userService.updateSubscriptions(req,mode)
            .then(res1 =>{
                    
                userService.getById(req.user.id)
                    .then(res2 => {
                        const user= res2.data[0]
                        dispatch(success(user))
                        dispatch(alertActions.success('Subscription updated successfully'))
                    })
            })
            .catch(error => {

                dispatch(failure(error))
                dispatch(alertActions.error(error))
            })
    }
    
    function request(req) { return {type : userConstants.UPDATE_SUBSCRIPTIONS_REQUEST,req}}
    function success(user) { return {type : userConstants.UPDATE_SUBSCRIPTIONS_SUCCESS,user}}
    function failure(error) { return {type : userConstants.UPDATE_SUBSCRIPTIONS_FAILURE,error}}
}


function updateLikes(req1,req2){
    return dispatch =>{
        toast("Opération en cours.")

        dispatch(request(req1))

        experiencesService.updateLikes(req1)
            .then(res1 => {
                userService.updateLikes(req2 )
                .then(res2=>{   
                    firebaseAnalytics.logEvent("USER_UPDATE_LIKES");

                    userService.getById(req2.user.id)
                        .then(res3 => {
                            const user = res3.data[0]
                            dispatch(updateExperienceLikes(res1.data.posted.resp))
                            dispatch(success(user))
                            dispatch(alertActions.success("LIKES UPDATED SUCCESSFULLY"))
                            toast.dismiss()
                            toast('Opération effectuée avec succès.')
                        })
                })
                .catch(
                    error =>{
                        toast.dismiss()
                        toast('Erreur')
                        dispatch(failure(error))
                        dispatch(alertActions.error(error))
                    }
                )
            })
            .catch(err => console.error(err))
        
    }
    function updateExperienceLikes(experience) { return {type : experiencesConstants.UPDATE_EXPERIENCE_LIKES,experience}}
    function request(req) { return {type : userConstants.UPDATE_LIKES_REQUEST,req}}
    function success(user) { return {type : userConstants.UPDATE_LIKES_SUCCESS,user}}
    function failure(error) { return {type : userConstants.UPDATE_LIKES_FAILURE,error}}
}


function logInWithFacebook(req){

    return dispatch =>{
        dispatch(request(req))

        userService.logInWithFacebook(req)
            .then(res=>{
               const user =res.data.posted
                
               firebaseAnalytics.logEvent("USER_LOGIN_WITH_FACEBOOK");

            //    window.location ="http://localhost:3000/#/redirect"
            history.push('/redirect')

                dispatch(success(user))
               dispatch(alertActions.success("LOGGED IN WITH FACEBOOK SUCCESSFULLY"))
              

               
       
            }).catch(err => {
                dispatch(failure(err))
            })
        /*
        if(req){
            dispatch(success(req))
            dispatch(alertActions.success("LOGIN WITH FACEBOOK SUCCESSFULLY"))
        }else{
            dispatch(failure())
        }*/
    }

    function request(req){return {type: userConstants.LOGIN_WITH_FACEBOOK_REQUEST,req}}
    function success(user) {return {type : userConstants.LOGIN_WITH_FACEBOOK_SUCCESS,user}}
    function failure(err){return{type : userConstants.LOGIN_WITH_FACEBOOK_FAILURE,err}}
}

function logInWithGoogle(req){
    return dispatch =>{
        dispatch(request(req))

        userService.logInWithGoogle(req)
            .then(res=>{
               const user =res.data.posted
                
               firebaseAnalytics.logEvent("USER_LOGIN_WITH_GOOGLE");

               history.push("/redirect")
               dispatch(success(user))
               dispatch(alertActions.success("LOGGED IN WITH GOOGLE SUCCESSFULLY"))
            }).catch(err => {
                dispatch(failure(err))
            })
        /*
        if(req){
            dispatch(success(req))
            dispatch(alertActions.success("LOGIN WITH FACEBOOK SUCCESSFULLY"))
        }else{
            dispatch(failure())
        }*/
    }

    function request(req){return {type: userConstants.LOGIN_WITH_GOOGLE_REQUEST,req}}
    function success(user) {return {type : userConstants.LOGIN_WITH_GOOGLE_SUCCESS,user}}
    function failure(err){return{type : userConstants.LOGIN_WITH_GOOGLE_FAILURE,err}}
}


function updateUserUpvotes(contributionId,mode) {

    return dispatch => {
        dispatch(request());

        dispatch(success({
            contributionId,
            mode
        }))
            
            
    };

    function request() { return { type: userConstants.UPDATE_USER_UPVOTES_REQUEST } }
    function success(payload) { return { type: userConstants.UPDATE_USER_UPVOTES_SUCCESS, payload } }
    function failure(error) { return { type: userConstants.UPDATE_USER_UPVOTES_ERROR,error } }
}




function updateBoughtExperiences(req){
    return dispatch =>{
        dispatch(request(req))
      
        userService.updateBoughtExperiences(req)
            .then(res=> {

                dispatch(success(req.newBoughtExperiences))
                dispatch(alertActions.success("UPDATED BOUGHT EXPERIENCES SUCCESSFULLY"))
            })
            .catch(err=> dispatch(failure(err)))
       
    }

    function request(experiences){return {type: userConstants.UPDATE_BOUGHT_EXPERIENCES_REQUEST,experiences}}
    function success(experiences) {return {type : userConstants.UPDATE_BOUGHT_EXPERIENCES_SUCCESS,experiences}}
    function failure(err){return{type : userConstants.UPDATE_BOUGHT_EXPERIENCES_FAILURE,err}}
}

function updateShoppingCart(req){
    return dispatch =>{
        dispatch(request(req))
        userService.updateShoppingCart(req )
            .then(res=>{   
                dispatch(success(req.cartItems))
            })
            .catch(
                error =>{
                    dispatch(failure(error))
                    dispatch(alertActions.error(error))
                }
            )
    }

    function request(req) { return {type : userConstants.UPDATE_SHOPPING_CART_REQUEST,req}}
    function success(items) { return {type : userConstants.UPDATE_SHOPPING_CART_SUCCESS,items}}
    function failure(error) { return {type : userConstants.UPDATE_SHOPPING_CART_FAILURE,error}}
}

function resetUpdated(){
    return dispatch =>{
        dispatch(reset())
     
    }

    function reset() { return {type : userConstants.RESET_UPDATED}}
   
}



function updateAddress(req) {

    return dispatch => {
        dispatch(request(req));

       userService.updateAddress(req)
            .then(res => { 
          
                dispatch(success(req))  
            })
            .catch(
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    
                })
           
    };

    function request(req) { return { type: userConstants.UPDATE_ADDRESS_REQUEST,req } }
    function success(payload) { return { type: userConstants.UPDATE_ADDRESS_SUCCESS,payload } }
    function failure(error) { return { type: userConstants.UPDATE_ADDRESS_FAILURE, error } }
}


function getNewToken(req){
    return dispatch =>{
        dispatch(request(req))

        userService.getNewToken(req)
            .then(res=>{  
                    const payload = res.data
                    dispatch(success(payload))
            })
            .catch(
                error =>{

                    dispatch(failure(error))
                    dispatch(alertActions.error(error))
                }
            )
    }

    function request(req) { return {type : userConstants.GET_NEW_TOKEN_REQUEST,req}}
    function success(payload) { return {type : userConstants.GET_NEW_TOKEN_SUCCESS,payload}}
    function failure(error) { return {type : userConstants.GET_NEW_TOKEN_FAILURE,error}}
}