import React from 'react'
import './newUser.css'
import { Button, Switch} from 'antd';
import {userService} from '../../_services'
import {ExperienceCard} from '../Shared/ExperienceCard/ExperienceCard'
import {EditOutlined} from '@ant-design/icons';
import { Link } from "react-router-dom"
import { connect} from 'react-redux'
import {history } from '../../_helpers'
import { redirectionActions, userActions } from '../../_actions';





class newUser extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            toggleChecked : false,
            userLoaded : false,
            user :{},
            boughtExperiences:[],
            subscribed : false,
            gnibApptSubscriptionToggleSwitch: false,
            selected : "EXP" 

        }
    }


    
    componentDidMount(){
        document.documentElement.scrollTop = 0

        this.props.updateUrl(`/newuser/${this.props.match.params.id}`)
       if(this.props.authentication.loggedIn){
           this.setState({user:this.props.authentication.user,userLoaded:true})
       }else{
        userService.getById(this.props.match.params.id)
        .then(res=> this.setState({user : res.data[0],userLoaded : true}))
        .catch(err => console.error(err))
       }
      }
    handleToggleChange=(checked)=>{
        this.setState({toggleChecked : checked})
    }

    
    handleEditCancel = () => {
        this.props.hideEditModal()
      };

    handleSectionChange = (section) => {
        this.setState({
            selected:section
        })
    }

    onredirectToParams=(text)=>{
        // window.location=`https://www.aliendimension.com/#/newuser/${this.props.user.id}/settings/${text}`
         history.push(`/newuser/${this.props.user.id}/settings/${text}`)
      }



    render(){


     

     
          const ownerOfAccount= this.props.authentication.loggedIn && this.props.match.params.id === this.props.user.id
        if(this.state.userLoaded)
        {
         
            return(
                <div className="user-page">
                    
        
                    <div className="profile__first-section">
                        <div className="user-main-info">
                            <img src={ownerOfAccount ? this.props.user.body.image[1].url:this.state.user.body.image[1].url} className="profile__image"></img>
                            <div className="name-section">
                                <h1>{ownerOfAccount ? this.props.user.body.name.toUpperCase()+" "+this.props.user.body.surname.toUpperCase() : this.state.user.body.name.toUpperCase()+" "+this.state.user.body.surname.toUpperCase()}</h1>
                               {this.state.user.body.address.region ? 
                               <div style={{display :" flex"}}>
                                    {ownerOfAccount&& <h4>{this.state.user.body.address.address}, {this.state.user.body.address.zipCode} -</h4>}
                                    <h4 style={{fontWeight : "bold",marginLeft:"5px"}}> {this.state.user.body.address.region}, {this.state.user.body.address.country}</h4>
                                </div>
                                :
                                ownerOfAccount&& <span onClick={this.onredirectToParams} className="specify-residency-address-cta">Précisez votre adresse de résidence   &#62;</span>}
                            </div>
                            {ownerOfAccount && <button className="edit-profile-cta" onClick={this.onredirectToParams}>Modifiez</button>}
                        </div>
                        <div className="profile__cta-section">
                                
                               {this.state.selected === "EXP" ?
                                    <div className={`item item-active`} onClick={() => this.handleSectionChange('EXP')}>
                                        <svg xmlns="http://www.w3.org/2000/svg" style={{fill:"#fff",marginRight:"4%"}} width="20" height="20" viewBox="0 0 20.31 20"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="About_Us" data-name="About Us"><g id="First_List" data-name="First List"><g id="My_experiences" data-name="My experiences"><g id="experiences_buton" data-name="experiences buton"><g id="Experience_Icon_white" data-name="Experience Icon white"><rect class="cls-1" y="12" width="8" height="8" rx="4"/><rect class="cls-1" x="12.31" y="12" width="8" height="8" rx="4"/><rect class="cls-1" x="12.31" width="8" height="8" rx="4"/><rect class="cls-1" width="8" height="8" rx="4"/></g></g></g></g></g></g></svg>
                                         Expériences
                                    </div>
                                :
                                   <div className={`item `} onClick={() => this.handleSectionChange('EXP')}>
                                        <svg xmlns="http://www.w3.org/2000/svg" style={{marginRight:"4%"}} height="20" width="20" viewBox="0 0 20.31 20"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="About_Us" data-name="About Us"><g id="First_List" data-name="First List"><g id="My_experiences" data-name="My experiences"><g id="experiences_buton" data-name="experiences buton"><g id="Experience_Icon" data-name="Experience Icon"><rect class="cls-1" y="12" width="8" height="8" rx="4"/><rect class="cls-1" x="12.31" y="12" width="8" height="8" rx="4"/><rect class="cls-1" x="12.31" width="8" height="8" rx="4"/><rect class="cls-1" width="8" height="8" rx="4"/></g></g></g></g></g></g></svg>
                                         Expériences
                                    </div>
                                }

                               {this.state.selected==="WISH" ? 
                                    <div className={`item item-active`}onClick={() => this.handleSectionChange('WISH')}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" style={{fill:"#fff",marginRight:"4%"}} viewBox="0 0 9.59 20"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="About_Us" data-name="About Us"><g id="First_List" data-name="First List"><g id="Whishlist"><g id="Whishlist-2" data-name="Whishlist"><path id="On" class="cls-1" d="M8.12,19.62,5.73,15.68a1.1,1.1,0,0,0-1.87,0L1.47,19.62A.79.79,0,0,1,0,19.21V0H9.59V19.21A.79.79,0,0,1,8.12,19.62Z"/></g></g></g></g></g></svg>
                                         Favoris
                                    </div>
                                :
                                    <div className={`item `}onClick={() => this.handleSectionChange('WISH')}>
                                        <svg xmlns="http://www.w3.org/2000/svg"  width="20" height="20"  style={{marginRight:"4%"}} viewBox="0 0 9.59 20"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="About_Us" data-name="About Us"><g id="First_List" data-name="First List"><g id="Whishlist"><g id="Whishlist-2" data-name="Whishlist"><path id="On" class="cls-1" d="M8.12,19.62,5.73,15.68a1.1,1.1,0,0,0-1.87,0L1.47,19.62A.79.79,0,0,1,0,19.21V0H9.59V19.21A.79.79,0,0,1,8.12,19.62Z"/></g></g></g></g></g></svg>
                                         Favoris
                                    </div>

                                }
                        </div>
                    </div>
                    <div className="profile__second-section">
                        {this.state.selected === 'EXP' ?
                            this.state.user.body.bought.length > 0 ?
                            this.state.user.body.bought.map(experience =><div className="user-profile__card" > <ExperienceCard 
                             title={experience.name}
                              id={experience.id}
                               description={experience.description} 
                               coverUrl={experience.Url || experience.coverUrl}
                                category={experience.category}
                                 price={experience.price}
                                 foregroundImage={experience.foregroundImage || experience.forgroundUrl}

                                 /></div>):
                            <h3 style={{color:'rgb(125,125,125)'}}>Aucune expériences n'est acheté.</h3>
                         : 
                            this.state.user.body.wishlists.length > 0 ?
                                this.state.user.body.wishlists.map(experience =><div className="user-profile__card" > <ExperienceCard
                                   title={experience.name}
                                   id={experience.id }  
                                    description={experience.description}
                                    coverUrl={experience.coverUrl} 
                                    category={experience.category}
                                    price={experience.price}
                                    foregroundImage={experience.forgroundUrl}
                                    /></div>):
                                <h3 style={{color:'rgb(125,125,125)'}}><Link to="/products">Remplissez</Link> votre liste de favoris.</h3>
                         }
                    </div>  
                </div>
              
            )
        }else{
            return <h1>LOADING USER ......... PLEASE WAIT </h1>
        }
        
    }
}

function mapState(state){
    const {experiences,authentication} = state
    const {editModalVisible,user,token} = authentication
    return {experiences,authentication,editModalVisible,user,token}
}

const actionCreators = {
    updateFavorite : userActions.updateFavorite,
    updateSubscriptions : userActions.updateSubscriptions,
    updateUrl : redirectionActions.updateUrl
  };

const connectedNewUser = connect(mapState,actionCreators)(newUser)
export { connectedNewUser as newUser}