//import { authHeader } from '../_helpers';
import axios from 'axios'
import {serverConstants} from '../_constants'
/**
 * exporting a userService object with the defined function that send http requests as methods
 * @exports Services__Users
 */
export const userService = {
    login,
    register,
    update,
    getById,
    updateCommunities,
    logout,
    updateFavorite,
    updateSubscriptions,
    updateLikes,
    logInWithFacebook,
    updatePassword,
    resetPassword,
    addAchievement,
    generateOrder,
    generatePaymentUrl,
    PaymentVerification,
    updateBoughtExperiences,
    updateShoppingCart,
    updateAddress,
    subscribeToNewsletter,
    logInWithGoogle,
    getNewToken,
    getOrderById,
    updatePhoneNumber
};
/**
 * sending a POST request to log the user in
 * 
 * @param {string} email  email adress of user for authentication
 * @param {string} password password of user for authentication
 * @returns a promise
 */
function login(email, password) {
    
    return axios.post(`${serverConstants.DOMAIN_NAME}/users/login`,{
        email,
        password
    })
}

/**
 * sending a Get request to get a specific user by ID
 * 
 * @param {string} id  id of user
 * @returns a promise
 */
function getById(id) {
    return axios.get(`${serverConstants.DOMAIN_NAME}/users/${id}`)
}

/**
 * sending a POST request to register new user
 * 
 * @param {object} user info of user wanting to register
 * @returns a promise
 */
function register(user) {
    return axios.post(`${serverConstants.DOMAIN_NAME}/users/add`,user)               
}
/**
 * sending a PUT request to update the info of a specific user
 * 
 * @param {object} newUser newUser info for updates
 * @returns a promise
 */
function update(newUser) {

    return  axios.put(`${serverConstants.DOMAIN_NAME}/users/update`,newUser)
}

function logout(){
    return console.log("logged out")
}

/**
 * sending a PÜT request to update the user's favorites (ADD/REMOVE)
 * 
 * @param {object} req updated array of favorites 
 */
function updateFavorite(req){
    return axios.put(`${serverConstants.DOMAIN_NAME}/users/update/wishlist`,req)
}

function updateCommunities(req){
    return axios.put(`${serverConstants.DOMAIN_NAME}/users/update/community`,req)
}

function updateSubscriptions(req,mode){
    return axios.put(`${serverConstants.DOMAIN_NAME}/users/update/${mode}`,req)
}


function updateLikes(req){
    return axios.put(`${serverConstants.DOMAIN_NAME}/users/update/liked`,req)
}


function logInWithFacebook(req){
    return axios.post(`${serverConstants.DOMAIN_NAME}/users/login/facebook`,req)
} 

function updatePassword(req){
    return axios.put(`${serverConstants.DOMAIN_NAME}/users/update/password`,req)
}

function resetPassword(req){
    return axios.post(`${serverConstants.DOMAIN_NAME}/users/reset`,req)
}

function addAchievement(req){
    return axios.put(`${serverConstants.DOMAIN_NAME}/users/update/achievement`,req)
}

function generateOrder(req){
    return axios.post(`${serverConstants.DOMAIN_NAME}/orders/add`,req)
}


function generatePaymentUrl(req){
    return axios.put(`${serverConstants.DOMAIN_NAME}/orders/update`,req)
}

function PaymentVerification(req){
    return axios.put(`${serverConstants.DOMAIN_NAME}/orders/update/state`,req)
}


function updateBoughtExperiences(req){
    return axios.put(`${serverConstants.DOMAIN_NAME}/users/update/bought`,req)
}

function updateShoppingCart(req){
    return axios.put(`${serverConstants.DOMAIN_NAME}/users/update/cart`,req)
}

function updateAddress(req){
    return axios.put(`${serverConstants.DOMAIN_NAME}/users/update/address`,req)
}

function subscribeToNewsletter(req){
    return axios.put(`${serverConstants.DOMAIN_NAME}/newsletters/update`,req)
}


function logInWithGoogle(req){
    return axios.post(`${serverConstants.DOMAIN_NAME}/users/login/google`,req)
} 

function getNewToken(req){
    return axios.post(`${serverConstants.DOMAIN_NAME}/users/refresh`,req)
}

function getOrderById(id){
    return axios.get(`${serverConstants.DOMAIN_NAME}/orders/bill/${id}`)
}


function updatePhoneNumber(req){
    return axios.put(`${serverConstants.DOMAIN_NAME}/users/update/phone`,req)
}