


import { orderConstants } from '../_constants';

const initialState= {content : {},payment:{}}

export function order(state = initialState, action) {
  switch (action.type) {
    case orderConstants.CREATE_ORDER:
       return {...state,
        content : action.order
    }
    case orderConstants.GOT_PAYMENT_URL:
        
       return {...state,
            payment: action.payment
    }
    case orderConstants.CLEAR_ORDER : 
        return {
            ...initialState
        }     
    default:
      return state
  }
}