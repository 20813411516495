
import { orderConstants } from '../_constants';



export const orderActions = {
    createOrder,
    gotPayment,
    clearOrder
};

function createOrder(order) {
    return dispatch => {
                    dispatch(createOrder(order))
    };
    /**
     * these are the actions to be dispatched 
     */
    
    function createOrder(order) { return { type: orderConstants.CREATE_ORDER, order } }
    
}

function gotPayment(payment) {
    return dispatch => {
                    dispatch(gotPayment(payment))
    };
    /**
     * these are the actions to be dispatched 
     */
    
    function gotPayment(payment) { return { type: orderConstants.GOT_PAYMENT_URL, payment } }
    
}


function clearOrder() {
    return dispatch => {
      

   
                    dispatch(clearOrder())
                  
            
    };
    /**
     * these are the actions to be dispatched 
     */
    
    function clearOrder() { return { type: orderConstants.CLEAR_ORDER } }
    
}