// import React, { useState, useEffect } from 'react'
// import './NewTestingScroll.css'

// const NewTestingScroll = (props) => {

//   useEffect(()=>{

// const html = document.documentElement;
// const canvas = document.getElementById("hero-lightpass");
// const context = canvas.getContext("2d");

// const frameCount = 45;
// const currentFrame = index => (require(
//   `../../assets/animations/First-Section/S1_${index.toString().padStart(5, '0')}.jpg`
// ))

// const preloadImages = () => {
//   for (let i = 1; i < frameCount; i++) {
//     const img = new Image();
//     img.src = currentFrame(i);
//   }
// };

// const img = new Image()
// img.src = currentFrame(1);
// canvas.width=1367;
// canvas.height=768;
// img.onload=function(){
//   context.drawImage(img, -290, 0);
// }

// const updateImage = index => {
//   img.src = currentFrame(index);
//   context.drawImage(img, -290, 0);
// }

// window.addEventListener('scroll', () => {  
//   const scrollTop = html.scrollTop;
//   const maxScrollTop = html.scrollHeight - window.innerHeight;
//   const scrollFraction = scrollTop / maxScrollTop;
//   const frameIndex = Math.min(
//     frameCount - 1,
//     Math.ceil(scrollFraction * frameCount)
//   );
  
//   requestAnimationFrame(() => updateImage(frameIndex + 1))
// });

// preloadImages()
//   },[])

//     return(
//         <div className="animation-container">
//             <canvas id="hero-lightpass" />
//         </div>
//     )
// }




// export {NewTestingScroll}

import React, { useState, useEffect } from 'react'
import './PhoneAnimation.css'

const PhoneAnimation = (props) => {

  useEffect(()=>{


const html = document.documentElement;
const canvas = document.getElementById("hero-lightpass");
const context = canvas.getContext("2d");

const frameCount = 45;
const currentFrame = index => (require(
  `../../assets/animations/First\ Section/Mobile/S1_${index.toString().padStart(5, '0')}.jpg`
))

const preloadImages = () => {
  for (let i = 1; i < frameCount; i++) {
    const img = new Image();
    img.src = currentFrame(i);
    img.width=window.innerWidth
    img.height=window.innerHeight
  }
};  

const img = new Image()
img.src = currentFrame(1);
canvas.width=1000;
canvas.height=1100;
img.onload=function(){
  context.drawImage(img, -40, 0);
}

const updateImage = index => {
  img.src = currentFrame(index);
  context.drawImage(img, -40, 0);
}

window.addEventListener('scroll', () => {  
  const scrollTop = html.scrollTop;
  const maxScrollTop = html.scrollHeight - window.innerHeight;
  const scrollFraction = scrollTop / props.maxScrollTop;
  const frameIndex = Math.min(
    frameCount - 1,
    Math.ceil(scrollFraction * frameCount)
  );

  
  requestAnimationFrame(() => updateImage(frameIndex + 1))
});



preloadImages()
  },[])

    return(
        <div id="animation-container" className="animation-container">
            <canvas id="hero-lightpass"  />
        </div>
    )
}




export {PhoneAnimation}