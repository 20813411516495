import React, { useState, useEffect } from 'react'
import './NewProductsPage.css'

const NewProductsPage=(props) => {
    return(
        <div className="new-products-page">
            Hello world!
        </div>
    )
}

export {NewProductsPage}