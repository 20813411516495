/**
 * Defining and exporting communitiesConstant to unify and simplify assiging action types 
 * @exports Constants__Communities
 */

export const communitiesConstants = {

    GETALL_REQUEST: 'COMMUNITIES_GETALL_REQUEST',
    GETALL_SUCCESS: 'COMMUNITIES_GETALL_SUCCESS',
    GETALL_FAILURE: 'COMMUNITIES_GETALL_FAILURE',

    GETBYID_REQUEST: 'COMMUNITIES_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'COMMUNITIES_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'COMMUNITIES_GETBYID_FAILURE',
    
    GETPOSTSBYID_REQUEST: 'COMMUNITIES_GETPOSTSBYID_REQUEST',
    GETPOSTSBYID_SUCCESS: 'COMMUNITIES_GETPOSTSBYID_SUCCESS',
    GETPOSTSBYID_FAILURE: 'COMMUNITIES_GETPOSTSBYID_FAILURE',

    GETPOSTBYID_REQUEST: 'COMMUNITIES_GETPOSTBYID_REQUEST',
    GETPOSTBYID_SUCCESS: 'COMMUNITIES_GETPOSTBYID_SUCCESS',
    GETPOSTBYID_FAILURE: 'COMMUNITIES_GETPOSTBYID_FAILURE',

    ADDPOST_REQUEST: 'COMMUNITIES_ADDPOST_REQUEST',
    ADDPOST_SUCCESS: 'COMMUNITIES_ADDPOST_SUCCESS',
    ADDPOST_FAILURE: 'COMMUNITIES_ADDPOST_FAILURE',

    GETCOMMENTSBYID_REQUEST: 'COMMUNITIES_GETCOMMENTSBYID_REQUEST',
    GETCOMMENTSBYID_SUCCESS: 'COMMUNITIES_GETCOMMENTSBYID_SUCCESS',
    GETCOMMENTSBYID_FAILURE: 'COMMUNITIES_GETCOMMENTSBYID_FAILURE',
    
    ADDCOMMENT_REQUEST: 'COMMUNITIES_ADDCOMMENT_REQUEST',
    ADDCOMMENT_SUCCESS: 'COMMUNITIES_ADDCOMMENT_SUCCESS',
    ADDCOMMENT_FAILURE: 'COMMUNITIES_ADDCOMMENT_FAILURE',
    
    GETREPLIESBYID_REQUEST: 'COMMUNITIES_GETREPLIESBYID_REQUEST',
    GETREPLIESBYID_SUCCESS: 'COMMUNITIES_GETREPLIESBYID_SUCCESS',
    GETREPLIESBYID_FAILURE: 'COMMUNITIES_GETREPLIESBYID_FAILURE',
    
    ADDREPLY_REQUEST: 'COMMUNITIES_ADDREPLY_REQUEST',
    ADDREPLY_SUCCESS: 'COMMUNITIES_ADDREPLY_SUCCESS',
    ADDREPLY_FAILURE: 'COMMUNITIES_ADDREPLY_FAILURE',


    
};
