import React from 'react';
import './Footer.css';
import {FacebookOutlined,InstagramOutlined,GooglePlusOutlined,LinkedinOutlined} from '@ant-design/icons'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { userService } from '../../_services';
import { Button } from 'antd';

/**
 * The Alien dimension footer component
 * 
 * @exports FooterComponent
 */
function Footer(props) {

  const handleSubscriptionToNewsletter = () => {

    const req= {
      id :"alien_dimension" ,
      email : document.getElementById('input-newsletter').value
    }

    userService.subscribeToNewsletter(req)
      .then(res => toast("Opération effectuée avec succès"))
      .catch(err => toast('Erreur'))

  }

  const redirectTo=(destination ) =>{
    window.location = destination

  }


  const showComingSoonPopUp = () => {
    toast("Coming Soon.", {
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      })
  }

 

  return (
    <div className="footer" id="footer">
      <div className="footer-elements-container">
           <div className="social-media-section">
             <Link to="/"><div className="logo"></div></Link>
             <h3 className="suivez-nous-catch-phrase">Suivez-nous sur</h3>
             <div style={{display:"flex",justifyContent:"space-between",width:"60%",marginTop:"1%"}}>
              
              <svg xmlns="http://www.w3.org/2000/svg" width="22.678" height="22.678" viewBox="0 0 22.678 22.678"  onClick={()=> redirectTo("https://www.instagram.com/alien.dimension/")} className="social-media-icon">
                <g id="Instagram" transform="translate(-1095.184 -4261.399)">
                  <g id="Group_133" data-name="Group 133">
                    <path id="Path_436" data-name="Path 436" d="M1106.523,4263.442c3.028,0,3.386.012,4.582.066a6.289,6.289,0,0,1,2.106.391,3.756,3.756,0,0,1,2.151,2.152,6.268,6.268,0,0,1,.391,2.1c.054,1.2.066,1.554.066,4.582s-.012,3.386-.066,4.582a6.26,6.26,0,0,1-.391,2.1,3.755,3.755,0,0,1-2.151,2.152,6.254,6.254,0,0,1-2.106.391c-1.2.054-1.554.066-4.582.066s-3.386-.012-4.582-.066a6.24,6.24,0,0,1-2.1-.391,3.752,3.752,0,0,1-2.152-2.152,6.285,6.285,0,0,1-.391-2.1c-.054-1.2-.066-1.554-.066-4.582s.012-3.386.066-4.582a6.294,6.294,0,0,1,.391-2.1,3.753,3.753,0,0,1,2.152-2.152,6.274,6.274,0,0,1,2.1-.391c1.2-.054,1.555-.066,4.582-.066m0-2.043c-3.079,0-3.466.013-4.675.068a8.321,8.321,0,0,0-2.752.527,5.789,5.789,0,0,0-3.316,3.317,8.272,8.272,0,0,0-.527,2.752c-.056,1.21-.069,1.6-.069,4.675s.013,3.466.069,4.675a8.272,8.272,0,0,0,.527,2.752,5.793,5.793,0,0,0,3.316,3.317,8.31,8.31,0,0,0,2.752.527c1.209.055,1.6.068,4.675.068s3.466-.013,4.675-.068a8.309,8.309,0,0,0,2.752-.527,5.8,5.8,0,0,0,3.317-3.317,8.322,8.322,0,0,0,.527-2.752c.055-1.209.068-1.6.068-4.675s-.013-3.465-.068-4.675a8.322,8.322,0,0,0-.527-2.752,5.794,5.794,0,0,0-3.317-3.317,8.32,8.32,0,0,0-2.752-.527c-1.209-.055-1.6-.068-4.675-.068" />
                    <path id="Path_437" data-name="Path 437" d="M1106.523,4266.915a5.823,5.823,0,1,0,5.823,5.823,5.823,5.823,0,0,0-5.823-5.823m0,9.6a3.78,3.78,0,1,1,3.78-3.78,3.78,3.78,0,0,1-3.78,3.78" />
                    <path id="Path_438" data-name="Path 438" d="M1113.937,4266.685a1.361,1.361,0,1,1-1.361-1.36,1.361,1.361,0,0,1,1.361,1.36" />
                  </g>
                </g>
              </svg>


              <svg xmlns="http://www.w3.org/2000/svg" width="11.539" height="25" viewBox="0 0 11.539 25"  onClick={()=> redirectTo("https://www.facebook.com/AlienCommunity")} className="social-media-icon" >
  ²             <g id="Facebook" transform="translate(-1046.146 -4260.238)">
  ²               <path id="Path_435" data-name="Path 435" d="M1048.765,4285.238h5.034v-12.606h3.512l.374-4.221H1053.8v-2.4c0-.995.2-1.388,1.162-1.388h2.724v-4.381H1054.2c-3.745,0-5.434,1.65-5.434,4.808v3.365h-2.619v4.274h2.619Z" />
  ²             </g>
  ²           </svg>

                <svg xmlns="http://www.w3.org/2000/svg" width="20.731" height="20.74" viewBox="0 0 20.731 20.74" onClick={()=>redirectTo("https://www.linkedin.com/company/alien-dimension/mycompany/")} className="social-media-icon">
                <g id="Linkedin" transform="translate(-1205.372 -4262.006)">
                  <g id="Group_136" data-name="Group 136">
                    <g id="Group_135" data-name="Group 135">
                      <rect id="Rectangle_160" data-name="Rectangle 160" width="4.28" height="13.827" transform="translate(1205.784 4268.919)" />
                      <path id="Path_441" data-name="Path 441" d="M1207.9,4267.109a2.552,2.552,0,1,0-2.531-2.552A2.542,2.542,0,0,0,1207.9,4267.109Z" />
                    </g>
                    <path id="Path_442" data-name="Path 442" d="M1216.905,4275.488c0-1.944.9-3.1,2.608-3.1,1.574,0,2.33,1.111,2.33,3.1v7.258h4.26v-8.755c0-3.7-2.1-5.494-5.031-5.494a4.821,4.821,0,0,0-4.167,2.285v-1.863h-4.1v13.827h4.1Z" />
                  </g>
                </g>
              </svg>


             </div>
           </div>
           <div className="navigation-section">
             <h3 style={{color:"white",marginBottom:"8%",marginRight:"2.5rem"}}>Naviguer</h3>
             <div style={{display:"flex",flexDirection:"column"}}>
              <Link className="link" to="/products">Expériences</Link>
               <Link className="link" to="/" onClick={showComingSoonPopUp}>Communautés</Link>
              <Link className="link" to="/about-us">À propos de nous</Link> 
             {props.loggedIn &&  <Link className="link" to={`/newuser/${props.user.id}`}>Profil</Link>}
             {props.loggedIn&& <Link className="link" to={`/newuser/${props.user.id}/settings`}>Paramètres</Link>}
             </div>

           </div>
           <div className="contacts-section">
              <h3>Inscrivez vous à la newsletter Alien Dimension</h3>
              <div style={{width:"100%"}}>
                <input id="input-newsletter" className="newsletter-input" type="text" placeholder="Entrer Votre Email"/>
                <button className="newsletter-button" onClick={handleSubscriptionToNewsletter}> S'inscrire</button>
              </div>
              <div style={{display:"flex",flexDirection:"column"}}>
                <span>Email : <b>contact@aliendimension.com</b></span>
                <span>Téléphone : <b>+216 58 557 752</b></span>
                <span>Adresse : <b>8 Rue Ibrahim Jaffel, Manzah 4 Tunis, Tunisie</b></span>
              </div>
             
           </div>
      </div>
      <span className="rights-reserved" >All rights reserved 	&copy; 2021 Alien Dimension </span>
    </div>
  );
}

const mapState = (state) =>{
  const {user,loggedIn} = state.authentication
  return{
    user,
    loggedIn
  }
}

const connectedFooter = connect(mapState)(Footer)

export {connectedFooter as Footer};
