import firebase from 'firebase/app';
import '@firebase/messaging';
var firebaseConfig = {
    apiKey: "AIzaSyCYnfXkHMzz3VQDtj9W8QJLm8QYaKd4HvQ",
    authDomain: "juniornetwork-372d9.firebaseapp.com",
    databaseURL: "https://juniornetwork-372d9.firebaseio.com",
    projectId: "juniornetwork-372d9",
    storageBucket: "juniornetwork-372d9.appspot.com",
    messagingSenderId: "600955840944",
    appId: "1:600955840944:web:ae5b8797398481a2d64e75",
    measurementId: "G-WZC2CQMQ6Q"
  };

firebase.initializeApp(firebaseConfig);

let firebaseAnalytics;


  async function initFirebaseAnalytics(){
       if (!firebaseAnalytics) {
         await require('firebase/analytics');
         firebaseAnalytics = await firebase.analytics();
       }
       return firebaseAnalytics;
     }

initFirebaseAnalytics()

let messaging;

// we need to check if messaging is supported by the browser
if(firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
}

if ('serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
        const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
            updateViaCache: 'none'
        });
        if(registration) 
        messaging.useServiceWorker(registration);
    });
}

export {
    messaging,
    firebaseAnalytics
};



