import React from 'react'
import './Navbar.css'
import {Button,Menu,Dropdown, Modal,Form,Input,Checkbox,AutoComplete,Alert} from 'antd'
import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import { notificationsActions, shoppingCartActions, userActions } from '../../_actions';
import { experiencesActions} from '../../_actions'
import { Link} from 'react-router-dom';
import {communitiesActions} from '../../_actions'
import {BellOutlined,BellFilled,MenuOutlined,LoadingOutlined,SettingOutlined,LockOutlined,LeftOutlined,ShoppingCartOutlined,SearchOutlined} from '@ant-design/icons';
import {history} from '../../_helpers'
import {messaging } from '../../firebase'
import GoogleLogin from 'react-google-login'
import { experiencesService } from '../../_services';
import {isBrowser,isMobile} from 'react-device-detect'
import { ToastContainer, toast } from 'react-toastify';
import { Fragment } from 'react';
import { authentication } from '../../_reducers/authentication.reducer';
import {LoginHooks} from '../GoogleLogin/LoginHooks';
import { LoginWithFacebook } from '../LoginWithFacebook';
import { useGoogleLogout } from 'react-google-login';
import {browserName} from 'react-device-detect'


//import AntSearch from './components/AntSearch'




/**
 * The Alien dimension Navbar component
 * 
 * @exports NavbarComponent
 */
 class Navbar extends React.Component{
  
    constructor(props){
      super(props)
      
      this.state={
        isLoggedin: false,
        user: {},
        submitted : false,
        loading : false,
        loginError: false,
        count :0,
        experiences : [],
        loaded :false,
        searchOptions : [],
        dataSource : [],
        value:'',
        notificationData : null,
        notifications: [],
        experienceNotification: false,
        editPasswordModalVisible : false,
        updatePasswordSuccess : false,
        updatingPassword : false,
        showNotificationCounter : false,
        alertNotification: false,
        showParams : false,
        updates : 0,
        tested :false,
        experiences: [],
        searching : false
      }

    }


    componentDidMount(){
      if(this.props.loggedIn) document.getElementById('navbar_ctas').classList.add = 'before-logo-animation'
      
      window.addEventListener('scroll', this.trackScrolling)
      if(!this.props.experiences.loaded){
      this.props.getAll()
    }
      
      if(this.props.loggedIn && this.props.user.body.shoppingCart){
        this.props.setShoppingCart(this.props.user.body.cartItems)
      }
    
  
    }
    
    
    
    /**
     * when the componentDidmount hook is called we check if the expereieces are loaded ?
     *      -if not we call the experiences and communities getAll action creator tjen we load them on the components state
     *       and we set the search options of the autocomplete to include the experiences titles.
     *      -if the experiences are loaded in the Redux store we simply get them using props and pass them to the components state
     */



  

  
    /**
     * This makes the log in modal visible
     */
    handleSignInClick= () =>{
      if(isBrowser){
        this.props.showModal()
      }else{
        // window.location = "http://localhost:3000/#/sign-in"
         history.push('/sign-in')
      }
        
    }

   
    trackScrolling =( )=> {
      if(document.getElementById('navbar-logo-extended'))document.getElementById('navbar-logo-extended').className = "collapse-transition"
      if(document.getElementById('navbar-logo'))document.getElementById('navbar-logo').className = "navbar-logo-visible"
    }
    

    handleOk = () => {
      this.props.clearLoginError()
      this.setState({ loading: true });
      setTimeout(() => {
        this.setState({ loading: false, modalVisible: false });
      }, 1000);
      };
    
      /**
     * This makes the log in modal invisible
     */

      handleCancel = () => {
        this.props.hideModal()
      };


      /**
     * This function handles the log out by changing the logged in variable in redux store
     */
    handleLogOut = () =>{
      
      this.props.logout()
      
      this.setState({loginError : false,
      loggedOut : true})
     
    }


    /**
     * This function handles the selection of an element from the (search bar) 
     * and redirects the user to the specific experience
     */
     onSelect=(value)=> {

      const selectedExperience= this.state.experiences.filter(experience => experience.body.title === value)
      // window.location = `http://localhost:3000/#/product/${selectedExperience[0].id}/overwiew`
       history.push(`/product/${selectedExperience[0].id}/overwiew`)
      const newsearchOptions = this.state.searchOptions
      this.setState({value : '',
      dataSource : newsearchOptions})
    }


    /**
     * This function handles the search of an element in the autocomplete ( search bar )
     * and shows suggestions and complete search terms
     */
    handleSearch=(value)=>{
      experiencesService.getAll('',0,0,0,value)
        .then(res=> {
          const dataSource = res.data.map(exp => exp.body.title)
          this.setState({
            dataSource,
            experiences : res.data
          })
          
        })
        .catch(err => console.error(err))
      const newdataSource = this.state.searchOptions.filter(option => option.toUpperCase().includes(value.toUpperCase()))
      this.setState({
        dataSource: !value ? this.state.searchOptions : newdataSource,
        value
      });
    }

    handleExperiencePageClick=()=>{
      if(this.state.experienceNotification){
        this.setState({experienceNotification:false})
      }
    }




    showEditPasswordModal= () =>{

        this.props.hideEditModal()
        this.setState({editPasswordModalVisible : true})
        } 

        hideModal= ()=>{
          this.props.hideModal()
        }


        handlNotificationDropdownClick=()=>{
          this.setState({showNotificationCounter:false})
        }    

        responseGoogle=(response)=>{

        }

        startSearching=()=>{
          this.setState({searching : true})
        }

        handleAlertClick=()=>{
          this.setState({alertNotification:false})
        }

        onVoirProfile=()=>{
          // window.location = `http://localhost:3000/#/newuser/${this.props.user.id}`
           history.push(`/newuser/${this.props.user.id}`)
        }

        onVisitExperiences= () => {
          // window.location='http://localhost:3000/#/products'
           history.push(`/products`)

        }

        onRedirectToLogin=()=>{
          // window.location = "http://localhost:3000/#/sign-in"
           history.push('/sign-in')
        }

        onRedirectToSignUp =( )=> {
          // window.location = "http://localhost:3000/#/register"
           history.push('/register')
        }
        
        onVisitCommunities =( )=> {
          toast("Bientôt disponible.", {
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            })

        }
        
        onVisitAboutUs=()=>{
          // window.location = "http://localhost:3000/#/about-us"
           history.push('/about-us')
        }

        onParametres=()=>{
          if(this.state.showParams)this.setState({showParams : false})
          else this.setState({showParams : true})
          
        }

        onVisitNotifications=()=> {
          // window.location = "http://localhost:3000/#/notifications"
           history.push('/notifications')
        }

        onredirectToParams=(text)=>{
          // window.location = `http://localhost:3000/#//newuser/${this.props.user.id}/settings/${text}`
           history.push(`/newuser/${this.props.user.id}/settings/${text}`)
        }

        equalShoppingCarts=(first,second)=>{

         
          let equal = true
          if(first.length !== second.length){
            return false
          }else{
            first.map((item,index) => {
              if(item.id !== second[index].id || item.quantity !== second[index].quantity)return false    
              else return true
            })
          }
         
          return equal
          
        }
      
    render(){
      
      const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
      };
      const tailLayout = {
        wrapperCol: {
          offset: 8,
          span: 16,
        },
      };
      
      const onFinish = (values) => {
        
        this.props.login(values.email,values.password)
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
 

      
    
          
        const notificationsMenu = (
        <Menu onClick={this.handleNotificationClick} style={{display:"flex",flexDirection:'column',width:"20vw",padding:"5%"}}>
            {this.state.notifications.map(notification =>{
               if(notification.content.includes('Post') || notification.content.includes('Comment')|| notification.content.includes('Reply')){
                 return <Link to={`/communities/${notification.communityName}/post/${notification.postId}`} className="notification">{notification.content}</Link>
                }else{
                return <div className="notification">{notification.content}</div>
               }
               
               })}
        </Menu>)

        const settingsMenu = (
          <Menu className="settings-menu" style={{display:"flex",position:"relative",flexDirection:'column',padding:"5% 0% 3% 5%",marginTop:'5%'}}> 
          <div className="arrow"></div>
            <div className="settings_menu_item"><Button className="settings_menu_item_button" type="text" onClick={this.onVoirProfile}> <img src={require('../../assets/icons/ProfileIcon.png')} style={{marginRight:'1rem',marginLeft:'.5rem'}}/> Voir votre Profil</Button></div>
            <div className="settings_menu_item"><Button className="settings_menu_item_button" type="text"onClick={this.onredirectToParams}> <img src={require('../../assets/icons/Setting.png')} style={{marginRight:'1rem',marginLeft:'.5rem'}}/> Paramètres</Button></div>
            <div className="settings_menu_item" style={{borderTop:"1px solid #ccc",paddingTop:"2px"}}><Button className="settings_menu_item_button" type="text"onClick={this.handleLogOut} > <LeftOutlined style={{marginRight:'.8rem',marginLeft:'.5rem'}}/>Déconnecter</Button></div>
          </Menu>
        )

        const parametres = (
          <Menu className="parametres-menu" style={{display:"flex",flexDirection:'row',width:"20vw",marginTop:'5%',paddingLeft:"1rem"}}> 
            <Button shape='circle' type='ghost' style={{border:'none'}} onClick={this.onParametres}><LeftOutlined /></Button>
            <div style={{display:"flex",flexDirection:"column"}} className="parametres-items-container">
              <div className="settings_menu_item"><Button className="settings_menu_item_button" type="text" onClick={()=>this.onButtonClick('Parametres de compte')}> <SettingOutlined />Parametres de compte</Button></div>
              <div className="settings_menu_item"><Button className="settings_menu_item_button" type="text"onClick={()=>this.onButtonClick('Securite de compte')}> <LockOutlined />Securite de compte</Button></div>
              <div className="settings_menu_item"><Button className="settings_menu_item_button" type="text"onClick={()=>this.onButtonClick('Parametres de notifictaions')}> <LockOutlined />Parametres de notifictaions</Button></div>
            </div>
          </Menu>
        )

       


        const hamburgermenu=(
          <Menu className="hamburger"> 
            {this.props.loggedIn ? 
            null
            :
            <Fragment>
                  <Menu.Item style={{color:'white'}} onClick={this.onRedirectToLogin}>Se connecter</Menu.Item>
                  <Menu.Item style={{color:'white'}} onClick={this.onRedirectToSignUp}>S'inscrire</Menu.Item>
                  <hr/>
            </Fragment>
            }
            
            <div>
              
            </div>
            <Menu.Item style={{color:'white'}} onClick={this.onVisitExperiences}>Expériences</Menu.Item>
            <Menu.Item style={{color:'white'}} onClick={this.onVisitCommunities}>Communautés</Menu.Item>
            <Menu.Item style={{color:'white'}} onClick={this.onVisitAboutUs}>A propos de nous</Menu.Item>
            

            <hr/>
            {/* <Menu.Item style={{color:'white'}} onClick={this.startSearching}>Search</Menu.Item> */}
            <Menu.Item style={{color:'white'}} onClick={this.onVisitNotifications}>Notifications</Menu.Item>
            {/* {this.props.loggedIn && <hr/>} */}
            {/* {this.props.loggedIn && <Menu.Item style={{color:'white'}} onClick={this.handleLogOut}>Log out</Menu.Item>} */}
            
            
          </Menu>
        )
      
  
      
          

          

         
            return(
              

              <div className="navbar" id="navbar">
                {this.state.searching &&  <div className="mobile-search">
                   <AutoComplete
                    style={{width:"100%"}}
                    dataSource={this.state.dataSource}
                    value={this.state.value}
                    onSelect={this.onSelect}
                    onSearch={this.handleSearch} 
                  >
                    <Input type="text"  placeholder="Rechercher..." prefix={<SearchOutlined style={{color:"white"}}/>} style={{background:"rgb(59, 59, 59)",borderRadius:"100px",transform:"scale(.7)"}}></Input>
                  </AutoComplete> 
               
                </div>  }
                <div style={{display:"flex"}}>
                  <Link to="/"><div id="navbar-logo" className="navbar_logo"></div></Link>
                  <Link to="/"><div id="navbar-logo-extended" className="navbar_logo-extended"></div></Link>
                </div>
                
                <div  className={`navbar_links ${this.props.loggedIn && "a" }`}>
                  <div className="navbar_link">
                    <Button type="link" onClick={this.onVisitExperiences} style={{color:"white"}}>Expériences</Button>
                    <div className="experiences-highliting"></div>
                  </div>
                  <div className="navbar_link">
                  <Button type="link" onClick={this.onVisitCommunities} style={{color:"white"}}>Communautés</Button>
                    <div className="experiences-highliting"></div>
                  </div>
                  <div className="navbar_link">
                  <Button type="link"  onClick={this.onVisitAboutUs} style={{color:"white"}}>À propos de nous</Button>
                    <div className="experiences-highliting"></div>
                  </div>

                 
                 
                </div>
                {this.props.loggedIn ? <div id="navbar_ctas" className="navbar_ctas" >
                  <div className="autocomplete-container">
                  <AutoComplete
                    style={{width:225}}
                    dataSource={this.state.dataSource}
                    value={this.state.value}
                    onSelect={this.onSelect}
                    onSearch={this.handleSearch} 
                  >
                    <Input type="text" className="autocomplete-input" placeholder="Rechercher..." prefix={<SearchOutlined style={{color:"white"}}/>} style={{background:"rgb(59, 59, 59)",borderRadius:"100px",transform:"scale(.7)"}}></Input>
                  </AutoComplete>
                   </div> 
                 
                  <div className="shopping-cart-container">
                  <div style={{position:"relative"}}>
                               <Link to="/shoppingcart">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 25.122 21.349">
                                    <g id="Pannier" transform="translate(1.001 0.852)">
                                      <path id="Path_464" data-name="Path 464" d="M2293,22l.091-.434a1.942,1.942,0,0,1,3.693-.371l.014.04L2301,29h11.1a2.522,2.522,0,0,0,2.425-1.829L2316,22h-18" transform="translate(-2293 -20.003)" fill="none" stroke="#f2f2f2" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.691"/>
                                      <circle id="Ellipse_49" data-name="Ellipse 49" cx="1.5" cy="1.5" r="1.5" transform="translate(7.5 17.497)" fill="#f2f2f2"/>
                                      <circle id="Ellipse_50" data-name="Ellipse 50" cx="1.5" cy="1.5" r="1.5" transform="translate(15.5 17.497)" fill="#f2f2f2"/>
                                      <path id="Path_465" data-name="Path 465" d="M2301.535,35h8.972a2.489,2.489,0,0,0,2.227-3.6l-1.2-2.4" transform="translate(-2293 -20.003)" fill="none" stroke="#f2f2f2" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.691"/>
                                    </g>
                                  </svg>

                                 </Link>
                               {this.props.shoppingCart.items.length >0 ?
                                 <span className="shopping-cart-item-count">{this.props.shoppingCart.items.length}</span>
                                 :
                                 null
                               }
                             </div>
                  </div>
                  <div className="notifications-icon">
                  <Dropdown 
                               overlay={notificationsMenu}
                               trigger={['click']}  
                               onClick={this.handlNotificationDropdownClick}
                    
                             >
                               <div className="notification-section">
                                   {this.state.notifications.length> 0 ?
                                   // bell icon filled
                                   <div>

                                     <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 17.371 21.691" style={{fill:"green"}}>
                                   <g id="NotifIcon" transform="translate(1.003 0.845)">
                                     <path id="Path_462" data-name="Path 462" d="M2470.641,19.4h0a6.248,6.248,0,0,0-6.157,5.186l-1.525,8.848h15.364l-1.525-8.848A6.248,6.248,0,0,0,2470.641,19.4Z" transform="translate(-2462.959 -19.4)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.691"/>
                                     <path id="Path_463" data-name="Path 463" d="M2474.021,36.327a3.073,3.073,0,1,1-6.146,0" transform="translate(-2462.959 -19.4)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.691"/>
                                   </g>
                                  </svg>

                                   {this.state.showNotificationCounter ?<div id="notification_counter" className="notification-counter " >{this.state.notifications.length}</div>:null}
                                   </div> 
                                   : 
                                  //  bell icon outliuned
                                   <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 21.691" style={{fill:"green"}}>
                                   <g id="NotifIcon" transform="translate(1.003 0.845)">
                                     <path id="Path_462" data-name="Path 462" d="M2470.641,19.4h0a6.248,6.248,0,0,0-6.157,5.186l-1.525,8.848h15.364l-1.525-8.848A6.248,6.248,0,0,0,2470.641,19.4Z" transform="translate(-2462.959 -19.4)" fill="none" stroke="#f2f2f2" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.691"/>
                                     <path id="Path_463" data-name="Path 463" d="M2474.021,36.327a3.073,3.073,0,1,1-6.146,0" transform="translate(-2462.959 -19.4)" fill="none" stroke="#f2f2f2" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.691"/>
                                   </g>
                                  </svg>

                                 }
                              
                             
                               </div>
                          
                             </Dropdown>
                             
                  </div>
                  <div className="profile-icon-section">
                    <Dropdown
                      overlay={settingsMenu}
                      trigger={['click']}      
                    >
                    <img src={this.props.user.body.image[2].url} alt="" className="profile-icon"></img>
                
                    </Dropdown>
                  </div> 
                  <div className="hamburgermenu-section">
                  <Dropdown
                    className="hamburgermenu"
                      overlay={hamburgermenu}
                      trigger={['click']}    
                    >
                    <Button shape="circle" ghost style={{border:"none"}} icon={<MenuOutlined />}></Button>
                
                    </Dropdown>
                    </div>
                                    
                  
                </div>
                :
                <div classNames={`logged-out-ctas `} className="logged-out-ctas ">
                    <div className="autocomplete-container">
                  <AutoComplete
                    
                    dataSource={this.state.dataSource}
                    value={this.state.value}
                    onSelect={this.onSelect}
                    onSearch={this.handleSearch} 
                  >
                    <Input type="text" className="autocomplete-input" placeholder="Rechercher..." prefix={<SearchOutlined style={{color:"white"}}/>} style={{background:"rgb(59, 59, 59)",borderRadius:"100px",transform:"scale(.7)"}}></Input>
                  </AutoComplete>
                   </div> 
                 
                  <div className="shopping-cart-container">
                  <div style={{position:"relative"}} className="shopping-cart-icon">
                               <Link to="/shoppingcart"><ShoppingCartOutlined style={{color:'white',fontSize:"1.2rem"}} /></Link>
                               {this.props.shoppingCart.items.length >0 ?
                                 <span className="shopping-cart-item-count">{this.props.shoppingCart.items.length}</span>
                                 :
                                 null
                               }
                             </div>
                  </div>

                    <Button type='text' className="sign-in__button" size="small"  onClick={this.handleSignInClick}>Se connecter</Button> 
                    <Link to="/register"><Button  type="danger" className="sign-up__button" size="small">S'inscrire</Button></Link> 
                    <div className="hamburgermenu-section">
                  <Dropdown
                    className="hamburgermenu"
                      overlay={hamburgermenu}
                      trigger={['click']}    
                    >
                    <Button shape="circle" ghost style={{border:"none"}} icon={<MenuOutlined />}></Button>
                
                    </Dropdown>
                    </div>
                </div>
                
                }
                <Modal
                     title="Bienvenue "
                     className="signIn-modal"
                     visible={this.props.modalVisible}
                           onOk={this.handleOk}
                           onCancel={this.handleCancel}
                         >
                          
                        <div className="sign-in-form" style={{marginLeft:"8%"}}>
                        <Form
                        name="basic"
                        initialValues={{
                          remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                      >
                        <Form.Item
                                               style={{marginTop:"1rem"}}

                          name="email"
                          rules={[
                            {
                              required: true,
                              type:'email',
                              message: "Prière d'entrer votre adresse E-mail",
                            },
                          ]}
                        >
                          <Input placeholder="E-mail" style={{borderRadius:"25px",padding:"1% 5%"}}/>
                        </Form.Item>
                        
                        <Form.Item
                         style={{marginTop:"1rem"}}
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Prière d'entrer votre mot de passe",
                            },
                          ]}

                        >
                          <Input.Password  placeholder="Mot de passe" style={{borderRadius:"25px",padding:"1% 5%"}}/>
                        </Form.Item>

                         {this.props.logInError ?<span style={{marginLeft:"1rem",marginTop:"1rem",color:"rgb(100,100,100)",fontSize:".8rem"}}>Vérifier vos informations d'identification et réessayer</span> :null }
                        
                        <div style={{display:"flex",justifyContent:"space-between",marginTop:"1rem",marginBottom:"1rem",alignItems:"center"}}>
                        <Link to="/resetpasswordrequest"><span style={{marginLeft:"1rem",color:"rgb(115,115,115)"}}> Mot de passe oublié ?</span></Link>
                        
                        <Button type="primary" htmlType="submit" style={{borderRadius:"25px",padding:"1% 7%",background:'#0068ff'}}>
                          Se connecter
                        </Button>
                        </div>
                          
                      </Form>
                
                        {!browserName.toUpperCase().includes('FACEBOOK')&&<LoginHooks style={{height:"25px"}}/>}
                        <LoginWithFacebook/>
                        

                          
                    
                      </div>
                                          
                  
                 
                    
                         </Modal>
                         <ToastContainer position="bottom-center"/>

              </div>
          )
        
    }
    
}


function mapState(state) {
  const { experiences } =state
  const {shoppingCart} = state
    const { loggingIn,loggedIn,modalVisible,editModalVisible,user,logInError,token } = state.authentication; 
  return { loggingIn,
  user,
  loggedIn,
  token,
  modalVisible,
  editModalVisible,
  experiences,
  logInError,
  shoppingCart};
}

const actionCreators = {
  communitiesGetAll: communitiesActions.getAll,
  getAll: experiencesActions.getAll,
  login: userActions.login,
  logout : userActions.logout,
  showModal : userActions.showModal,
  hideEditModal : userActions.hideEditModal,
  hideModal : userActions.hideModal,
  updateUser : userActions.update,
  loginWithFacebook : userActions.logInWithFacebook,
  setShoppingCart : shoppingCartActions.setShoppingCart,
  updateUserShoppingCart: userActions.updateShoppingCart,
  pushNotification: notificationsActions.pushNotification
};
const connectedNavbar = connect(mapState, actionCreators)(Navbar);
export  {connectedNavbar as Navbar}