

export const orderConstants = {

    CREATE_ORDER: 'CREATE_ORDER',

    GOT_PAYMENT_URL : 'ORDER_GOT_PAYMENT_URL',

    CLEAR_ORDER : 'CLEAR_ORDER'
};
