/**
 * Defining and exporting experiencesConstant to unify and simplify assiging action types 
 * @exports Constants__Experiences
 */
export const experiencesConstants = {
    
    GETALL_REQUEST: 'EXPERIENCES_GETALL_REQUEST',
    GETALL_SUCCESS: 'EXPERIENCES_GETALL_SUCCESS',
    GETALL_FAILURE: 'EXPERIENCES_GETALL_FAILURE',

    UPDATE_EXPERIENCE_LIKES : "UPDATE_EXPERIENCE_LIKES"

};
