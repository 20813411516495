//import { authHeader } from '../_helpers';
import axios from 'axios'
import {serverConstants} from '../_constants'
/**
 * exporting an experiencesService object with the defined function that affect http requests as methods
 * @exports Services__Experiences
 */
export const experiencesService = {
    getAll,
    getById,
    getByCategory,
    getByPriceRange,
    getByKeywords,
    getmaxPrice,
    getQuizzByExperienceId,
    updateLikes
};

/**
 * sending a GET request to get an array of experiences from DataBase 
 * @returns a promise
 */
function getAll(category='',min=0,max=0,createdOn=0,keywords='') {
    return axios.get(`${serverConstants.DOMAIN_NAME}/experiences/search/?category=${category}&min=${min}&max=${max}&createdOn=${createdOn}&keywords=${keywords}`);
}

function getById(id){
    return axios.get(`${serverConstants.DOMAIN_NAME}/experiences/${id}`)
}


function getByCategory(category){
    return axios.get(`${serverConstants.DOMAIN_NAME}/experiences/search/category?cls=${category}`)
}

function getByPriceRange(min,max){
    return axios.get(`${serverConstants.DOMAIN_NAME}/experiences/search/price?min=${min}&max=${max}`)
}


function getByKeywords(keywords){
    return axios.get(`${serverConstants.DOMAIN_NAME}/experiences/search/keywords?keywords=${keywords}`)
}

function getmaxPrice(){
    return axios.get(`${serverConstants.DOMAIN_NAME}/filters/first`)
}

function getQuizzByExperienceId(id){
    return axios.get(`${serverConstants.DOMAIN_NAME}/experiences/${id}/quizzes`)
}

function updateLikes(req){
    return axios.put(`${serverConstants.DOMAIN_NAME}/experiences/add/likes`,req)
}