import React, { useState, useEffect } from 'react'
import './LoginWithFacebook.css'
import FacebookLogin from 'react-facebook-login'
import {LoadingOutlined} from '@ant-design/icons';

import { userActions } from '../../_actions'
import {connect} from'react-redux'

const LoginWithFacebook =(props )=>{


const responseFacebook = response =>  {
    if(response){

    props.loginWithFacebook({
      token : response.accessToken
    })
    
    }
  }


    return(
        <div className="login-facebook-button" >
          <div className="facebook-logo"></div>
            <FacebookLogin
                              appId="736543897284842"
                              autoLoad={false}
                              fields="name,email,picture"
                              callback={responseFacebook}
                              cssClass="my-facebook-button-class"
                              textButton="Se connecter avec Facebook"
                              redirectUri="http://localhost:3000/#/sign-in"
            ></FacebookLogin> 
          {props.authentication.loggingInWithFacebook && <LoadingOutlined/>}
        </div>
    )
}

const mapState = (state) => {
  const {authentication} = state
  return{
    authentication
  }
}

const actionCreators= {
  loginWithFacebook :userActions.logInWithFacebook
}

const connectedLoginWithFacebook = connect(mapState,actionCreators)(LoginWithFacebook)

export {connectedLoginWithFacebook as LoginWithFacebook}