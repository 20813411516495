

import { act } from 'react-dom/test-utils';
import { shoppingCartConstants } from '../_constants';

const initialState= {items : [],total :0 }

export function shoppingCart(state = initialState, action) {
  switch (action.type) {
    case shoppingCartConstants.ADD:
        let newTotal = state.total
        let newItems = state.items
        if(!state.items.find(item => item.id === action.product.id)){
            newItems.push({...action.product,
                quantity: 1})
                newTotal = newTotal + action.product.price
        }else{
                const itemIndex = newItems.findIndex(item => item.id === action.product.id)
                const oldItem = newItems[itemIndex]
                newItems[itemIndex] = {
                    ...oldItem,
                    quantity: oldItem.quantity+1
                }
                newTotal = newTotal+ newItems[itemIndex].price

        }
        
        return{
            ...state,
            items : newItems,
            total :newTotal
        }
    case shoppingCartConstants.DELETE:
        let Total = state.total
        let newestItems = state.items
        let foundItem= state.items.find(item => item.id === action.product.id)
        if(foundItem.quantity===1) {
            newestItems =state.items.filter(item => item.id !== action.product.id)
            Total = Total - foundItem.price
        }else{
            const itemIndex = newestItems.findIndex(item => item.id === action.product.id)
            const oldItem = newestItems[itemIndex]
            newestItems[itemIndex] = {
                ...oldItem,
                quantity: oldItem.quantity-1,
              
            }
            
            Total = Total - newestItems[itemIndex].price
        }
       
        return{
            ...state,
            items : newestItems,
            total: Total
        }
    case shoppingCartConstants.SET_SHOPPING_CART :
        let total = 0 
        action.cartItems.forEach(item => total = total + item.price* item.quantity) 
     
        return {
            items :action.cartItems,
            total
        }    
    case shoppingCartConstants.CLEAR_SHOPPING_CART : 
        return {
            ...state,
            items : [],
            total : 0
        } 
    case shoppingCartConstants.DELETE_ITEM_FROM_CART : 
        const foundItem2 = state.items.find(item => item.title === action.title)
        const newItems2 = state.items.filter(item=> item.title !== action.title)
        const newTotal2 = state.total - foundItem2.price * foundItem2.quantity
    return{
        items : newItems2,
        total : newTotal2
    }
    case shoppingCartConstants.SET_BILLING_SHOPPING_CART : 
    return {
        ...state,
        billingShoppingCart : action.shoppingCartContent
    }
    default:
      return state
  }
}