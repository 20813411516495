import React, { Component } from 'react'
import {connect} from 'react-redux'
import {CloseOutlined,LoadingOutlined } from '@ant-design/icons';
import {userService} from '../../_services'
import './UserSettings.css'
import {history} from '../../_helpers/history'
import { userActions } from '../../_actions';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router';


class UserSettings extends Component{
    constructor(props){
        super(props)

        this.state = {
            editSelected: false,
            securitySelected : false,
            notificationsSelected : false
        }
    }

    componentDidMount(){
      document.documentElement.scrollTop = 0

    
    }

   
      handleUnsubscription= async (topic) => {
   
     
      
      
      if(this.props.loggedIn && this.props.user.body.subscriptions.includes(topic)){
       
        const subscriptions = this.props.user.body.subscriptions.filter(sub => sub !== topic)
        
        const req = {
            user: {
                id: this.props.user.id,
                token : this.props.token,
                subscriptions
            },
            subscription : {
                token : localStorage.getItem('INSTANCE_TOKEN'),
                topic : topic
            }
        }

        this.props.updateSubscriptions(req,'unsubscribe') 
            

      }
       
    }

    
    render(){
        
        


        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
          };
          const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
          };

          const onEditFinish = values =>{

            if( (document.getElementById('address-country').value !== ""
             && document.getElementById('address-region').value !== ""
            && document.getElementById('address-address').value !== "" 
            && document.getElementById('address-zipCode').value !== "") ||
            (document.getElementById('address-country').value === ""
            && document.getElementById('address-region').value === ""
           && document.getElementById('address-address').value === "" 
           && document.getElementById('address-zipCode').value === "")
            ){
              const req={
                id : this.props.user.id,
                token: this.props.token,
                address : {
                  country : document.getElementById('address-country').value,
                  region :document.getElementById('address-region').value,
                  address : document.getElementById('address-address').value,
                  zipCode : parseInt(document.getElementById('address-zipCode').value)
                }
            }
            this.props.updateAddress(req)
            }else if(document.getElementById('address-country').value !== ""){
              alert('please specify full address')
            }

            const newuser = {
              id:this.props.user.id,
              name : document.getElementById('first-name').value ===""  ? this.props.user.body.name : document.getElementById('first-name').value,
              surname : document.getElementById('last-name').value === ""  ? this.props.user.body.surname : document.getElementById('last-name').value,
              phoneNumber : document.getElementById('phone-number').value === ""  ? this.props.user.body.phoneNumber :parseInt(document.getElementById('phone-number').value),
              image : "randof",
              token : this.props.token
            }
            if(newuser.name !== this.props.user.body.name || newuser.surname !== this.props.user.body.surname || newuser.phoneNumber !== this.props.user.body.phoneNumber)
            {
             
              this.props.updateUser(newuser)
              document.getElementById('first-name').value =""
              document.getElementById('last-name').value =""
              document.getElementById('phone-number').value =""
             

            }
          }

          const onFinishFailed = errorInfo => {
          };

          const onChnagePasswordFinish = values =>{
            this.setState({updatingPassword : true})
              const req = {
                id : this.props.user.id,
                email : this.props.user.body.email,
                password : document.getElementById('current-password').value,
                newPassword : document.getElementById('new-password').value,
                token : this.props.token
              }
              if(document.getElementById('new-password').value === document.getElementById('re-type-new-password').value && document.getElementById('new-password').value && document.getElementById('current-password').value){
                userService.updatePassword(req)
                .then(res => {
                  this.setState({updatingPassword:false,updatePasswordSuccess:true,updatePasswordError:false})
                  document.getElementById('current-password').value = ""
                  document.getElementById('new-password').value =""
                  document.getElementById('re-type-new-password').value =""
                  toast("Operation effectuée.",{
                    autoClose : 2111
                  })
                })
                .catch(err => 
                  {
                    toast("Erreur de changement du mot de passe.",{
                      autoClose : 2111
                    })
                    this.setState({updatingPassword:false,updatePasswordError:true,updatePasswordSuccess:false})}
                  )

              }
   
            
          }

          if(this.props.updated){
            toast("Operation effectuée.",{
              autoClose:2111
            })
            this.props.resetUpdated()
          }
        if(!this.props.loggedIn){
          return <Redirect to='/'/>
        }else{
          return(
            
            <div className="user-settings-page">
              <div className="menu">
                  <div className="menu-item">
                      <div className="icon profile-settings"></div>
                      <h1 className="settings-title">PARAMÈTRES DU COMPTE</h1>
                      <p className="settings-description">Ici, vous pouvez modifier votre nom et votre numéro de téléphone</p>
                      <a href="#account-settings" className="redirect">Cliquez ici</a>
                  </div>
                  <div className="menu-item">
                      <div className="icon security-settings"></div>
                      <h1 className="settings-title">PARAMÈTRES DE SÉCURITÉ</h1>
                      <p className="settings-description">Ici, vous pouvez changer votre mot de passe actuel</p>
                      <a href="#security-settings" className="redirect">Cliquez ici</a>

                  </div>
                  <div className="menu-item">
                      <div className="icon notification-settings"></div>
                      <h1 className="settings-title">PARAMÈTRES DE NOTIFICATION</h1>
                      <p className="settings-description">Ici, Vous pouvez modifier ici vos abonnements aux notifications</p>
                      <a href="#notifications-settings" className="redirect">Cliquez ici</a>

                  </div>
              </div>
              <hr/>
              <div  id="account-settings" style={{padding:"5%",marginTop:"2%"}}>
                  <div className="account-settings">
                    <div className="settings-icon-container">
                      <div className="profile-settings-icon settings-icon"></div>
                    </div>
                      <div className="form">
                        <input id="first-name" className="input" type="text" placeholder="Prénom"/>
                        <input id="last-name" className="input" type="text" placeholder="Nom de famille"/>
                        <input id="phone-number" className="input" type="text" placeholder="Numéro de téléphone"/>
                        <div style={{marginTop:'5%'}}>
                          <input id="address-country" className="address-input input address-input-1" type="text" placeholder="Pays"/>
                          <input id="address-region" className="address-input input" type="text" placeholder="Région"/>
                          <input id="address-address" className="address-input input address-input-3" type="text" placeholder="Adresse"/>
                          <input id="address-zipCode" className="address-input input" type="text" placeholder="Code postal"/>

                        </div>
                       
                        <button className="update" onClick={onEditFinish}>Modifiez</button>
                      </div>
                  </div>

                  <div id="security-settings" className="account-settings">
                    <div className="settings-icon-container">
                      <div className="security-settings-icon settings-icon"></div>
                    </div>
                      <div className="form">
                        <input id="current-password" className="input" type="text" placeholder="Mot de passe actuel"/>
                        <input id="new-password" className="input" type="text" placeholder="Nouveau mot de passe"/>
                        <input id="re-type-new-password" className="input" type="text" placeholder="Confirmez le nouveau mot de passe"/>
                        <button className="update" onClick={onChnagePasswordFinish}>Modifiez</button>
                      </div>
                  </div>

                  <div id="notifications-settings" className="account-settings">
                    <div className="settings-icon-container">
                      <div className="notification-settings-icon settings-icon"></div>
                    </div>
                      <div className="notifications-container">
                        <span className="notitications__header">Je reçois des notification des canaux :</span>
                      {this.props.user.body && this.props.user.body.subscriptions.length >0 ?

                              this.props.user.body.subscriptions.map(subscription=><div className="notification-container">
                              <span className="notification-name"><b>{subscription}</b></span>
                              <div className="notification-cta" onClick={()=>this.handleUnsubscription(subscription)}> <CloseOutlined /></div>
                            </div>
                            ):"No subscriptions"
                            }
                        
                        
                      
                      </div>
                  </div>

              </div>
            </div>
        )
        }
       
    }
}

function mapState(state){
    const {user,loggedIn,token,updating,updated} =  state.authentication
    return{
        user,
        loggedIn,
        token,
        updating,
        updated
    }
}

const actionCreators ={
    updateUser : userActions.update,
    updateSubscriptions : userActions.updateSubscriptions,
    resetUpdated : userActions.resetUpdated,
    updateAddress : userActions.updateAddress
}

const connectedUserSettings = connect(mapState,actionCreators)(UserSettings)
export {connectedUserSettings as UserSettings}