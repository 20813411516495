import React, { useState, useEffect } from 'react'
import { redirectionActions } from '../../_actions'
import {connect} from 'react-redux'
import './AboutUs.css'

const AboutUs = ( props ) => {

    useEffect(()=>{
        props.updateUrl('/about-us')
        document.documentElement.scrollTop = 0
    },[])
    return(
        <div className="about-us-page">
            <div className="about-us__first-section">
                <div className="first-quote">
                <span>- Vision</span>
                    <h3>" Donner vie à l’imagination."</h3>
                  
                </div>
                <img className="eye" src={require('../../assets/images/Eye.png')} alt="about-us"/>
            </div>
            <div className="about-us__second-section">
                <div className="about-us__second-section__left">
                    <div className="quote-section">
                        <div className="quote-line"></div>
                        <div className="quote-container">
                        <span>- Mission</span>    

                            <h3>"Nous dépassons les limites pour donner aux gens le pouvoir d'explorer de nouveaux horizons."</h3>
                        </div>
                        
                    </div>

                </div>
                <div className="about-us__second-section__right">
                    <div className="about-us__text">
                        <h3>Notre histoire</h3>
                        <p>A l'ère digitale, les méthodes d’apprentissage n’ont pas changé ! 
                            Les parents et les jeunes apprenants reprochent le manque de pratique et d’interactivité de ces méthodes. 
                            Pour dépasser ces limites, nous avons conçu Alien Dimension, une application mobile de réalité augmentée qui donne à chaque apprenant le pouvoir de transformer son propre environnement en un laboratoire bien équipé. 
                            Les institutions scolaires auront un outil pédagogique à haute définition technologique qui leur permettra de partager des expériences interactives, basées sur la pratique avec leurs étudiants.</p>
                    </div>
                   
                    <img className="mars-texture" src={require('../../assets/images/Texture Mars.png')} alt="about-us"/>

                    <div className="about-us__text">
                        <h3>Nos valeurs </h3>
                        <p> 1- Soyez le leader que vous souhaitez avoir.<br/>
                            2- Oser de penser plus fort, de créer et d’aller plus loin.<br/>
                            3- Toujours couvrir le dos de votre collègue.
                            </p>
                    </div>
                </div>
                    
            </div>
        </div>
    )
}

const mapState = (state) => {
    const {redirection} = state
    return{
        redirection
    }
}

const actionCreators={
    updateUrl : redirectionActions.updateUrl
}

const connectedAboutUs = connect(mapState,actionCreators)(AboutUs)

export { connectedAboutUs as AboutUs}