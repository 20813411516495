import React, { useEffect, useState } from 'react'
import './PaymentSuccess.css'
import {CheckCircleTwoTone } from '@ant-design/icons'
import {connect} from 'react-redux'
import {history} from '../../_helpers'
import {Link} from 'react-router-dom'
import { userService } from '../../_services'
import { orderActions, shoppingCartActions, userActions } from '../../_actions'

const PaymentSuccess = (props) =>{

    const [mount,setMount] = useState(0)

    useEffect(()=>{

        document.documentElement.scrollTop = 0

              var url = new URL(window.location.href);
            const req={
                order : {
                    payment :{
                        method: "konnect",
                        id: url.searchParams.get("payment_ref")
                    }
                }
            }
            
            
        userService.PaymentVerification(req)
            .then(res => {
                setTimeout(()=>{

                },1000)
                props.clearShoppingCart()
             
                
         
            })
            .catch(err => console.error(err))
        
       
    },[])

   
    
    return(
        <div className="payment-success-page">
               
                <div className="first-section">
                     <div className="background-image">
                         
                     </div>
                </div>
               
                <div className="second-section">
                    <h1>Paiment validée</h1>
                    
                    
                    <p>Chèr(e) <b>{props.user.body && props.user.body.name+" "+ props.user.body.surname}</b>, merci pour votre confiance.<br/>

                    Le paiement est effectué avec succès.<br/>
                    
                    Vous pouvez commencer, dés maintenant,  {props.shoppingCart.items.length>1?"les expériences":"l'experience"} :<br/>
                    <ul>
                        {props.order.content.orderItems&&props.order.content.orderItems.map(item => <li><b>{item.boughtExperienceName}</b></li>)}
                    </ul>
                    Votre paquet sera livré au plus tard dans X jours !<br/>
                    Nous restons toujours à votre disposition &lt;3 .<br/>
                    <Link to={`/generate-bill/${props.order.content.id}`}><b>Facture</b></Link>.</p>
                
                     </div>
              
        </div>
    )
}


const mapState=(state)=>{
    const {user} = state.authentication
    const {order} = state
    const {authentication} = state
    const {shoppingCart} = state
    return{
        user,
        order,
        authentication,
        shoppingCart
    }
}

const actionCreators = {
    clearShoppingCart : shoppingCartActions.clearShoppingCart,
    clearOrder : orderActions.clearOrder,
    updateBoughtExperiences: userActions.updateBoughtExperiences,
    setBillingShoppingCart : shoppingCartActions.setBillingShoppingCart
}


const connectedPaymentSuccess = connect(mapState,actionCreators)(PaymentSuccess)
export {connectedPaymentSuccess as PaymentSuccess}