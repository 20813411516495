import React from 'react'
import { communitiesService } from '../../../_services'
import { Tooltip } from 'antd';
import {WarningOutlined} from '@ant-design/icons'
import {AntComment} from '../AntComment/AntComment'
import './Reply.css'

class Reply extends React.Component{
    constructor(props){
        super(props)

        this.state={
            replies : [],
            repliesLoaded : false,
            value :""
        }
    }

    componentDidMount(){
        if(this.props.showReplies){
            communitiesService.getRepliesByComment(this.props.commentId)
            .then(res => this.setState({
                replies : res.data,
                repliesLoaded : true
            }))
        }
   
    }

    onChange = ({ target: { value } }) => {
        this.setState({ value });
      };
    

    render(){
        return(
            <div className="reply">
                {this.props.notValidated?
                    <Tooltip title="your post will be visible to other users after validation">
                        < WarningOutlined  style={{color:'yellow',fontSize:'1.2rem'}}/>
                  </Tooltip>
                    :null}
                <AntComment refreshContributions={this.props.refreshContributions} body={this.props.body} author={this.props.author} type="Reply" contributionId={this.props.replyId} likes={this.props.likes}/>
            </div>
        )
    }
}

export default Reply