import React, { Component } from 'react'
import './QuizzPage.css'
import { Button, Radio } from 'antd';
import {connect } from 'react-redux'
import { communitiesService, userService } from '../../_services'
import {LoadingOutlined} from '@ant-design/icons'
import {redirectionActions, userActions} from '../../_actions'
import { CountdownTimer } from './CountdownTimer/CountdownTimer';
import { Redirect } from 'react-router-dom';

class QuizzPage extends Component{

    constructor(props){
        super(props)

        this.state = {
            quizz: null,
            value: null,
            selectedQuestionIndex:null,
            quizzCompleted:false,
            canRetakeQuizz: null,
            userScore: 0,
            totalScore:0,
            quizzSumitted:false,
            userCertificate: null, 
            timeToRetry : null,
            grade : null,
            startQuizz:false,
            authentication:null
          };
    }


    componentDidMount(){

        document.documentElement.scrollTop = 0

        this.props.updateUrl(`/quizzes/${this.props.match.params.id}`)
        this.setState({authentication:this.props.authentication})
     
        communitiesService.getQuizzById(this.props.match.params.id)
            .then(res1=>{
                const quiz= res1.data[0]
                
                communitiesService.getLastSubmissionCreationDate(this.props.authentication.user.id,res1.data[0].id)
                    .then(res2 => { 
                        if(res2.data.length === 0 )this.setState({canRetakeQuizz: true})
                        else{
                            if(Date.now()> (res2.data[res2.data.length-1].body.creationDate + quiz.body.cooldown*60000)) {
                              
                                this.setState({canRetakeQuizz:true})
                            }
                            else {
                                const timeToRetry  = Math.floor(((res2.data[res2.data.length-1].body.creationDate + quiz.body.cooldown*60000)-Date.now())/60000)
                             
                                this.setState({timeToRetry,canRetakeQuizz:false})
             
                            }
                        }
                       
                      
                    })
                    .catch(err=> console.error(err))

                this.setState({quizz : quiz,selectedQuestionIndex:0})
            })
            .catch(err=>console.log("error",err))


     
    }

    onChange = e => {
       
        this.setState({
          value: e.target.value,
        });
    };


    onAnswerClick=(value)=>{
        
        
        let correctAnswerIndex
        this.state.quizz.body.questions[this.state.selectedQuestionIndex].answers.map((answer,index) => {
            if(answer.isCorrect) correctAnswerIndex=index
        })
        
        const newUserScore = this.state.userScore + this.state.quizz.body.questions[this.state.selectedQuestionIndex].answers[value].addScore
        const totalScore =this.state.totalScore + this.state.quizz.body.questions[this.state.selectedQuestionIndex].answers[correctAnswerIndex].addScore  

        this.setState({
            value,
            userScore:newUserScore>0?newUserScore:0,
            totalScore:totalScore   
        })

        
        if(this.state.selectedQuestionIndex+1 === this.state.quizz.body.questions.length){
            if(this.state.userScore*100/this.state.totalScore>= 0 && this.state.userScore*100/this.state.totalScore <=59) this.setState({grade: 'F'})
                else if(this.state.userScore*100/this.state.totalScore>= 60 && this.state.userScore*100/this.state.totalScore <=69) this.setState({grade: 'D'})
                else if(this.state.userScore*100/this.state.totalScore>= 70 && this.state.userScore*100/this.state.totalScore <=79) this.setState({grade: 'C'})
                else if(this.state.userScore*100/this.state.totalScore>= 80 && this.state.userScore*100/this.state.totalScore <=89){
                    //generating certificate
                    const req = {
                        certificate:{
                            name:this.props.authentication.user.body.name,
                            surname : this.props.authentication.user.body.surname,
                            date :"17/11/2020",
                            experienceName:"Astronomy"
                        }
                       
                    }
                    this.setState({generatingCertificate : true})
                    communitiesService.generateCertificate(req)
                        .then(res=>{
                            this.setState({generatingCertificate : false,userCertificate:res.data.posted.url})

                            // adding certificate URL to user achie'vments 
                            const req2 = {
                                id : this.props.authentication.user.id,
                                token : this.props.authentication.token,
                                achievements : {
                                    title: "Certificate",
                                    URL : res.data.posted.url
                                }
                            }

                            userService.addAchievement(req2)
                                .then(res => console.log("added achievement"))
                                .catch(err => console.error(err))
                            
                            
                        })
                        .catch(err => console.error(err))
                    this.setState({grade: 'B'})
                
                }
                else if(this.state.userScore*100/this.state.totalScore>= 90 && this.state.userScore*100/this.state.totalScore <=100){
                    //generating certificate
                    const req = {
                        certificate:{
                            name:this.props.authentication.user.body.name,
                            surname : this.props.authentication.user.body.surname,
                            date :"17/11/2020",
                            experienceName:"Astronomy"
                        }
                       
                    }
                   
                    this.setState({generatingCertificate : true})
                    communitiesService.generateCertificate(req)
                        .then(res=>{
                        
                                this.setState({generatingCertificate : false,userCertificate:res.data.posted.url})

                                // adding certificate URL to user achie'vments 

                                const req2 = {
                                    id : this.props.authentication.user.id,
                                    token : this.props.authentication.token,
                                    achievements : {
                                        title: "Certificate",
                                        URL : res.data.posted.url
                                    }
                                }

                                userService.addAchievement(req2)
                                    .then(res => console.log("added achievement"))
                                    .catch(err => console.error(err))
                                
                           
                            })
                        .catch(err => console.error(err))

                    this.setState({grade: 'A'})
                    }
           
                this.setState({quizzCompleted:true})

        }else{
            setTimeout(()=>{
                
                    this.setState({selectedQuestionIndex: this.state.selectedQuestionIndex+1,
                        value: null,
                    movedToNextAnswer: true
                    })
                
            },1000)  
        }
    }

     handleQuestionTimeUp= ()=>{
       
         if(this.state.selectedQuestionIndex+1<this.state.quizz.body.questions.length){
            this.setState({
                selectedQuestionIndex:this.state.selectedQuestionIndex+1
            })
         }
     
    }


    handleStartQuizz=()=>{
        if(!this.props.authentication.loggedIn){
            this.props.showModal()
        }
        this.setState({
            startQuizz:true
        })
    }
    
    render(){

         if(this.state.quizzCompleted && !this.state.quizzSumitted){
            
            const req = {
                submission : {
                    quizId:this.state.quizz.id,
                    userId:this.props.authentication.user.id,
                    token:this.props.authentication.token,
                    score:this.state.userScore,
                    grade:this.state.grade
                }
            } 
            
             communitiesService.submitQuizz(req)
                 .then(res=>{
                
                    this.setState({quizzSumitted:true})})
                 .catch(err=>console.log("error#############",err))
         }

 
       
        const canTakeQuizz = this.state.quizz && this.state.canRetakeQuizz 
         
                return(
                    <div data-test="QuizzPage" className="quizz-page">
                        <div className="header">
                            <h2 data-test="quizz-title" className="quizz-title">{this.state.quizz?this.state.quizz.body.title:"Loading Quizz"}</h2>
                        </div>
                        <div className="body">

                            {this.state.quizz?
                               this.state.startQuizz ? 
                                    this.props.authentication.loggedIn ?
                                        canTakeQuizz ?
                                            !this.state.quizzCompleted && this.state.quizz.body.questions[this.state.selectedQuestionIndex]?
                                            <div data-test="question-card" className="question-card">
                                            <div className="question-info">
                                                <div className="question-card-header">
                                                    <p className="question-number" style={{fontSize:"0.8rem"}}>Question {this.state.selectedQuestionIndex +1} of {this.state.quizz.body.questions.length}</p>
                                                    <div style={{display:"flex"}}>
                                                        <CountdownTimer id="countdown-timer" time={this.state.quizz.body.questions[this.state.selectedQuestionIndex].timer} action={this.handleQuestionTimeUp}/>
                                                        <p style={{marginLeft:"5px"}}>seconds remaining</p>
                                                        </div>
                                                </div>

                                                <h2 className="question" style={{fontWeight:"bold"}}>{this.state.quizz.body.questions[this.state.selectedQuestionIndex].info.fr}</h2>
                                            </div>


                                            <Radio.Group onChange={this.onChange} value={this.state.value} className="answers-section">
                                                { this.state.quizz.body.questions[this.state.selectedQuestionIndex].answers.map((answer,index)=> {
                                                    return <div key={index} data-test="Answer" className="answer" onClick={()=>this.onAnswerClick(index)}> {answer.info.fr} </div>
                                                })}

                                            </Radio.Group>

                                            </div>
                                            :
                                            <div data-test="quizz-completed-card" className="quizz-completed-card">
                                                <h3>Quizz completed</h3>
                                                <p>{this.state.grade === 'A' || this.state.grade==='B'?"Congrats on passing the test, ":"Unfortunately you've failed the test, "}you've got Grade : <b>{this.state.grade}</b> with a score of <b>{this.state.userScore*100/this.state.totalScore}</b> %</p>
                                                  {this.state.grade === 'A' || this.state.grade ==='B'? this.state.generatingCertificate? <div data-test="generating-certificate"> Generating you certificate<LoadingOutlined style={{marginLeft:"0.5rem"}} spin={true}/>  </div>:<div> <a data-test="link-to-certificate" href={this.state.userCertificate}>Click here</a> to download your certificate</div>:null}
                                                {this.state.grade==='C'?<p>PS: {this.state.quizz.body.cAnswer}</p>:null}
                                                {this.state.grade==='D'?<p>PS: {this.state.quizz.body.dAnswer}</p>:null}
                                                {this.state.grade==='F'?<p>PS: {this.state.quizz.body.fAnswer}</p>:null}
                                                {this.state.grade==='B' ||this.state.grade==='C'||this.state.grade==='F'||this.state.grade==='D'?
                                                <p>You can try the quizz again in <CountdownTimer time={this.state.quizz.body.cooldown} mode="quizz-cooldown"/> </p>
                                                : null}
                                            </div>
                                            :
                                            <div data-test='retake-quizz' className="retake-quizz">
                                            <h3 style={{marginRight:"1rem"}}>You've already taken this quizz, you can retry after :</h3>{this.state.timeToRetry ?<CountdownTimer time={this.state.timeToRetry} mode="quizz-cooldown"/> :null} 
                                             </div>
                                        :
                                        <h1>Please Log in to proceed to quizz</h1>
                               :
                               <div className="question-card" style={{padding:"2%"}}>
                                   <h3>welcome to the {this.state.quizz.body.title}</h3>
                                   <p>This Quizz contains {this.state.quizz.body.questions.length} questions <br/> The time limit for this quizz is ## 25 ## minutes  </p>
                                   <Button type='primary' style={{width: 150}} onClick={this.handleStartQuizz}> Start Quizz </Button>
                               </div>
                            :
                            <div data-test="loading-card" className="question-card"><LoadingOutlined spin={true}/></div>
                            }

                          
                          
                           
    
                        </div>
                    </div>
                    
                )
            
      
           
       
            
       
        
    }
}

function mapStateToProps(state){
    const {authentication} = state
    return {
        authentication
    }
}
const actionCreators={
    showModal : userActions.showModal,
    updateUrl : redirectionActions.updateUrl
}

const connectedQuizzPage = connect(mapStateToProps,actionCreators)(QuizzPage)

export {connectedQuizzPage as QuizzPage}