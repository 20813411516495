import { experiencesConstants } from '../_constants';
import { experiencesService } from '../_services';
import { alertActions } from './';

/**
 * exporting an object with the defined functions dispatching the experiences actions as methods
 * 
 * @exports Action-creators__Experiences
 */
export const experiencesActions = {
    getAll
};

/**
 * this is the expereiecnes getAll fuction 
 * @returns array of experiences in database
 */
function getAll(category='',min=0,max=0,createdOn=0,keywords='') {
    return dispatch => {
        dispatch(request());

        experiencesService.getAll(category,min,max,createdOn,keywords)
            .then(res => {

                    const experiences = res.data

                    dispatch(success(experiences))
                    dispatch(alertActions.success('Got experiences successfull'));
                })
             .catch( error => {
                
                dispatch(failure(error))})  
            
    };

    function request() { return { type: experiencesConstants.GETALL_REQUEST } }
    function success(experiences) { return { type: experiencesConstants.GETALL_SUCCESS, experiences } }
    function failure(error) { return { type: experiencesConstants.GETALL_FAILURE, error } }
}