import React, { useState,useEffect } from 'react'

function CountdownTimer(props){
    const [timeRemaining,setTimeRemaining] = useState(props.time*60)
    // const [initialTime,setInitialTime] = useState(props.initialTime)
    // const [timer,setTimer]=useState(0)
    const [skipped,setSkipped] = useState(false)

    useEffect(()=>{
        setTimeout(()=>{
            setTimeRemaining(timeRemaining-1)
        },1000)
    })



    if(timeRemaining>0){
        return(
            <div>
                <h3>
                    
        { props.mode ==="quizz-cooldown"?<p> {Math.floor(timeRemaining/3600)} hours , {Math.floor((timeRemaining%3600)/60)} minutes and {timeRemaining%60} seconds</p>:timeRemaining}
                </h3>
            </div>
        )
    }else{
        if(props.mode !=='quizz-cooldown'){
            if(!skipped){
                props.action()
                setSkipped(true)
            }
        }
       
         return null
    }
    
}

export {CountdownTimer}