import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { experiences } from './experiences.reducer';
import { communities } from './communities.reducer';
import { shoppingCart } from './ShoppingCart.reducer'
import { order } from './order.reducer'
import { alert } from './alert.reducer';
import {redirection} from './redirection.reducer'
import { notifications} from './notifications.reducer'

const rootReducer = combineReducers({
  authentication,
  registration,
  experiences,
  communities,
  shoppingCart,
  order,
  alert,
  redirection,
  notifications
});

export default rootReducer;