import React from 'react';
import { useGoogleLogin } from 'react-google-login';
import {connect} from 'react-redux'
// refresh token
import {LoadingOutlined} from '@ant-design/icons';

import { refreshTokenSetup } from '../../utils/refreshToken';
import { userActions } from '../../_actions';
import { userService } from '../../_services';

const clientId =
  '1089855924360-1kjshmi1ov19pfm3p2r31ntm8s79gfp6.apps.googleusercontent.com';

function LoginHooks(props) {
  const onSuccess = (res) => {
    props.logInWithGoogle({
      token:res.tokenId
    })
    
    refreshTokenSetup(res);
  };

  const onFailure = (res) => {
    console.log('Login failed: res:', res);
  
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: false,
    accessType: 'offline',
    // responseType: 'code',
    // prompt: 'consent',
  });

  return (
    <div className="google" onClick={signIn}>
      <img src={require('../../assets/icons/google.svg')} height="25px" style={{marginRight:"1.5%"}}></img>
      Se connecter avec Google
      {props.authentication.loggingInWithGoogle && <LoadingOutlined/>}
      </div>
      
  );
}

const mapState = (state) => {
  const {authentication} =state
  return{
    authentication
  }
}

const actionCreators = {
  logInWithGoogle :userActions.logInWithGoogle
}

const connectedLoginHooks = connect(mapState,actionCreators)(LoginHooks)

export{connectedLoginHooks as LoginHooks};
