import { experiencesConstants } from '../_constants';

const initialState= {items : [],loaded : false,error : null}
/**
 * Exporting a function that handles all the actions related to experiences
 * 
 * @exports Reducers__Experiences
 */
export function experiences(state = initialState, action) {
  switch (action.type) {
    case experiencesConstants.GETALL_REQUEST:
      return {
        ...state
      };
    case experiencesConstants.GETALL_SUCCESS:
      return {
        ...state,
        loaded:true,
        items: action.experiences
      };
    case experiencesConstants.GETALL_FAILURE:
      return { ...state,
        error: action.error
      };
    case experiencesConstants.UPDATE_EXPERIENCE_LIKES : 
    const newItems = state.items.map(experience =>{
      if(experience.id === action.experience.id){
        return {
          id : experience.id,
          body : {...experience.body,
          likes : action.experience.likes}
        }
      }else{
        return experience
      }
    })
    return{
      ...state,
      items:newItems
    }
      /*
    case experiencesConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, deleting: true }
            : user
        )
      };
    case experiencesConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter(user => user.id !== action.id)
      };
    case experiencesConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      }
      */
    default:
      return state
  }
}