import React, { useState,useEffect} from 'react'
import './NewNavBar.css'
import 'antd/dist/antd.css';
import {BellOutlined,BellFilled,SettingOutlined,LockOutlined,LeftOutlined } from '@ant-design/icons';
import {Button,Menu,Dropdown,AutoComplete} from 'antd'
import {userActions} from '../../_actions'
import {history} from '../../_helpers'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {messaging } from '../../firebase'

const NewNavBar = (props) =>{
    const [dataSource,setDatasource] = useState([])
    const [searchValue,setSearchValue] = useState('')
    const [notifications,setNotifications] = useState([])
    const [showNotificationCounter,setShowNotificationCounter] = useState(false)
    const [loginError,setLoginError] = useState(false)
    const [loggedOut,setLoggedOut] = useState(false)
    const [showParams,setShowParams] = useState(false)
    const [experienceNotification,setExperienceNotification] = useState(false)

    useEffect(()=>{
        setDatasource([])
        setSearchValue('')

    },[])

    const onSelect=(value)=> {
        /*
        const selectedExperience= this.state.experiences.filter(experience => experience.body.title === value)
  
        history.push(`/product/${selectedExperience[0].id}`)
        const newsearchOptions = this.state.searchOptions
        this.setState({value : '',
        dataSource : newsearchOptions})
        */
      }

    const handleSearch=(value)=>{
        /*
        const newdataSource = this.state.searchOptions.filter(option => option.toUpperCase().includes(value.toUpperCase()))
        this.setState({
          dataSource: !value ? this.state.searchOptions : newdataSource,
          value
        });
        */
      }

    const handlNotificationDropdownClick=()=>{
        setShowNotificationCounter(true)
      }    
  
    const  onVoirProfile=()=>{
      // window.location= `http://localhost:3000/#/newuser/${props.authentication.user.id}`
         history.push(`/newuser/${props.authentication.user.id}`)
      }

    const onParametres=()=>{
        if(showParams)setShowParams(false)
        else setShowParams(true)
        
    }

    const handleLogOut = () =>{
        props.logout()
        setLoginError(false)
        setLoggedOut(true)
       
    }

    const showModal= () =>{
        props.showModal()
    }

    const onButtonClick=(text)=>{
      // window.location=`http://localhost:3000/#/newuser/${props.authentication.user.id}/settings/${text}`
         history.push(`/newuser/${props.authentication.user.id}/settings/${text}`)
    }

    const handleExperiencePageClick=()=>{
      if(experienceNotification){
        setExperienceNotification(false)
      }
    }


    messaging.onMessage((payload) => {
   
      if(payload.data.tag==='EXPERIENCES') this.setState({experienceNotification : true})
      if(payload.data.tag==='GENERAL') this.setState({alertNotification : true})
      if(this.props.loggedIn && (this.props.user.body.subscriptions.includes(payload.data.tag) || (payload.data.tag ==='VALIDATION' && payload.data.auth === this.props.user.id) ) )
      { 
        
          if(( payload.data.tag.includes('COMMUNITY') ||payload.data.tag.includes('POST') || payload.data.tag.includes('COMMENT')) && payload.data.auth !== this.props.user.id)
          {const newNotifications = notifications
          newNotifications.push({
            content:payload.data.body,
            communityName : payload.data.communityName,
            postId : payload.data.id
          })
          setNotifications(newNotifications)
          setShowNotificationCounter(true)
         
            
            document.getElementById("notification_bell").classList.add("wiggle")
            setTimeout(()=>{
              document.getElementById("notification_bell").classList.remove("wiggle")
              
            },1000)}

            if(!payload.data.tag.includes('COMMUNITY')&& !payload.data.tag.includes('POST')&&!payload.data.tag.includes('COMMENT')){
              const newNotifications = notifications
              newNotifications.push({
                content:payload.data.body,
                communityName : payload.data.communityName,
                postId : payload.data.id
              })
              setNotifications(newNotifications)
              setShowNotificationCounter(true)
         
                
                document.getElementById("notification_bell").classList.add("wiggle")
                setTimeout(()=>{
                  document.getElementById("notification_bell").classList.remove("wiggle")
                  
                },1000)
            }
          
        }
     
      

    });



    const notificationsMenu = (
        <Menu style={{display:"flex",flexDirection:'column',width:"20vw",padding:"5%"}}>
            {notifications.map(notification =>{
               if(notification.content.includes('Post') || notification.content.includes('Comment')|| notification.content.includes('Reply')){
                 return <Link to={`/commuinities/${notification.communityName}/post/${notification.postId}`} className="notification">{notification.content}</Link>
                }else{
                return <div className="notification">{notification.content}</div>
               }
               
               })}
    </Menu>)

    const settingsMenu = (
        <Menu style={{display:"flex",flexDirection:'column',width:"20vw",padding:"5%",marginTop:'5%'}}> 
          <div className="settings_menu_item"><Button className="settings_menu_item_button" type="text" onClick={onVoirProfile}> <SettingOutlined />Voir Profile</Button></div>
          <div className="settings_menu_item"><Button className="settings_menu_item_button" type="text"onClick={onParametres}> <LockOutlined />Parametres et confidentialité</Button></div>
          <Button type="danger" style={{marginTop:"2%"}} onClick={handleLogOut}>sign out </Button>
        </Menu>
      )

    const parametres = (
        <Menu style={{display:"flex",flexDirection:'row',width:"20vw",marginTop:'5%'}}> 
          <Button shape='circle' type='ghost' style={{border:'none'}} onClick={onParametres}><LeftOutlined /></Button>
          <div style={{display:"flex",flexDirection:"column"}}>
            <div className="settings_menu_item"><Button className="settings_menu_item_button" type="text" onClick={()=>onButtonClick('Parametres de compte')}> <SettingOutlined />Parametres de compte</Button></div>
            <div className="settings_menu_item"><Button className="settings_menu_item_button" type="text"onClick={()=>onButtonClick('Securite de compte')}> <LockOutlined />Securite de compte</Button></div>
            <div className="settings_menu_item"><Button className="settings_menu_item_button" type="text"onClick={()=>onButtonClick('Parametres de notifictaions')}> <LockOutlined />Parametres de notifictaions</Button></div>
          </div>
        </Menu>
    )

    return(
        <div className="new-navbar">
            <div className="logo-section"></div>
            <div className="navigation-section">
                <ul className="navigation-links">
                    <li className="navigation-link"><Link to="/products" ><Button  type="link" onClick={handleExperiencePageClick} className="navbar__link__button" size="medium">Experiences</Button></Link></li>
                    <li className="navigation-link"><Link to="/communities/all"><Button  type="link" className="navbar__link__button" size="medium">Communities</Button></Link></li>
                    <li className="navigation-link"><Button  type="link" className="navbar__link__button" size="medium">About us</Button></li>
                    <li className="navigation-link">
                    <AutoComplete
                           dataSource={dataSource}
                           value={searchValue}
                           onSelect={onSelect}
                           onSearch={handleSearch}
                           placeholder="Search for experiences"
                    />
                    </li>
                    {props.authentication.loggedIn ?
                    <li className="navigation-link user-logged-in">
                        <Dropdown 
                              
                              overlay={notificationsMenu}
                              trigger={['click']}  
                              /*onClick={this.handlNotificationDropdownClick}*/
                        
                            >
                          
                                  {notifications.length> 0 ?
                                  <div>
                                    <BellFilled  id="notification_bell" className="notification-bell" />
                                  {showNotificationCounter ?<div id="notification_counter" className="notification-counter ">{notifications.length}</div>:null}
                                  </div> 
                                  : <BellOutlined  className="notification-bell"/>}
                                  
                                 
                              
                               
                        </Dropdown>
                        <Dropdown
                              overlay={showParams? parametres :settingsMenu}
                              trigger={['click']}      
                            >
                            <Link to={`/newuser/${props.authentication.user.id}`}><img src={props.authentication.user.body.image} alt="" className="user-avatar"></img></Link>
                              
                        </Dropdown>
                    </li>
                    :
                    <li className="navigation-link user-logged-out">
                        <Button type='text' className="navbar__link__button" size="small"  onClick={showModal}>Sign In</Button>
                        <Link to="/register"><Button  type="danger" className="signup__button" style={{ background:"rgba(123,208,233,0.7)", border: "white", color:"white"  }} size="medium">sign Up</Button></Link>
                    </li>
                    }
                </ul>
                {
                // props.authentication.loggedIn?
                //     <ul className="user-logged-in">
                //         <li> 
                //             <Dropdown 
                //               overlay={notificationsMenu}
                //               trigger={['click']}  
                //               /*onClick={this.handlNotificationDropdownClick}*/
                        
                //             >
                //               <div className="notification-section">
                //                   {notifications.length> 0 ?
                //                   <div>
                //                     <BellFilled id="notification_bell" className="notification-bell" />
                //                   {showNotificationCounter ?<div id="notification_counter" className="notification-counter ">{notifications.length}</div>:null}
                //                   </div> 
                //                   : <BellOutlined  className="notification-bell"/>}
                                  
                                 
                //               </div>
                              
                //             </Dropdown>
                //         </li>
                //         <li>
                //             <Dropdown
                //               overlay={showParams? parametres :settingsMenu}
                //               trigger={['click']}      
                //             >
                //             <Link to={`/newuser/${props.authentication.user.id}`}><img src={props.authentication.user.body.image} alt="" className="user-avatar"></img></Link>
                              
                //             </Dropdown>
                //         </li>
                //     </ul>
                // :    
                //     <ul className="user-logged-out">
                //         <li><Button type='text' className="navbar__link__button" size="small"  onClick={showModal}>Sign In</Button></li>
                //         <li><Link to="/register"><Button  type="danger" className="signup__button" style={{ background:"rgba(123,208,233,0.7)", border: "white", color:"white"  }} size="medium">sign Up</Button></Link> </li>
                //     </ul>
                }
            </div>
        </div>
    )
}

const mapState=(state)=>{
    const {authentication} = state
    return {
        authentication
    }
}

const actionCreators={
    login: userActions.login,
    logout : userActions.logout,
    showModal : userActions.showModal,
    hideModal : userActions.hideModal,
}

const connectedNewNavBar = connect(mapState,actionCreators)(NewNavBar)

export {connectedNewNavBar as NewNavBar}