import React, { useState, useEffect } from 'react'
import './InteractiveCard.css'
import {connect} from 'react-redux'
import {history } from '../../_helpers'

const InteractiveCard  = ( props ) => {

    const handleRedirect =( )=> {
           if(props.card.button.text==='Tester')window.location = 'https://play.google.com/store/apps/details?id=com.AlienDimension.AlienDimension'
           else{   
            const foundExperience = props.experiences.items.find(experience => experience.body.title.fr.includes(props.card.button.experience))
            // window.location = `http://localhost:3000/#/product/${foundExperience.id}/overview`
             history.push(`/product/${foundExperience.id}/overview`)
        }
    }
    return(

            <div className="interactive-card">
                <div >
                {props.card.imageIndex === 1 ?
                    <img className="interactive-card__image" src={require('../../assets/images/first-card.png')} alr="card-image"/>
                :props.card.imageIndex === 2?
                    <img className="interactive-card__image" src={require('../../assets/images/second-card.png')} alr="card-image"/>            
                :props.card.imageIndex === 3?
                    <img className="interactive-card__image" src={require('../../assets/images/third-card.png')} alr="card-image"/>            
                :
                    <img className="interactive-card__image" src={require('../../assets/images/fourth-card.png')} alr="card-image"/>                
                }
                </div>
                <div className="main-content">
                    {props.card.content.map(item => <div className="interactive-card__content__item">
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                    </div>)} 
                   
                </div>
                <button className="interactive-card__cta" onClick={handleRedirect}>{props.card.button.text}</button>
            </div>
     
    )
}

const mapState = ( state ) => {
    const {experiences} = state
    return{
        experiences
    }
    
}


const connectedInteractiveCard = connect(mapState)(InteractiveCard)
export { connectedInteractiveCard as  InteractiveCard }