import React, { useEffect, useState } from 'react'
import './OrderConfirmation.css'
import {connect} from 'react-redux'
import {history} from '../../_helpers'
import {Link} from 'react-router-dom'
import { orderActions, shoppingCartActions, userActions } from '../../_actions'

const OrderConfirmation= (props) =>{


    useEffect(()=>{

            document.documentElement.scrollTop = 0    

        
            props.clearShoppingCart()
        
            
    },[])

    return(
        <div className="payment-success-page">
               
                <div className="first-section">
                     <div className="background-image">
                         
                     </div>
                </div>
                
                <div className="second-section">
                <h1>Commande Envoyée</h1>
                
                <p>Chèr(e) <b>{props.user.body && props.user.body.name +" "+ props.user.body.surname}</b>, merci pour votre confiance.

                   Votre commande de : <br/>
                    <ul>
                        {props.order.content.orderItems && props.order.content.orderItems.map(item=> <li><b>{item.boughtExperienceName}</b></li>)}
                    </ul>
                   Le montant total à payer est : <b>{props.order.content.totalToPay&&props.order.content.totalToPay+0.6 } TND ( frais de paquet + frais de livraison + timbre fiscale )</b>.<br/>
                   Un membre de l'équipe Alien Dimension vous appellerai pour confirmer la commande.<br/>
                   Nous restons toujours à votre disposition.<br/>
                   <Link to={`/generate-bill/${props.order.content.id}`}><b>Facture</b></Link>.
                   </p>
                
            </div>
                
        </div>
    )
}


const mapState=(state)=>{
    const {user} = state.authentication
    const {order} = state
    const {authentication} = state
    const {shoppingCart} = state
    return{
        user,
        order,
        authentication,
        shoppingCart
    }
}

const actionCreators = {
    clearShoppingCart : shoppingCartActions.clearShoppingCart,
    clearOrder : orderActions.clearOrder,
    updateBoughtExperiences: userActions.updateBoughtExperiences,
    setBillingShoppingCart : shoppingCartActions.setBillingShoppingCart
}


const connectedOrderConfirmation = connect(mapState,actionCreators)(OrderConfirmation)
export {connectedOrderConfirmation as OrderConfirmation}