import React from 'react'
import './NewCommunityPage.css'
import {connect } from 'react-redux'
import {Post} from '../Shared/Post'
import {Button,Input,Select,AutoComplete} from 'antd'
import {BellFilled, BellOutlined} from '@ant-design/icons'
import { communitiesActions, redirectionActions, userActions} from '../../_actions'
import { communitiesService } from '../../_services'
import {messaging} from '../../firebase'
import {Link } from 'react-router-dom'

class NewCommunityPage extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            communities : [],
            selectedCommunity : {},
            renderedPosts: [],
            hiddePosts:[],
            bodyValue:'',
            postType:'',
            lastPostCreatedOn : null,
            subjectValue:'',
            morePosts: false,
            loadingMorePosts: false,
            selectedCategory : '',
            searchValue:''
        }
    }

    componentDidMount(){
        this.props.updateUrl(`/communities/all`)
        document.documentElement.scrollTop = 0
        window.addEventListener('scroll', this.trackScrolling)
        if(this.props.communities.loaded){
            this.setState({communities:this.props.communities.items})
        }else{
           this.props.getAll()
            setTimeout(()=>{
                this.setState({communities : this.props.communities.items})

                if(this.props.match.params.id ){
                this.props.communities.items.forEach(community => {
                        if (community.id === this.props.match.params.id) return this.showCommunityContent(community.body.name)
                    })  
                
                }
            },1000)
        }
       
        
    }

    trackScrolling = () => {
    
       
        
        if( window.innerHeight + document.documentElement.scrollTop
            === document.documentElement.scrollHeight){
                if(this.state.morePosts){
                    const newRenderedPosts= this.state.renderedPosts.concat(this.state.hiddenPosts)
                    this.setState({loadingMorePosts: true,
                    renderedPosts : newRenderedPosts})
           
                 
                    communitiesService.getPostsByCommunity(this.state.selectedCommunity.id,this.state.lastPostCreatedOn,this.state.searchValue,this.state.selectedCategory,this.state.lastPostLikes) 
                    .then(res => {
                      
                        if(res.data.length>0){
                            this.setState({
                                hiddenPosts:res.data,
                                morePosts: true,
                                loadingMorePosts: false,
                                lastPostCreatedOn : res.data[res.data.length-1].body.createdOn,
                                lastPostLikes: res.data[res.data.length-1].body.likes
                            })
                        }else{
                            this.setState({morePosts:false})
                        }
                        
                    })
                }
                
            }
      };

    submitPost= async ()=>{
        
        const permission = await this.notificationPermission()

        const req={
            post : {
                subject:this.state.subjectValue,
                body:this.state.bodyValue,
                communityId:this.state.selectedCommunity.id,
                communityName : this.state.selectedCommunity.body.name,
                auth : {
                    id:this.props.user.id,
                    name: this.props.user.body.name + " " + this.props.user.body.surname,
                    image: 'whatever',
                    subscriptionToken : localStorage.getItem('INSTANCE_TOKEN')
                },
                type: this.state.postType
            }
            
        }

        communitiesService.submitPost(req)
            .then(res=>{
                const newPost = {
                    id: res.data.posted.id,
                    body : {
                        subject:this.state.subjectValue,
                    body:this.state.bodyValue,
                    communityId:this.state.selectedCommunity.id,
                    auth : {
                        id:this.props.user.id,
                        name: this.props.user.body.name + " " + this.props.user.body.surname,
                        image: 'whatever'

                    },
                    type: this.state.postType,
                    notValidated : true
                    }
                }
         
                const newPosts= this.state.renderedPosts
                newPosts.unshift(newPost)

                const subscriptions = this.props.user.body.subscriptions
                 subscriptions.push(`POST${res.data.posted.id}`)
                 const subscriptionreq = {
                        user :{
                            id: this.props.user.id,
                            token : this.props.token,
                            subscriptions
                        },
                        subscription:{
                            token : localStorage.getItem('INSTANCE_TOKEN'),
                            topic : `POST${res.data.posted.id}`
                        }
                }

                if(permission){
                    this.props.updateSubscriptions(subscriptionreq,'subscribe')
                }

                this.setState({subjectValue :"",
                            bodyValue:'',
                            posts : newPosts})
            })
    }

    onBodyChange = ({ target: { value } }) => {
        this.setState({ bodyValue :value });
      };

    onSubjectChange = ({ target: { value } }) => {
        this.setState({ subjectValue :value });
    };
    
    handleChange=(value)=> {
        this.setState({postType:value})
      }


    //SUBSCRIPTION TO NOTIFICATIONS HANDLE
    notificationPermission = async ()=> {
        let permissionGranted = false;
        try {
  
            if (Notification.permission !== 'granted') {
                await messaging.requestPermission();
            }
       
            if (localStorage.getItem("INSTANCE_TOKEN")) {
                permissionGranted = true;
            } else {
                const token = await messaging.getToken(); // returns the same token on every invocation until refreshed by browser
              
                localStorage.setItem("INSTANCE_TOKEN", token);
                permissionGranted = true;
            }
        } catch (err) {
            console.error(err);
        } finally {
            return permissionGranted;
        }
      }

      
    handleUserSubscriptionToCommunity = async ( topic ) => {
        /*
        const newSubscriptions = []
       
        if(this.props.authentication.user.body.subscriptions.length >1){
             newSubscriptions = this.props.authentication.user.body.subscriptions.filter(subTopic =>  subTopic !== topic)
    
        }
        
           id :this.props.authentication.user.id ,
            subscriptions : newSubscriptions,
            token : this.props.authentication.token
            }
        this.props.updateSubscriptions(req)
        */
        const permission = await this.notificationPermission()
        if(this.props.loggedIn && permission){

            const subscriptions = this.props.user.body.subscriptions
            subscriptions.push(topic)
            const req = {
            user : {
                id: this.props.user.id,
                token : this.props.token,
              subscriptions
            },
            subscription : {
                token : localStorage.getItem('INSTANCE_TOKEN'),
                topic : topic
            }
          }
   
          this.props.updateSubscriptions(req,'subscribe')
           
        }

      
      
    }
    
    handleUserUnsubscriptionToCommunity = async (topic) => {
   
        /*
      const newSubscriptions = this.props.authentication.user.body.subscriptions
      newSubscriptions.push(topic)

      const req= {
          user :{
              id :this.props.authentication.user.id ,
              subscriptions : newSubscriptions,
              token : this.props.authentication.token
          },
          subscription : {
              topic
          }
              
      }
      this.props.updateSubscriptions(req)
       */
      
      
      if(this.props.loggedIn && this.props.user.body.subscriptions.includes(topic)){
       
        const subscriptions = this.props.user.body.subscriptions.filter(sub => sub !== topic)
        
        const req = {
            user: {
                id: this.props.user.id,
                token : this.props.token,
                subscriptions
            },
            subscription : {
                token : localStorage.getItem('INSTANCE_TOKEN'),
                topic : topic
            }
        }

        this.props.updateSubscriptions(req,'unsubscribe') 
            

      }
       
    }

    // SUBSCRIPTION TO NOTIFICATIONS HANDLE

    handleKeywordsSearch =(value)=>{
        this.setState({searchValue : value})


    }

    handleSelectChange=(value)=>{

        this.setState({selectedCategory : value})

             communitiesService.getPostsByCommunity(this.state.selectedCommunity.id,0,'',value)
                  .then(res =>{
                      if(res.data.length >0){
              
                          this.setState({renderedPosts : res.data})

                          communitiesService.getPostsByCommunity(this.state.selectedCommunity.id,res.data[res.data.length-1].body.createdOn,this.state.searchValue,value)
                              .then(res => { 
                                  if(res.data.length>0){
                                  this.setState({hiddenPosts : res.data,

                                  lastPostCreatedOn : res.data[res.data.length-1].body.createdOn})
                              }else{
                                  this.setState({hiddenPosts:[]})
                              }
                                  
                              })
                          
                      }else{
                          this.setState({renderedPosts:[]})
                      }

                      if(res.data.length ===6){this.setState({morePosts:true})}
                      else{this.setState({morePosts:false})}
                      })
              
          

    }


    refreshContributions=()=>{
        this.showCommunityContent(this.state.selectedCommunity.body.name)
    }

    joinCommunity=(id,name)=>{
       
        const found = this.props.user.body.communities.find(community => community.id === id);

        if(!found)
        {
        const newCommunities = this.props.user.body.communities;
        newCommunities.push({id,name })
        const req = {
            id: this.props.user.id,
            communities :newCommunities,
            token : this.props.token
        }

        this.props.updateCommunities(req)
        setTimeout(()=>{
            this.handleUserSubscriptionToCommunity(`COMMUNITY${this.state.selectedCommunity.id}`)
        },1111)
        }
    }

    handleSearch= (value)=>{
        communitiesService.getPostsByCommunity(this.state.selectedCommunity.id,0,value,this.state.selectedCategory)
                .then(res =>{

                    if(res.data.length >0){
                   
                        this.setState({renderedPosts : res.data
                        })
                   
                        communitiesService.getPostsByCommunity(this.state.selectedCommunity.id,res.data[res.data.length-1].body.createdOn,value,this.state.selectedCategory)
                            .then(res => {
                                if(res.data.length>0){
                                    this.setState({hiddenPosts : res.data,
                                    lastPostCreatedOn : res.data[res.data.length-1].body.createdOn})
                                }else{
                                    this.setState({hiddenPosts:[]})
                                }
                            })

                    }else{
                        this.setState({renderedPosts:[]})
                    }

                    if(this.state.hiddenPosts.length >0){this.setState({morePosts:true})}
                    else{this.setState({morePosts:false})}
                    })
    }

   

    showCommunityContent=(name) => {
   
        this.state.communities.map(community => {
            if(community.body.name === name ) { document.getElementById(community.body.name).style.display = "flex"}
           else document.getElementById(community.body.name).style.display= 'none'
        })
        
        const selectedCommunity = this.state.communities.filter( community => community.body.name === name)
 
        communitiesService.getPostsByCommunity(selectedCommunity[0].id)
            .then(res => {
                this.setState({
                selectedCommunity : selectedCommunity[0],
                renderedPosts : res.data,
                lastPostCreatedOn: res.data[res.data.length-1].body.createdOn,
                lastPostLikes :res.data[res.data.length-1].body.likes
            })
        
            if(res.data.length >0){
          
                communitiesService.getPostsByCommunity(selectedCommunity[0].id,res.data[res.data.length-1].body.createdOn,'','',res.data[res.data.length-1].body.likes)
                .then(res2 => {
               
                    if(res2.data.length>0){
                        
                        this.setState({
                            hiddenPosts : res2.data,
                            lastPostCreatedOn : res2.data[res2.data.length-1].body.createdOn,
                            lastPostLikes :res.data[res.data.length-1].body.likes,
                            morePosts : true
                        })
                    }else{
                        this.setState({
                            hiddenPosts : res2.data,
                     
                            morePosts : false
                        })
                    }
                    
                }
                )
                .catch(err => console.log("couldn't load hidden posts "))
            }else this.setState({morePosts:false})
            

        })
            .catch(err => this.setState({
                selectedCommunity : selectedCommunity[0]
            }))


          
    }

    render(){


        const { Search } = Input;
        const { TextArea } = Input;
        const { Option } = Select;
        const subscribedToThisCommunity = this.props.loggedIn && this.props.user.body.subscriptions.includes(`COMMUNITY${this.state.selectedCommunity.id}`)


        return(
            <div data-test="communityPage" className="community-page">
                <div className="community-page__side-bar">
                    <div className="community">
                        <h3 className="Community__name">Science communities</h3>
                        <div className="subcommunities-section">
                            <div className="subcommunity">
                                {this.state.communities.map(community =><Button data-test='showCommunityButon' type='text' onClick={() => this.showCommunityContent(community.body.name)} className="subcommunity__name">
                                    {community.body.name}
                                </Button >)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="community-page__main-section">
                
            {this.state.communities.map(community => 
                <div id={community.body.name} className="communitySection">
                    
                    <div style={{display:'flex',margin:"3% 0% 5% 5%",alignSelf:"flex-start",alignContent:'center'}}>
                        <div style={{display:"flex",flexDirection:"column"}}>
                            <h3 className="communitySection__title">{community.body.name}</h3>
                            <h3 className="community__">Community</h3>
                        </div>
                     
                        {   
                            this.props.loggedIn ?
                            (this.props.user.body.communities.filter(community => community.id === this.state.selectedCommunity.id)).length===0?
                            <Button data-test="joinCommnityButton" className="joinCommnityButton" onClick={()=>this.joinCommunity(this.state.selectedCommunity.id,this.state.selectedCommunity.body.name)}>Join</Button>:
                                subscribedToThisCommunity ?
                                <div ><Button data-test="unsubscribeFromCommunityButton" type="primary" ghost shape="circle" style={{ fontSize:"1.2rem",color:"#fbb72c", border :"none"}} onClick={()=>this.handleUserUnsubscriptionToCommunity(`COMMUNITY${this.state.selectedCommunity.id}`)} icon={<BellFilled />}/></div>
                                :
                                <div ><Button type="subscribeToCommunityButton" ghost shape="circle" style={{fontSize:"1.2rem", color:"#fbb72c", border :"none"}} onClick={()=>this.handleUserSubscriptionToCommunity(`COMMUNITY${this.state.selectedCommunity.id}`)} icon={<BellOutlined />}/></div>
                            
                            :null 
                        }

                    </div>
                    {this.props.loggedIn ? 
                            <div className="write-post-section">
                            <div className="post-card">
                                <div className="post-card__header">
                                    <Link to={`/newuser/${this.props.user.id}`}><div className="user-image"></div></Link>
                                </div>
                                <div className="post-card-content">
                                   

                                     <div className="post-card-text">
                                         <Input value={this.state.subjectValue} placeholder="Post Subject" onChange={this.onSubjectChange}></Input>
                                         <TextArea
                                         value={this.state.bodyValue}
                                         onChange={this.onBodyChange}
                                         placeholder="What's on your mind"
                                         autoSize={{ minRows: 3, maxRows: 3 }}
                                         />
                                         <div style={{ textAlign:"right"}}>
                                             <Select  placeholder="Post Type" style={{ width: 115 }} className="select-type" onChange={this.handleChange}>
                                             <Option value="Review">Review</Option>
                                             <Option value="Question">Question</Option>
                                             <Option value="News">News</Option>
                                             </Select>
                                             <Button className="post-button" style={{borderRadius:"25px"}} type="primary" onClick={this.submitPost}>Post</Button>

                                         </div> 
                                     </div>
                                </div>
                            </div>
                        </div>
                        : null}
                         <div style={{display :'flex',alignItems :'center',width:'100%'}} className="selectors" >
                            <Select value={this.state.selectedCategory} className="select" style={{ width: 120 }}onChange={this.handleSelectChange}>
                              <Option value=''>All</Option>
                              <Option value="Review">Reviews</Option>
                              <Option value="Question">Questions</Option>
                              <Option value="News">News</Option>
                            </Select>
                            <Search
                              placeholder="input search text"
                              onSearch={value => this.handleSearch(value)}
                            
                            />
                             
                         </div>
                    {this.state.renderedPosts.length>0 ?
                                this.state.renderedPosts.map(post => {
                                    
                                    return <Post 
                                    refreshContributions={this.refreshContributions} 
                                    communityName={this.state.selectedCommunity.body.name} 
                                    style={{width :"80%"}} 
                                    postId={post.id} postType={post.body.type} 
                                    subject={post.body.subject} body={post.body.body} 
                                    author={post.body.auth} notValidated={post.body.notValidated} 
                                    likes={post.body.likes}
                                    commentsCount={post.body.commentsCount}
                                    />
                                })
                    : <div className="no-post"><h3>There are no posts available in this community</h3></div>}
                </div>
            )}
                </div>
            </div>
        )
    }
}


function mapState(state){
    const {communities,authentication} = state
    const {loggedIn,user,token} = authentication
    return {
        communities,
        loggedIn,
        user,
        token
    }
}

const actionCreators = {
    updateSubscriptions : userActions.updateSubscriptions,
    getAll : communitiesActions.getAll,
    updateCommunities: userActions.updateCommunities,
    updateUrl : redirectionActions.updateUrl
  };

const connectedNewCommunityPage = connect(mapState,actionCreators)(NewCommunityPage)

export {connectedNewCommunityPage as NewCommunityPage} 