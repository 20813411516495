import { redirectionConstants } from '../_constants';

const initialState= {url :"/"}

export function redirection(state = initialState, action) {
  switch (action.type) {
    case redirectionConstants.UPDATE_REDIRECTION_URL:
       return {...state,
        url : action.url
    }    
    default:
      return state
  }
}