import { userConstants } from '../_constants';

/**
 * defining the initial state:
 *    - loggedIn : signals if the user is loggedIn,
 *    - user : object containing the user's info,
 *    - modalVisible : bollean indicating whether the login modal is visible
 *    - editModalVisible : boolean indicating whether the user edit modal is visible
 */
const initialState ={ loggedIn: false,refreshToken:'',token :'',registering : false, logInError: null, logginInWithFacebook : false,logginInWithGoogle:false,registerSuccess : null , registerError : null, user :{},modalVisible: false,editModalVisible:false, updating : false,updated : false, error: null } 

/**
 * exporting a function that handles every action related to user authentication and status
 *  
 * @param {object} state this is the authentication attribute content in redux store1
 * @param {object} action this is the action dispatched from the reducer
 * @exports Reducers__Authentication
 */
export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        logInError :false
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn : false,
        loggedIn: true, 
        logInError : false,
        user: action.user,
        modalVisible:false,
        token:action.user.token,
        error : null,
        refreshToken : action.user.refreshToken
      };
    case userConstants.LOGIN_FAILURE:
      return {...state,
      logInError : action.error,
      loggingIn: false
    };
    case userConstants.REGISTER_REQUEST:
      return{
        ...state,
        registering : true,
        registerError : false
      }  
      case userConstants.REGISTER_SUCCESS:
        return{
          ...state,
          registerError : null,
          registerSuccess : true,
          registering : false,
        } 
    case userConstants.REGISTER_FAILURE:
      return{
        ...state,
        registerError : action.error,
        registering : false,
      }  
 
    case userConstants.LOGOUT:
      return {
        ...state,
        user: {},
        loggedIn : false};
    case userConstants.UPDATE_FAVORITE_REQUEST:
      return{
        ...state,
        updating:true
      }
    case userConstants.UPDATE_FAVORITE_SUCCESS:
      return {
        ...state,
        user : action.user,
        updating:false
      }
    case userConstants.UPDATE_FAVORITE_FAILURE:
      return {
        ...state,
        updating:false
      }
    case userConstants.UPDATE_REQUEST:
      return {
        ...state,
        updating : true
      }
    case userConstants.UPDATE_SUCCESS:

      return{
        ...state,
        user : action.user,
        updating :false,
        updated : true,
        editModalVisible: false
      }
      case userConstants.UPDATE_FAILURE:
        return{
          ...state,
          updating :false,
          updated: false
        }
    case userConstants.SHOW_MODAL :
      return {
        ...state,
        modalVisible : true
      } 
    case userConstants.SHOW_EDIT_MODAL:
      return {
        ...state,
        editModalVisible :true
      }
    case userConstants.HIDE_EDIT_MODAL:
      return {
        ...state,
        editModalVisible:false
      }
    case userConstants.HIDE_MODAL:
      return {
        ...state,
        modalVisible : false,
        logInError : null
      }
    case userConstants.UPDATE_COMMUNITIES_REQUEST:
        return{
          ...state,
          updating:true
        }
    case userConstants.UPDATE_COMMUNITIES_SUCCESS:
        return {
          ...state,
          user : action.user,
          updating:false
        }
    case userConstants.UPDATE_COMMUNITIES_FAILURE:
        return {
          ...state,
          updating :false
        }
    case userConstants.UPDATE_SUBSCRIPTIONS_REQUEST:
          return{
            ...state,
            updating:true
          }
    case userConstants.UPDATE_SUBSCRIPTIONS_SUCCESS:
          return {
            ...state,
            user : action.user,
            updating:false
          }
    case userConstants.UPDATE_SUBSCRIPTIONS_FAILURE:
          return {
            ...state,
            updating : false
          }
   case userConstants.UPDATE_LIKES_REQUEST:
      return{
        ...state,
        updating:true
      }
    case userConstants.UPDATE_LIKES_SUCCESS:
      return {
        ...state,
        user : action.user,
        updating:false
      }
    case userConstants.UPDATE_LIKES_FAILURE:
      return {
        ...state,
        updating:false
      }
      case userConstants.LOGIN_WITH_FACEBOOK_REQUEST:
        return{
          ...state,
          loggingInWithFacebook:true,
          updating:true
        }
      case userConstants.LOGIN_WITH_FACEBOOK_SUCCESS:
        return {
          ...state,
          user : action.user,
          token : action.user.token,
          refreshToken : action.user.refreshToken,
          loggedIn:true,
          loggingInWithFacebook:false,
          modalVisible: false,
          updating:false
        }
      case userConstants.LOGIN_WITH_FACEBOOK_FAILURE:
        return {
          ...state,
          loggingInWithFacebook: false,
          modalVisible:false,
          updating:false
        }

        case userConstants.LOGIN_WITH_GOOGLE_REQUEST:
        return{
          ...state,
          loggingInWithGoogle:true,
          updating:true
        }
      case userConstants.LOGIN_WITH_GOOGLE_SUCCESS:
        return {
          ...state,
          user : action.user,
          loggedIn:true,
          loggingInWithGoogle:false,
          modalVisible: false,
          token: action.user.token,
          refreshToken : action.user.refreshToken,
          updating:false
        }
      case userConstants.LOGIN_WITH_GOOGLE_FAILURE:
        return {
          ...state,
          loggingInWithGoogle: false,
          modalVisible:false,
          updating:false
        }

      case userConstants.UPDATE_USER_UPVOTES_SUCCESS:
        let newUser=state.user
        if(action.payload.mode === 'UPVOTE' && !newUser.body.liked.includes(action.payload.contributionId) && !newUser.body.disliked.includes(action.payload.contributionId)  ){
          newUser.body.liked.push(action.payload.contributionId)
        }else if(action.payload.mode === 'REMOVE_UPVOTE'){
          newUser.body.liked=state.user.body.liked.filter(contributionId => contributionId !== action.payload.contributionId)
        }else if(action.payload.mode === 'DOWNVOTE'&& !newUser.body.disliked.includes(action.payload.contributionId) && !newUser.body.liked.includes(action.payload.contributionId)  ){
          newUser.body.disliked.push(action.payload.contributionId)
        }else if(action.payload.mode === 'REMOVE_DOWNVOTE'){
          newUser.body.disliked=state.user.body.liked.filter(contributionId => contributionId !== action.payload.contributionId)
        }
       
        return{
          ...state,
          user:newUser
        }
        case userConstants.UPDATE_BOUGHT_EXPERIENCES_REQUEST:
          return{
            ...state,
            updating : true
          }
        case userConstants.UPDATE_BOUGHT_EXPERIENCES_SUCCESS:
          let user = state.user
          action.experiences.forEach(experience => {
            if(!user.body.bought.find(element => element.id === experience.id)){
              user.body.bought.push(experience)
            }
  
          });
    
          return{
            ...state,
            updating : false,
            user
          }
          case userConstants.UPDATE_BOUGHT_EXPERIENCES_FAILURE:
    
          return{
            ...state,
            updating : false
          }
          case userConstants.UPDATE_SHOPPING_CART_REQUEST:
            return{
              ...state,
              updating:true
            }
          case userConstants.UPDATE_SHOPPING_CART_SUCCESS:
            let updatedCartUser=state.user
            updatedCartUser.body.cartItems = action.items
            return {
              ...state,
              user:updatedCartUser,
              updating:false
            }
          case userConstants.UPDATE_SHOPPING_CART_FAILURE:
            return {
              ...state,
              updating:false
            }
          case userConstants.RESET_UPDATED:
            return {
              ...state,
              updated:false
            }
          case userConstants.UPDATE_ADDRESS_REQUEST:
            return{
              ...state,
              updating : true
            }
          case userConstants.UPDATE_ADDRESS_SUCCESS:
            const updateAddressUuser = state.user
            updateAddressUuser.body.address = action.payload.address
            updateAddressUuser.body.phoneNumber = action.payload.phoneNumber
            return{
              ...state,
              user : updateAddressUuser,
              updating:false
            }
          case userConstants.UPDATE_ADDRESS_FAILURE:
            return {
            ...state,
            logInError : action.error,
            updating: false
            }
            case userConstants.GET_NEW_TOKEN_REQUEST:
              return{
                ...state,
                updating : true
              }
            case userConstants.GET_NEW_TOKEN_SUCCESS:

                 return{
                ...state,
                token : action.payload.token,
                refreshToken : action.payload.refreshToken,
                updating:false
              }
            case userConstants.GET_NEW_TOKEN_FAILURE:
              return {
              ...state,
              error : action.error,
              updating: false
              }
          
    default:
      return state
  }
}