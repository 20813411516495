
import { communitiesConstants } from '../_constants';
import { communitiesService } from '../_services';
import { alertActions } from './';

/**
 * exporting a communitiesActions object with the defined functions that dispatch the communties actions as its methods
* @exports Action-creators__Communities
 */
export const communitiesActions = {
    getAll
};

/**
 * This is the comments getAll action creator 
 * it handles dispatching actions to inform you about the state of request and manages the response to the request
 * 
 * HOW IT WORKS :
 * 1) dispatch a request action : informing that the request is pending
 * 2) calls the communitiesService with the appropriate method
 * 3) when the reponse comes :
 *     - if response is successfull :
 *          {
 *              dispatch a success action with the communities as payload
 *              dispatch a success alert with a message    
 *          }
 *       else :
 *          {
 *              dispatch failure action with the error as payload
 *          }
 * 
 * @returns array of communities in DataBase
 */
function getAll() {
    return dispatch => {
        dispatch(request());

        communitiesService.getAll()
            .then(res => {
                    const communities = res.data
                    dispatch(success(communities))
                    dispatch(alertActions.success('Got communities successfull'));
                })
             .catch( error => dispatch(failure(error)))  
            
    };
    /**
     * these are the actions to be dispatched 
     */
    function request() { return { type: communitiesConstants.GETALL_REQUEST } }
    function success(communities) { return { type: communitiesConstants.GETALL_SUCCESS, communities } }
    function failure(error) { return { type: communitiesConstants.GETALL_FAILURE, error } }
}


