import React from 'react'
import './ProductsPage.css'
import {AutoComplete,Select,Slider,Button } from 'antd'
import {ExperienceCard} from '../Shared/ExperienceCard/ExperienceCard'
import {experiencesActions, redirectionActions, userActions} from '../../_actions'
import {connect } from 'react-redux'
import {messaging} from '../../firebase'
import { experiencesService} from '../../_services'
import Axios from 'axios'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { serverConstants } from '../../_constants'
import {isBrowser,isMobile} from 'react-device-detect'

/**
 * The Alien dimension ExperienceS catalog Page
 *
 * @exports Experiences-catalog__Page
 */

class ProductsPage extends React.Component {

    constructor(props){
        super(props)

        this.state ={
            renderedExperiences : [],
            hiddenExperiences : [],
            value : '',
            searchOptions:[],
            count:0,
            dataSource :[],
            visible : false,
            loaded:false,
            moreExperiences : false,
            selectedCategory :'',
            scrollValue : 0,
            searchTimeout :null,
            sliderValue: [0, 0],
            maxPrice:0,
            lastExperienceCreatedOn : 0,
            loadingMoreExperiences : false,
            subscribedToExperiences :  null,
            viewedExperienceIndex :null,
            toasterVisible : false,
            toasterStatus :null,
            toasterMessage : ''
        }
    }


    componentWillReceiveProps(prevProps, prevState){
        if(prevProps!== this.props){
        }

        this.setState({
            renderedExperiences : this.props.experiences.items
        })
    }

    notificationPermission = async ()=> {
        let permissionGranted = false;
        try {

            if (Notification.permission !== 'granted') {
                await messaging.requestPermission();
            }

            if (localStorage.getItem("INSTANCE_TOKEN")) {
                permissionGranted = true;
            } else {
                const token = await messaging.getToken(); // returns the same token on every invocation until refreshed by browser

                localStorage.setItem("INSTANCE_TOKEN", token);
                permissionGranted = true;
            }
        } catch (err) {
            console.log(err);

        } finally {
            return permissionGranted;
        }
      }


      isInViewport=(el)=> {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    
        );
    }


    handleUserSubscriptionToNotifications = async ( topic ) => {
        /*
        const newSubscriptions = []

        if(this.props.authentication.user.body.subscriptions.length >1){
             newSubscriptions = this.props.authentication.user.body.subscriptions.filter(subTopic =>  subTopic !== topic)

        }


        const req= {
            id :this.props.authentication.user.id ,
            subscriptions : newSubscriptions,
            token : this.props.authentication.token
            }
        this.props.updateSubscriptions(req)
        */
       const permission = await this.notificationPermission()

        if(this.props.loggedIn && permission){

            const subscriptions = this.props.user.body.subscriptions
            subscriptions.push(topic)
            const req = {
            user : {
                id: this.props.user.id,
                token : this.props.token,
              subscriptions
            },
            subscription : {
                token : localStorage.getItem('INSTANCE_TOKEN'),
                topic : "EXPERIENCES"
            }
          }

          this.props.updateSubscriptions(req,'subscribe')

        }
        else if(permission){

            Axios.put(`${serverConstants.DOMAIN_NAME}/notifications/subscribe`,{
                token : localStorage.getItem("INSTANCE_TOKEN"),
                topic : "EXPERIENCES"
               })
               .then(res =>{
                    this.setState({subscribedToExperiencesNotifications:true})
                localStorage.setItem('SUBSCRIBED_TO_EXPERIENCES_NOTIFICATIONS',true)})
               .catch(err =>localStorage.setItem('SUBSCRIBED_TO_EXPERIENCES_NOTIFICATIONS',false))

        }



    }

    handleUserUnsubscriptionToNotifications = async (topic) => {

        /*
      const newSubscriptions = this.props.authentication.user.body.subscriptions
      newSubscriptions.push(topic)

      const req= {
          user :{
              id :this.props.authentication.user.id ,
              subscriptions : newSubscriptions,
              token : this.props.authentication.token
          },
          subscription : {
              topic
          }

      }
      this.props.updateSubscriptions(req)
       */


      if(this.props.loggedIn){
        const subscriptions = this.props.user.body.subscriptions.filter(sub => sub !== topic)

        const req = {
            user: {
                id: this.props.user.id,
                token : this.props.token,
                subscriptions
            },
            subscription : {
                token : localStorage.getItem('INSTANCE_TOKEN'),
                topic
            }
        }

        this.props.updateSubscriptions(req,'unsubscribe')


      }else{
          const req = {
              token: localStorage.getItem("INSTANCE_TOKEN"),
              topic:"EXPERIENCES"
          }



        Axios.put(`${serverConstants.DOMAIN_NAME}/notifications/unsubscribe`,req)
           .then(res => {
                this.setState({subscribedToExperiencesNotifications : false})

                localStorage.setItem('SUBSCRIBED_TO_EXPERIENCES_NOTIFICATIONS',false)})
           .catch(err =>console.log("couldn't unsubscribe"))

      }
    }


/**
 * this function checks whether experiences are loade if so we just retrieve them from the redux store
 * if not we call the Experiences getAll action creator and we load the experiences on the state
 * this function also loads the autocomplete search options
 *
 */
    async componentDidMount(){
        this.props.updateUrl('/products')
        document.documentElement.scrollTop = 0
        experiencesService.getmaxPrice()
            .then(res=>
                {
                    const newSliderValue= [0,res.data.body.maxPrice]
                    this.setState({sliderValue: newSliderValue,maxPrice:res.data.body.maxPrice})
                }
            )


        window.addEventListener('scroll', this.trackScrolling)
            if(this.props.experiences.loaded){
                this.setState({renderedExperiences : this.props.experiences.items, loaded : true})

            }else{

                toast("Chargement des experiences")
                this.props.getAll()
                setTimeout(()=>{
                    this.setState({renderedExperiences : this.props.experiences.items
                    ,loaded : true})


                },1500)
                if(this.props.experiences.items.length >0){
                    this.setState({lastExperienceCreatedOn : this.props.experiences.items[this.props.experiences.items.length-1].body.createdOn})
                }
            }
            setTimeout(()=>{

                experiencesService.getAll(this.state.selectedCategory,this.state.sliderValue[0],this.state.sliderValue[1],this.props.experiences.items[this.props.experiences.items.length-1].body.createdOn,this.state.value)
                .then(res => {
                    if(res.data.length > 0 ){
                        this.setState({hiddenExperiences : res.data,
                        moreExperiences : true,lastExperienceCreatedOn:res.data[res.data.length-1].body.createdOn})
                    }else{
                        this.setState({hiddenExperiences : [],moreExperiences:false})
                    }

                })
            },2000)

        const permission = await this.notificationPermission()

        let subbed
        if(this.props.loggedIn) subbed =this.props.user.body.subscriptions.includes('EXPERIENCES')
            if(permission && !subbed){

               this.handleUserSubscriptionToNotifications('EXPERIENCES')
                

                this.handleUserSubscriptionToNotifications('GENERAL')
                 


               Axios.put(`${serverConstants.DOMAIN_NAME}/notifications/subscribe`,{
                token : localStorage.getItem("INSTANCE_TOKEN"),
                topic : "GENERAL"
               })
               .then(res => localStorage.setItem('SUBSCRIBED_TO_GENERAL_NOTIFICATIONS',true))
               .catch(err => localStorage.setItem('SUBSCRIBED_TO_GENERAL_NOTIFICATIONS',false))
            }
            else{ }



    }

//

    handleSearch = (value) => {
        this.setState({value})

        clearTimeout(this.state.clearTimeout);
        this.state.clearTimeout = setTimeout(() =>{

            experiencesService.getAll(this.state.selectedCategory,this.state.sliderValue[0],this.state.sliderValue[1],0,value)
                .then(res =>{
                    if(res.data.length >0){
                        const dataSource = res.data.map(exp => exp.body.title)
                        this.setState({renderedExperiences : res.data,
                            dataSource
                        })
                        
                        experiencesService.getAll(this.state.selectedCategory,this.state.sliderValue[0],this.state.sliderValue[1],res.data[res.data.length-1].body.createdOn,value)
                            .then(res => {
                                if(res.data.length>0){
                                    this.setState({hiddenExperiences : res.data,
                                    lastExperienceCreatedOn : res.data[res.data.length-1].body.createdOn})
                                }else{
                                    this.setState({hiddenExperiences:[]})
                                }
                            })

                    }else{
                        this.setState({renderedExperiences:[],
                        dataSource : []})
                    }

                    if(this.state.hiddenExperiences.length >0){this.setState({moreExperiences:true})}
                    else{this.setState({moreExperiences:false})}
                    })
        }, 500);

            /*
          experiencesService.getByKeywords(value)
              .then(res =>{

                  this.setState({renderedExperiences: res.data})

              })
           */

    }

    trackScrolling = () => {

        this.state.renderedExperiences.forEach( (experience,index)=> {
            if(document.getElementById(`experience-${index}`) && this.isInViewport(document.getElementById(`experience-${index}`)) && isMobile)
            this.setState({viewedExperienceIndex : index})
        })

   

        if( window.innerHeight + document.documentElement.scrollTop
            === document.documentElement.scrollHeight){

                if(this.state.moreExperiences){

                    const newRenderedExperiences= this.state.renderedExperiences.concat(this.state.hiddenExperiences)
                    this.setState({loadingMoreExperiences: true,
                    renderedExperiences : newRenderedExperiences})


                    experiencesService.getAll(this.state.selectedCategory,this.state.sliderValue[0],this.state.sliderValue[1],this.state.lastExperienceCreatedOn,this.state.value)
                    .then(res => {

                        if(res.data.length>0){
                            this.setState({
                                hiddenExperiences:res.data,
                                moreExperiences: true,
                                count:res.data.length + this.state.renderedExperiences.length,
                                loadingMoreExperiences: false,
                                lastExperienceCreatedOn : res.data[res.data.length-1].body.createdOn
                            })
                        }else{
                            this.setState({moreExperiences:false})
                        }

                    })
                }

            }
      };

      



    /*

    handleSearchChnage=(e)=>{

        const renderedExperiences = this.props.experiences.items.filter(experience => experience.body.title.toUpperCase().includes(e.target.value.toUpperCase()))
       z
        this.setState({renderedExperienull
     */

      /**
      }
HEREERERERREERREERcategory
      /**
     * Handles Selecting an expeirence and shows the experience card
     * import {HeartOutlined,HeartFilled} from '@ant-design/icons'
import {Link} from 'react-router-dom'is.props.experiences.items.filter(experience => experience.body.title.toUpperCase().includes(value.toUpperCase()))

       this.setState({
                     renderedExperiences
                    })
     }

     /**
     * Handles Adding expereience to whishlist by calling the UpdateFavorite action creator
     *
     */

    

      handleSelectChange=(value)=>{
          this.setState({selectedCategory : value})
          if(value.toUpperCase()==='')
            {this.setState({renderedExperiences : this.props.experiences.items,
            selectedCategory :''})}
            else{

                experiencesService.getAll(value,this.state.sliderValue[0],this.state.sliderValue[1],0,this.state.value)
                    .then(res =>{
                        if(res.data.length >0){
                            this.setState({renderedExperiences : res.data})

                            experiencesService.getAll(value,this.state.sliderValue[0],this.state.sliderValue[1], res.data[res.data.length-1].body.createdOn,this.state.value)
                                .then(res => {
                                    if(res.data.length>0){
                                    this.setState({hiddenExperiences : res.data,
                                    lastExperienceCreatedOn : res.data[res.data.length-1].body.createdOn})
                                }else{
                                    this.setState({hiddenExperiences:[]})
                                }

                                })

                        }else{
                            this.setState({renderedExperiences:[]})
                        }

                        if(res.data.length ===6){this.setState({moreExperiences:true})}
                        else{this.setState({moreExperiences:false})}
                        })

            }
      }

      handleSliderChange= (value)=>{
            this.setState({sliderValue: value})

            experiencesService.getAll(this.state.selectedCategory,value[0],value[1],0,this.state.value)
            .then(res=>{
                if(res.data.length>0){
                    this.setState({renderedExperiences:res.data})
                    experiencesService.getAll(this.state.selectedCategory,value[0],value[1], res.data[res.data.length-1].body.createdOn,this.state.value)
                        .then(res => {
                            if(res.data.length>0){
                                this.setState({hiddenExperiences : res.data,
                                lastExperienceCreatedOn : res.data[res.data.length-1].body.createdOn})
                            }else{
                                this.setState({hiddenExperiences:[]})
                            }
                        })

            }
                else{this.setState({
                    renderedExperiences : [],
                    moreExperiences : false
                })}
                if(res.data.length<6){this.setState({moreExperiences:false})}

        }
            )          /*const newRenderedExperiences = this.props.experiences.items.filter(exp => exp.body.price>=value[0] && exp.body.price<=value[1])
          this.setState({renderedExperiences:newRenderedExperiences})*/
      }


      handleScroll = (e) => {

      }


    render() {

        const {Option} = Select

        const subscribedToExperiencesNotifications = (!this.props.loggedIn &&  localStorage.getItem('SUBSCRIBED_TO_EXPERIENCES_NOTIFICATIONS') === true) || (this.props.loggedIn && this.props.user.body.subscriptions.includes('EXPERIENCES'))
        
        if (!this.state.loaded) {
            setTimeout(()=>{
                toast.dismiss()

            },2111)

            return (<div className="loading-screen">
                <h1 className="loading">Loading products....</h1>
                
            </div>)
        }
        else {
            return (

                <div id="products-page">
                    <section id="products">
                        {/*<div className="search-bar">
                        <Search
                          placeholder="input search text"
                          enterButton="Search"
                          size="large"
                          className="search-button"
                          onChange={e => this.handleSearchChnage(e)}
                        />
                        </div>*/ }
                        <div style={{display :'flex',alignItems :'center',justifyContent:"space-between"}}>
                            <Select value={this.state.selectedCategory} className="products-page-category-selector"onChange={this.handleSelectChange}>
                              <Option value=''>Tout</Option>
                              <Option value="Science">Science</Option>
                              <Option value="Culture">Culture</Option>
                              <Option value="Technology">Technolgy</Option>
                            </Select>
                            <AutoComplete
                               dataSource={this.state.dataSource}
                               style={{margin :'3% auto', width: "90%" }}
                               value={this.state.value}
                               onSelect={this.onSelect}
                               onSearch={this.handleSearch}
                               placeholder="Rechercher..."
                             />

                         </div>

                            <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",marginTop:'0.7rem',padding:"0% 2%"}}>
                            <span className="slider-min-max">{this.state.sliderValue[0]} TND</span>
                               <Slider style={{color:"red"}} range defaultValue={[0,this.state.maxPrice]} onAfterChange={this.handleSliderChange} max={this.state.maxPrice} style={{width : "85%",margin:"0 20px"}}/>
                                <span className="slider-min-max">{this.state.sliderValue[1]} TND</span>
                            </div>



                        <div id="Products-section" className="products-section">
                        {this.state.renderedExperiences&&this.state.renderedExperiences.length>0?
                        this.state.renderedExperiences.map((experience,index) =>
                               {
                                   return<div className="product-holder" id={`experience-${index}`}>
                                      <ExperienceCard
                                      index={index}
                                      loggedIn={this.props.loggedIn}
                                      user={this.props.user} 
                                      id={experience.id}
                                      category={experience.body.category.fr}
                                      title={experience.body.title.fr}
                                      likes={experience.body.likes}
                                      description={experience.body.shortDescription.fr}
                                      price={experience.body.price}
                                      coverUrl={experience.body.coverUrl}
                                      foregroundImage={experience.body.forgroundUrl}
                                      discountPrice={experience.body.discountPrice}
                                      discountPercentage={experience.body.discountPercentage}
                                      viewedExperienceIndex={this.state.viewedExperienceIndex}

                                      />

                            </div>}):
                            <h3 style={{margin :'20% 41%'}}> 0 experiences found </h3>
                            }

                        </div>
                        {this.state.moreExperiences ? <div style={{display:'flex', width:"89%"}} >
                            <div className="product-holder-loading animate"></div>
                            <div className="product-holder-loading animate"></div>
                            <div className="product-holder-loading animate"></div>
                        </div> : null }

                    </section>
                </div>

            )
        }
    }

}

function mapState(state) {
    const { experiences,authentication} = state;
    const {user,loggedIn,token}  = authentication
    return { experiences,
        user,loggedIn,token };
}

const actionCreators = {
    getAll: experiencesActions.getAll,
    updateSubscriptions : userActions.updateSubscriptions,
    updateUrl : redirectionActions.updateUrl
};

const connectedProductsPage = connect(mapState, actionCreators)(ProductsPage);
export {connectedProductsPage as ProductsPage}




 /*
                            <div className="product-holder">
                                <Link className="product-holder-link" to={`/product/${experience.id}`}>
                                <div >
                                    <img src={experience.body.coverUrl} alt="Product-picutre" className="product-image" />
                                </div>
                                </Link>
                                <div className="product-info">
                                    <h1>{experience.body.title}</h1>
                                    <p>{experience.body.description}</p>
                                    <h2 className="price">{experience.body.price} Dinars</h2>
                                    <ul className="lessons">
                                        {experience.body.lessons.map(lesson =>
                                            <li className="lesson">
                                                <h1>{lesson.name}</h1>
                                                <p>{lesson.description}</p>
                                            </li>
                                        )}
                                    </ul>
                                    <div className="button">

                                            <Button type="primary" style={{borderRadius :"15px"}}>
                                                Enroll now
                                            </Button>

                                    {this.props.loggedIn ?
                                        (this.props.user.body.wishlists.filter(fav => fav.id === experience.id)).length===0?
                                        <Button type="primary" shape="circle" style={{zIndex : 4,background:"white", color:"red", border :"none"}}  className="add-to-favorite__button" icon={<HeartOutlined style={{fontSize : "1.5rem"}} />} onClick={()=> this.addFavorite(experience.id,experience.body.title)} />
                                        :  <Button type="primary" shape="circle" style={{zIndex : 4,background:"white", color:"red",  border :"none"}}   icon={<HeartFilled style={{fontSize : "1.5rem"}} />} onClick={()=> this.removeFavorite(experience.id)} />
                                    :null}

                                    </div>
                                </div>

                            </div>
                          */