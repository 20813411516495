import { userConstants } from '../_constants';
/**
 * exporting a function that handles the registration action
 * 
 * @param {object} state state of the registration attribute in redux store 
 * @param {object} action action dispatched from userActions creator
 * @exports Reducers__Registration
 */
export function registration(state = {}, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { registering: true };
    case userConstants.REGISTER_SUCCESS:
      return {};
    case userConstants.REGISTER_FAILURE:
      return {};
    default:
      return state
  }
}