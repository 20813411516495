import React, { useState, useEffect } from 'react'
import './NewCheckoutPage.css'
import {CheckCircleTwoTone,LoadingOutlined} from '@ant-design/icons';
import { messaging} from '../../firebase'
import { Link } from 'react-router-dom'
import { Radio,Input, Button,Form,Select } from 'antd';
import { history } from '../../_helpers'
import { connect } from 'react-redux'
import { userService } from '../../_services';
import { orderActions, redirectionActions, userActions } from '../../_actions';
import Axios from 'axios';
import { LoginWithFacebook } from '../LoginWithFacebook';
import { LoginHooks } from '../GoogleLogin/LoginHooks';
import { toast } from 'react-toastify';
import { browserName } from 'react-device-detect'
import {firebaseAnalytics} from '../../firebase'
 

const NewCheckoutPage = (props) => {
    const [paymentMode, setPaymentMode] = useState('on-delivery');
    const [editingResidencyAdress, setEditingResidencyAdress] = useState(false);
    const [editingDeliveryAdress, setEditingDeliveryAdress] = useState(false);
    const [loginSectionVisible,setLoginSectionVisible] = useState(true)
    const [residencyAdress,setResidencyAdress] = useState({
       
    })
    const [orderGenerated,setOrderGenerated] = useState(false)
    const [deliveryAdress,setDeliveryAdress] = useState({
     
    })
    const [orderId,setOrderId] = useState(null)
    const [totalWithoutDelivery,setTotalWithoutDelivery] = useState(null)
    const [delivertFees,setDeliveryFees] = useState(8)
    const [refreshCounter,setRefreshCounter] = useState(0)
    const [value, setValue] = React.useState(1);
    
    const refresh =( )=>{
      setRefreshCounter(refreshCounter+1)
    }

    const onRadioChange = e => {
      if(e.target.value === 1) {
        setDeliveryAdress({...residencyAdress ,
        recieverPhone : props.authentication.user.body.phoneNumber})
      }
      setValue(e.target.value);
    };
    
    useEffect(()=>{
      props.updateUrl('/checkoutOrder')
        document.documentElement.scrollTop = 0
        let newTotal= 0
        setTimeout(()=>{
            props.shoppingCart.items.map(product => newTotal = newTotal + product.price * product.quantity)
            setTotalWithoutDelivery(newTotal)
        },500)      
        
        setDeliveryFees(delivertFees)
       
    },[])

    useEffect(()=>{
      if(props.authentication.loggedIn){
        setResidencyAdress({...props.authentication.user.body.address,
        phoneNumber : props.authentication.user.body.phoneNumber})
        setDeliveryAdress({
          ...props.authentication.user.body.address,
          recieverPhone : props.authentication.user.body.phoneNumber
        })
      }
     
    },[props.authentication.loggedIn])


    const handleEditingResidencyAdress = ( )=>{
        setEditingResidencyAdress(true)
    }

    const handleEditingDeliveryAdress = ( )=>{
        setEditingDeliveryAdress(true)
    }

    const onChange = e => {
        setPaymentMode(e.target.value);
      };

    const notificationPermission = async ()=> {
        let permissionGranted = false;
        try {
            /* request permission if not granted */
            if (Notification.permission !== 'granted') {
                await messaging.requestPermission();
            }
            /* get instance token if not available */
            if (localStorage.getItem("INSTANCE_TOKEN")) {
                permissionGranted = true;
            } else {
                const token = await messaging.getToken(); // returns the same token on every invocation until refreshed by browser
              
                localStorage.setItem("INSTANCE_TOKEN", token);
                permissionGranted = true;
            }
        } catch (err) {
            console.log(err);
           
        } finally {
            return permissionGranted;
        }
      }
    
    
    const submitResidencyAdress=()=> {
        setEditingResidencyAdress(false)
    }


    const generateOrder = () =>{
      
          const orderItems = props.shoppingCart.items.map(product=> {
              return {
                  boughtExperienceId : product.id,
              quantity: product.quantity,
          boughtExperienceName: product.title,
          boughtExperiencePrice : product.initialPrice,
          boughtExperienceDiscountPrice:product.discountPrice,
          boughtExperienceDiscountPercentage : product.discountPercentage}
          })
          const req= {
              order : {
                  userId:props.authentication.user.id,
                  totalToPay : props.shoppingCart.total+delivertFees,
                  deliveryFees : delivertFees,
                  orderItems
              }
          }
          userService.generateOrder(req)
              .then(res => {
                  setOrderId(res.data.posted.id)
                  props.createOrder({...req.order,id:res.data.posted.id})
                  // window.location = "http://localhost:3000/#/checkoutOrder"
                   history.push(`/checkoutOrder`)
              })
              .catch(err => console.error(err))
      

      
      
      
  }
  


    

    const proceedToPayment =( )=>{
      if(!props.authentication.loggedIn)toast('Vous devez vous connecter pour passer la commande')
      else{
        if(!paymentMode){
          toast("Prière de spécifier la méthode de paimenet.")
        }{
          if(paymentMode === "on-delivery") toast('confirmation de votre commande')
          const orderItems = props.shoppingCart.items.map(product =>{
            return {
                boughtExperienceId : product.id,
                quantity : product.quantity,
                boughtExperienceName : product.title,
                boughtExperiencePrice : product.initialPrice,
                boughtExperienceDiscountPrice:product.discountPrice,
                boughtExperienceDiscountPercentage : product.discountPercentage
            }
        })
        const req = {
            order : {
                id: orderId,
                deliveryAddress :`${deliveryAdress.country} ${deliveryAdress.region} ${deliveryAdress.address} ${deliveryAdress.recieverPhone} ${deliveryAdress.zipCode}`,
                residencyAddress: `${residencyAdress.country} ${residencyAdress.region} ${residencyAdress.address} ${residencyAdress.phoneNumber} ${residencyAdress.zipCode}` ,
                sender : {
                    senderName : props.authentication.user.body.name,
                    senderSurname : props.authentication.user.body.surname,
                    senderPhoneNumber : props.authentication.user.body.phoneNumber || 25165482,
                    senderEmail : props.authentication.user.body.email
                },
                method : paymentMode,
                totalToPay : props.shoppingCart.total +delivertFees,
                deliveryFees : delivertFees,
                orderItems
            }
        }
        if(!deliveryAdress){
            alert("Prière de spécifier l'adresse de livraison")
        }else{
  
            userService.generatePaymentUrl(req)
            .then(res => {
              firebaseAnalytics.logEvent("USER_PROCEED_TO_PAYMENT");

                toast.dismiss()
                if(paymentMode === 'konnect'){
                  props.gotPayment(res.data.posted.resp.body.payment)
                  window.location.href = res.data.posted.payUrl
                }
                if(paymentMode === 'on-delivery')history.push("/order-confirmation")
            })
            .catch(err=> console.error(err)) 
        }
        }
      }
     

            
           
        
        
    }

    const onRedirectToHome = () => {
      // window.location="http://localhost:3000/#/"
         history.push('/')
    }

    const onRedirectToShoppingCart = () => {
      // window.location="http://localhost:3000/#/shoppingcart"
         history.push('/shoppingcart')
    }

    const onFinishResidencyAdress = (values) => {
     
      if(!props.authentication.user.body.address.country || !Object.is(values,props.authentication.user.body.address)){

        const req={
         
            id : props.authentication.user.id,
            token: props.authentication.token,
            address :values,
            phoneNumber:values.phoneNumber
          
        
        }
   

        if(!props.authentication.user.body.phoneNumber) userService.updatePhoneNumber({
          id:props.authentication.user.id,
          token:props.authentication.token,
          phoneNumber:values.phoneNumber
        }).then(res=>{
          props.updateAddress(req)
        })
      }
      
      setResidencyAdress(values)
      setDeliveryAdress({...values,
      recieverPhone : values.phoneNumber})
      setEditingResidencyAdress(false)
    }

    const onFinishDeliveryAdress = (values) => {
      
      setValue(0)
        setDeliveryAdress(values)
        setEditingDeliveryAdress(false)
    }

    const onFinishRegistration = async values => {
      let req = {}
      const permission = await notificationPermission()
    
      if(permission){
        req = {
          user :{
            name: values.First_Name,
            surname : values.Last_Name,
            password : values.password,
            email : values.Email,
            subscriptions:['GENERAL','EXPERIENCES'],
            phoneNumber : parseInt(values.Phone_Number),
            role : "user",
            subscriptionToken: localStorage.getItem("INSTANCE_TOKEN")
          },
          subscription : {
            token : localStorage.getItem("INSTANCE_TOKEN"),
            topic : 'GENERAL'
          }
        }
      }else {
        req = {
          user :{
            name: values.First_Name,
            surname : values.Last_Name,
            password : values.password,
            email : values.Email,
            phoneNumber : parseInt(values.Phone_Number),
            role : "user"
          },
          subscription : {
            token : ' '
          }
        }
       
      }
      
        if(req.user.name&& req.user.password && req.user.phoneNumber && req.user.surname &&req.user.email && values.password === values.retyped_password)
        {
          props.register(req)
          
        }

       
    }

    const onFinishLoggingIn =(values ) =>{
        console.log("registratoin", values)
    }

    const onShowLoginSection =()=>{
      setLoginSectionVisible(true)
    }

    const onShowRegisterSection = ()=>{
      setLoginSectionVisible((false))
    }

    const handleLogin=()=>{
      const email=document.getElementById('input-email').value
      const password = document.getElementById('input-password').value
      props.login(email,password)
    }
    const { Option } = Select;


     if(!orderGenerated && props.authentication.loggedIn){
       generateOrder()
       setOrderGenerated(true)
     }
     if(document.getElementById('checkout-main-section-left'))setTimeout(()=>{console.log("height",document.getElementById('checkout-main-section-left').offsetHeight)},2000)
    return(
        <div className="checkout-page">
                <div className="checkout__navbar">
                    <span>Checkout</span>
                    <div className="checkout__navbar__links">
                        <span className="redirect-to-home" onClick={onRedirectToHome}>Accueil</span>
                        <span>&#62;</span>
                        <span className="redirect-to-shopping-cart" onClick={onRedirectToShoppingCart}>Pannier</span>
                        <span>&#62;</span>
                        <span>Checkout</span>
                    </div>
                </div> 
                <div className="subtotal__navbar">
                    <span style={{color:"#4c4c4c"}}><b>Subtotal</b></span>
                        <span className="checkout-subtotal">&#62;{props.shoppingCart.total}</span>
                </div> 
                <div id="checkout-body" className="checkout__body">
                    <div className="checkout__main-section">
                        <div id="checkout-main-section-left" className="checkout__main-section__left">

                        <div className="adress-section register-section">
                        <div className="adress-section__header">
                            <div style={{display:"flex",alignItems:"center"}}>
                            <span className="adress-section__header__label">1- INFORMATIONS PERSONNELLES</span>
                            {props.authentication.loggedIn && <CheckCircleTwoTone style={{fontSize:"1.3rem",marginLeft:".5rem"}} twoToneColor="#52c41a" />}
                            </div>
                        </div>
                        <div className="adress-section__content">
                           {!props.authentication.loggedIn?
                           <div>
                             <div className="personal-info-section__ctas">
                               <span className="personal-info__log-in" onClick={onShowLoginSection}>Vous avez déjà un compte ? Connectez-vous</span>
                               <span className="personal-info__sign-up" onClick={onShowRegisterSection}>Vous n'avez pas de compte? Inscrivez-vous</span>

                             </div>
                                <div style={{display:"flex",padding:"0% 5%",marginTop:'3%'}}>
                                     {loginSectionVisible ?
                                      <div className="sign-in-form" style={{marginLeft:"8%",marginTop:"3%"}}>
                                      <input id="input-email" type="email" placeholder="Email" className="section-email"/> 
                                      <input id="input-password" type="password" placeholder="Mot de passe" className="section-password"/> 
                                      <div style={{display:"flex",padding:" 4%",justifyContent:"space-between",alignItems:"center"}}>
                                          <Link to="/resetpasswordrequest"><span className="forgot-password">Mot de passe oublié ?</span></Link>
                                          <button onClick={handleLogin} className="login-cta" >
                                              Se connecter
                                              {props.authentication.loggingIn && <LoadingOutlined/>}
                                              </button>
                                      </div>
                                      {!browserName.toUpperCase().includes('FACEBOOK') && <LoginHooks />}
                                      <LoginWithFacebook/>
                                  </div>
                                     :<Form
              className="register-form"
             name="normal_register"
             initialValues={{ remember: true }}
             onFinish={onFinishRegistration}
           >
               <div className="registration-input-segment">
               <Form.Item
               className="form-item"
               name="First_Name"
               rules={[{ required: true, message: 'Please input your First Name!' }]}
             >
               <Input className="form-item__input"   placeholder="Prenom" />
             </Form.Item>
               <Form.Item
                 className="form-item"
                 name="Last_Name"
                 rules={[{ required: true, message: 'Please input your Last Name!' }]}
               >
                 <Input className="form-item__input" placeholder="Nom" />
               </Form.Item>
               </div>
             
                <div className="registration-input-segment">
                <Form.Item
                 className="form-item"
                 name="Phone_Number"
                 rules={[{ required: true, message: 'Please input a valid Phone Number!' }]}
               >
                 <Input placeholder="Numero du téléphone"  className="form-item__input"/>
               </Form.Item>
    
               <Form.Item
                 className="form-item"
                 name="Email"
                 rules={[{ required: true,type:'email', message: "please input a valid Email" }]}
               >
                 <Input  placeholder="Adresse e-mail" className="form-item__input" />
                
               </Form.Item>
                </div>
               
            
                <div className="registration-input-segment">
                <Form.Item
                 className="form-item"
                 name="password"
                 rules={[{ required: true, message: 'Please input your Password!' }]}
               >
                 <Input
                   className="form-item__input"
                   type="password"
                   placeholder="mot de passe"
                 />
               </Form.Item>

               <Form.Item
                 className="form-item"
                 name="retyped_password"
                 rules={[{ required: true, message: 'Please Re-Type Password!' }]}
               >
                 <Input
                   className="form-item__input"
                   type="password"
                   placeholder="Re-saisir le mot de passe"
                 />
               </Form.Item>
                </div>            
               
    
               <Form.Item name="Gender" rules={[{required : true, message:'select a gender'}]} >
                   <Select style={{ width : 100 }}  placeholder="Sexe">
                   <Option value="Male">Male</Option>
                   <Option value="Female">Female</Option>
                 </Select>
               </Form.Item>

             

                
                
               <Form.Item className="register-button">
                 
                 <Button type="primary" htmlType="submit" className="register-form-button" style={{width :"30%", height: 30}}>
                   Register
                   {props.authentication.registering || props.authentication.loggingIn? <LoadingOutlined/>:null}
                 </Button>
               </Form.Item>

             </Form>}
                                </div>
                            </div>
                            :
                                <div style={{display:"flex",flexDirection:"column"}}>
                                        <span><b>Nom complet : </b>{props.authentication.user.body.name} {props.authentication.user.body.surname}</span>
                                        <span><b>Email : </b>{props.authentication.user.body.email}</span>
                                        <span><b>Numéro de téléphone : </b>{props.authentication.user.body.phoneNumber}</span>
                                </div>
                            }
                            
                        </div>
                    </div>

                        <div className="adress-section">
                        <div className="adress-section__header">
                            <div style={{display:"flex",alignItems:"center"}}>
                            <span className="adress-section__header__label">2- ADRESSE DE RÉSIDENCE</span>
                            {props.authentication.loggedIn && !editingResidencyAdress && props.authentication.user.body.address.zipCode && <CheckCircleTwoTone style={{fontSize:"1.3rem",marginLeft:".5rem"}} twoToneColor="#52c41a" />}
                            </div>
                            {props.authentication.loggedIn && <span className="edit-adress-button" onClick={handleEditingResidencyAdress}> Modifier </span>}
                        </div>
                        {props.authentication.loggedIn ?
                          <div className="adress-section__content">
                            
                           {editingResidencyAdress || !props.authentication.user.body.address.zipCode || !props.authentication.user.body.phoneNumber?
                                <div style={{display:"flex",alignItems:"center"}}>
                                  
                                     <Form
                                      style={{width:"100%"}}
                                    
                                      name="residency_adress"
                                      initialValues={{ ...residencyAdress,remember: true }}
                                      onFinish={onFinishResidencyAdress}
                                      layout="vertical"
                                    >
                                        <div style={{display:"flex"}}>
                                          <Form.Item
                                            style={{width:"50%"}}
                                            name="country"
                                            
                                            label="Pays"
                                            rules={[{ required: true, message: 'Spécifier votre pays de residence.' }]}
                                          >
                                            <Input   placeholder={residencyAdress.country || "Pays"} />
                                          </Form.Item>
                                            <Form.Item
                                               style={{width:"50%"}}
                                              label="Région"
                                              name="region"
                                              rules={[{ required: true, message: 'Spécifier votre region de residence.' }]}
                                            >
                                              <Input  placeholder={residencyAdress.region || "Région"}/>
                                            </Form.Item>
                                        </div>
                                        

                                        <Form.Item
                                          label="Adresse"
                                          name="address"
                                          rules={[{ required: true, message: 'Spécifier votre adresse de residence.' }]}
                                        >
                                          <Input placeholder={residencyAdress.address || "Adresse"} />
                                        </Form.Item>

                                        <Form.Item
                                          label="Numéro de téléphone"
                                          name="phoneNumber"
                                          rules={[{ required: true, message: 'Spécifier le votre uméro de téléphone.' }]}
                                        >
                                          <Input placeholder={residencyAdress.phoneNumber || "Numero de télépone "}  />
                                        </Form.Item>


                                        <Form.Item
                                        
                                          label="Code postal"
                                          name="zipCode"
                                          rules={[{ required: true, message: 'Spécifier votre code postal de residence.' }]}
                                        >
                                          <Input placeholder={residencyAdress.zipCode || "Code postal"} />
                                        </Form.Item>


                                        <Form.Item >

                                          <Button type="primary" htmlType="submit">
                                            Sauvgarder

                                          </Button>
                                        </Form.Item>
                                      </Form>
                                </div>

                            :
                                <div style={{display:"flex",flexDirection:"column"}}>
                                    <div style={{display:'flex'}}>
                                        <span><b>Pays : </b>{residencyAdress.country}</span>
                                        <span style={{marginLeft:"1rem"}}><b>Région : </b>{residencyAdress.region}</span>
                                    </div>
                                    <div>
                                    <span><b>Adresse : </b>{residencyAdress.address}</span>
                                    <span  style={{marginLeft:"1rem"}}><b>Code postal : </b>{residencyAdress.zipCode}</span>
                                    </div>    
                                   
                                </div>
                            }
                            
                        </div>: null}
                    </div>


                        <div className="adress-section">
                        <div className="adress-section__header">
                            <div style={{display:"flex",alignItems:"center"}}>
                            <span className="adress-section__header__label">3- ADRESSE DE LIVRAISON</span>
                            {props.authentication.loggedIn && !editingDeliveryAdress && <CheckCircleTwoTone style={{fontSize:"1.3rem",marginLeft:".5rem"}} twoToneColor="#52c41a" />}
                            </div>
                            {props.authentication.loggedIn && <span className="edit-adress-button" onClick={handleEditingDeliveryAdress}> Modifier </span>}
                        </div>
                        {props.authentication.loggedIn ?
                        <div className="adress-section__content">
                          <Radio.Group style={{marginBottom :"1rem"}} onChange={onRadioChange} value={value}>
                                    <Radio value={1}>Identique à l'adresse de résidence</Radio>
                                    <Radio value={2}>Spécifier une adresse différente</Radio>
                                  </Radio.Group>
                           {value === 2 || editingDeliveryAdress?
                                <div style={{display:"flex",alignItems:"center"}}>
                                     <Form
                                      style={{width:"100%"}}
                                    
                                      name="delivery_adress"
                                      initialValues={{ ...deliveryAdress,remember: true }}
                                      onFinish={onFinishDeliveryAdress}
                                      layout="vertical"
                                    >
                                        <div style={{display:"flex"}}>
                                          <Form.Item
                                            style={{width:"50%"}}
                                            name="country"
                                            
                                            label="Pays"
                                            rules={[{ required: true, message: 'Spécifier le pays du destinataire.' }]}
                                          >
                                            <Input   placeholder={deliveryAdress.country || "Pays"} />
                                          </Form.Item>
                                            <Form.Item
                                               style={{width:"50%"}}
                                              label="Région"
                                              name="region"
                                              rules={[{ required: true, message: 'Spécifier la region du destinataire.' }]}
                                            >
                                              <Input  placeholder={deliveryAdress.region || "Région"}/>
                                            </Form.Item>
                                        </div>

                                        <Form.Item
                                          label="Adresse"
                                          name="address"
                                          rules={[{ required: true, message: "Spécifier l'adresse du destinataire." }]}
                                        >
                                          <Input placeholder={deliveryAdress.address || "Adresse"} />
                                        </Form.Item>

                                        <Form.Item
                                          label="Numéro de téléphone du destinataire "
                                          name="recieverPhone"
                                          rules={[{ required: true, message: 'Spécifier le Numéro de téléphone du destinataire.' }]}
                                        >
                                          <Input placeholder={deliveryAdress.recieverPhone || "Numero du télépone du destinataire"}  />
                                        </Form.Item>

                                        <Form.Item
                                        
                                          label="Code postal"
                                          name="zipCode"
                                          rules={[{ required: true, message: "Spécifier le code postal du destinataire."}]}
                                        >
                                          <Input placeholder={deliveryAdress.zipCode || "Code postal"} />
                                        </Form.Item>


                                        <Form.Item >

                                          <Button type="primary" htmlType="submit">
                                            Sauvgarder

                                          </Button>
                                        </Form.Item>
                                      </Form>
                                </div>

                            :
                                <div style={{display:"flex",flexDirection:"column"}}>
                                    <div style={{display:'flex'}}>
                                        <span><b>Pays : </b>{deliveryAdress.country}</span>
                                        <span style={{marginLeft:"1rem"}}><b>Région : </b>{deliveryAdress.region}</span>
                                    </div>
                                    <span><b>Adresse : </b>{deliveryAdress.address}</span>
                                    <span><b>Numéro de téléphone du destinataire : </b>{deliveryAdress.recieverPhone}</span>
                                    <span><b>Code postal : </b>{deliveryAdress.zipCode}</span>
                                </div>
                            }
                            
                            </div>:null}
                           </div>
                           <div className="payment-mode-section">
                        <div className="payment-mode-section__header">
                          <div style={{display:"flex"}}>
                          <span><b>3- MODE DE PAIEMENT</b></span>
                          {props.authentication.loggedIn && paymentMode && <CheckCircleTwoTone style={{fontSize:"1.3rem",marginLeft:".5rem"}} twoToneColor="#52c41a" />}

                          </div>

                        </div>


                        <div className="payment-mode-section__content">
                            <h3 className="payment-mode-header">Quel mode de paiement choisissez-vous ?</h3>
                            <Radio.Group onChange={onChange} value={paymentMode} style={{marginTop:"2%",boxShadow:"1px 1px 1px 1px rgba(0,0,0,0.2)",padding:"2%",width:"100%"}}>
                                <Radio value={'konnect'} style={{display:"flex"}}>
                                    <div className="online-payment">
                                        <div className="payment-mode-card" style={{display:'flex',alignItems:'center'}}>
                                            <div className="master-visa-logo"></div>
                                            <h4>PAIEMENT EN LIGNE</h4>
                                        </div>
                                        <p className="payment-method-description">Payer votre commande enligne avec<br/>votre carte bancaire!</p>

                                    </div>
                                </Radio>
                                <Radio value={'on-delivery'} style={{display:"flex"}}>
                                    <div className="onsite-payment">
                                        <div className="payment-mode-card" style={{display:'flex',alignItems:"center"}}>
                                            <div className="onsite-payment-logo"></div>
                                            <h4>PAIEMENT À LA LIVRAISON</h4>
                                        </div>
                                        <p className="payment-method-description">Payer votre commande cash à la livraison!</p>
                                    </div>
                                </Radio>
                            </Radio.Group>
                     
                            
                        </div>
                    </div>


                        </div>
                        <div className="checkout__main-section__right" style={{height:document.getElementById('checkout-main-section-left') ?`${document.getElementById('checkout-main-section-left').offsetHeight+100}px`:""}} >
                            <div className="checkout-total-section">
                              <span className="side-section__label">Total</span>
                              <div className="subtotal-section">
                                  <span className="subtotal-label">Frais Alien Dimension</span>
                                  <span className="subtotal">{props.shoppingCart.total} TND</span>
                              </div>
                              <div className="dilivery-fees-section">
                                  <span className="delivery-fees-label">Frais de livraison</span>
                                  <span className="delivery-fees">8.00 TND</span>
                              </div>
                              <hr/>
                              <div className="total-section">
                                  <span className="total-label">Net à payer</span>
                                  <span className="total">{props.shoppingCart.total + 8} TND</span>
                              </div>
                              <hr/>
                              <div className="buy-cta" onClick={proceedToPayment}>Confirmer la commande</div>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
               
        </div>
    )
}

const mapState=(state)=>{
    const {shoppingCart,authentication,experiences} = state
    return{
        shoppingCart,
        authentication,
        experiences
    }
}

const actionCreators={
    gotPayment : orderActions.gotPayment,
    login : userActions.login,
    register : userActions.register,
    updateUrl : redirectionActions.updateUrl,
    updateAddress : userActions.updateAddress,
    createOrder : orderActions.createOrder
}

const connectedCheckoutPage = connect(mapState,actionCreators)(NewCheckoutPage)
export {connectedCheckoutPage as NewCheckoutPage}