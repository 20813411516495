
import { shoppingCartConstants } from '../_constants';



export const shoppingCartActions = {
    addToCart,
    removeFromCart,
    clearShoppingCart,
    setShoppingCart,
    deleteItem,
    setBillingShoppingCart
};

function addToCart(product) {
    return dispatch => {
      

   
                    dispatch(addToCart(product))
                  
            
    };
    /**
     * these are the actions to be dispatched 
     */
    
    function addToCart(product) { return { type: shoppingCartConstants.ADD, product } }
    
}

function removeFromCart(product) {
    return dispatch => {
      

   
                    dispatch(removeFromCart(product))
                  
            
    };
    /**
     * these are the actions to be dispatched 
     */
    
    function removeFromCart(product) { return { type: shoppingCartConstants.DELETE, product } }
    
}


function clearShoppingCart() {
    return dispatch => {
      

   
                    dispatch(clearShoppingCart())
                  
            
    };
    /**
     * these are the actions to be dispatched 
     */
    
    function clearShoppingCart() { return { type: shoppingCartConstants.CLEAR_SHOPPING_CART } }
    
}

function setShoppingCart(shoppingCart){

    return dispatch => {
        dispatch(setShoppingCart(shoppingCart))
    }
    
    function setShoppingCart(shoppingCart) { return { type: shoppingCartConstants.SET_SHOPPING_CART,shoppingCart } }
}


function deleteItem(title){

    return dispatch => {
        dispatch(deleteItem(title))
    }
    
    function deleteItem(title) { return { type: shoppingCartConstants.DELETE_ITEM_FROM_CART,title } }
}


function setBillingShoppingCart(shoppingCartContent){

    return dispatch => {
        dispatch(setBillingShoppingCart(shoppingCartContent))
    }
    
    function setBillingShoppingCart(shoppingCartContent) { return { type: shoppingCartConstants.SET_BILLING_SHOPPING_CART,shoppingCartContent } }
}