import {notificationsConstants } from '../_constants'

export const notificationsActions = {
    pushNotification,
    popNotificationAt
};

function pushNotification(notification) {
    return dispatch => {
      

   
                    dispatch(pushNotification(notification))
                  
            
    };
    /**
     * these are the actions to be dispatched 
     */
    
    function pushNotification(notification) { return { type: notificationsConstants.PUSH_NOTIFICATION,notification } }
    
}




function popNotificationAt(index) {
    return dispatch => {
      

   
                    dispatch(popNotificationAt(index))
                  
            
    };
    /**
     * these are the actions to be dispatched 
     */
    
    function popNotificationAt(index) { return { type: notificationsConstants.POP_NOTIFICATION,index } }
    
}
