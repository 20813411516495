import React, { useState, useEffect } from 'react'
import './EmailVerification.css'
import {history} from '../../_helpers'

const EmailVerification = (props) => {

     useEffect(()=>{
         document.getElementById('navbar').style.display = ""
         document.getElementById('footer').style.display = ""
        setTimeout(()=>{
            // window.location= "http://localhost:3000/#/redirect"
             history.push('/redirect')
        },7000)
     })

    return (
        <div className="email-verification-page">
            <div className="email-verification__card">
                <p>We've sent you a verification email.Please check your inbox to verify and proceed to enjoy the full experience</p>
            </div>
        </div>
    )
}

export {EmailVerification}