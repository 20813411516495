import React from 'react'
import './NotificationsPage.css'
import { notificationsService} from '../../_services'
import { Button } from 'antd'
import {connect} from 'react-redux'
import {history } from '../../_helpers'
import { notificationsActions } from '../../_actions'


const NotificationsPage = ( props ) => {


    const handlePush =( ) => {
        const req = {
            "body": {
                "text" : "A new Experience has been added",
                "title":"NEW EXPERIENCE",
                "id" : "hello",
                "source" :{
                    "type": "EXPERIENCE"
                }
            },
            "topic" : "EXPERIENCES"
        }

        notificationsService.pushNotification(req)
            .then(res => console.log("success"))
            .catch(err => console.log("error"))
    }


    const handleRedirection = (title,index) => {
        if(title.includes("EXPERIENCE"))history.push("/products") 
        props.popNotificationAt(index)

    }

    return(
        <div className="notificaions-page">
            <div style={{display:'flex'}}>
            <h1>Notifications</h1>
            </div>
            {props.notifications.items.map((notification,index) => <div className="notification-card" onClick={()=>handleRedirection(notification.title,index)}> 
                <h3 className="notification-title">{notification.title}</h3>
                <span>{notification.body}</span>
            </div>)}
        </div>
    )
}

const mapState= (state) => {
    const {notifications} = state
    return{
        notifications
    }
}

const actionCreators = {
    pushNotification: notificationsActions.pushNotification,
    popNotificationAt : notificationsActions.popNotificationAt
}

const connectedNotificationsPage = connect(mapState,actionCreators)(NotificationsPage)
export {connectedNotificationsPage as NotificationsPage }