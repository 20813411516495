/**
 * Defining and exporting userConstant to unify and simplify assiging action types 
 * @exports Constants__Users
 */
export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    UPDATE_FAVORITE_REQUEST: 'USERS_UPDATE_FAVORITE_REQUEST',
    UPDATE_FAVORITE_SUCCESS: 'USERS_UPDATE_FAVORITE_SUCCESS',
    UPDATE_FAVORITE_FAILURE: 'USERS_UPDATE_FAVORITE_FAILURE',
    
    SHOW_MODAL : 'SHOW_MODAL',
    HIDE_MODAL : 'HIDE_MMODAL',
    SHOW_EDIT_MODAL : 'SHOW_EDIT_MODAL',
    HIDE_EDIT_MODAL : 'HIDE_EDIT_MODAL',

    UPDATE_COMMUNITIES_REQUEST: 'USERS_UPDATE_COMMUNITIES_REQUEST',
    UPDATE_COMMUNITIES_SUCCESS: 'USERS_UPDATE_COMMUNITIES_SUCCESS',
    UPDATE_COMMUNITIES_FAILURE: 'USERS_UPDATE_COMMUNITIES_FAILURE',

    UPDATE_SUBSCRIPTIONS_REQUEST: 'USERS_UPDATE_SUBSCRIPTIONS_REQUEST',
    UPDATE_SUBSCRIPTIONS_SUCCESS: 'USERS_UPDATE_SUBSCRIPTIONS_SUCCESS',
    UPDATE_SUBSCRIPTIONS_FAILURE: 'USERS_UPDATE_SUBSCRIPTIONS_FAILURE',

    UPDATE_LIKES_REQUEST: 'USERS_UPDATE_LIKES_REQUEST',
    UPDATE_LIKES_SUCCESS: 'USERS_UPDATE_LIKES_SUCCESS',
    UPDATE_LIKES_FAILURE: 'USERS_UPDATE_LIKES_FAILURE',

    LOGIN_WITH_FACEBOOK_REQUEST: 'USERS_LOGIN_WITH_FACEBOOK_REQUEST',
    LOGIN_WITH_FACEBOOK_SUCCESS: 'USERS_LOGIN_WITH_FACEBOOK_SUCCESS',
    LOGIN_WITH_FACEBOOK_FAILURE: 'USERS_LOGIN_WITH_FACEBOOK_FAILURE',

    LOGIN_WITH_GOOGLE_REQUEST: 'USERS_LOGIN_WITH_GOOGLE_REQUEST',
    LOGIN_WITH_GOOGLE_SUCCESS: 'USERS_LOGIN_WITH_GOOGLE_SUCCESS',
    LOGIN_WITH_GOOGLE_FAILURE: 'USERS_LOGIN_WITH_GOOGLE_FAILURE',

    UPDATE_USER_UPVOTES_REQUEST: 'USERS_UPDATE_USER_UPVOTES_REQUEST',
    UPDATE_USER_UPVOTES_SUCCESS: 'USERS_UPDATE_USER_UPVOTES_SUCCESS',
    UPDATE_USER_UPVOTES_FAILURE: 'USERS_UPDATE_USER_UPVOTES_FAILURE',

    UPDATE_BOUGHT_EXPERIENCES_REQUEST: 'USERS_UPDATE_BOUGHT_EXPERIENCES_REQUEST',
    UPDATE_BOUGHT_EXPERIENCES_SUCCESS: 'USERS_UPDATE_BOUGHT_EXPERIENCES_SUCCESS',
    UPDATE_BOUGHT_EXPERIENCES_FAILURE: 'USERS_UPDATE_BOUGHT_EXPERIENCES_FAILURE',

    UPDATE_SHOPPING_CART_REQUEST: 'USERS_UPDATE_SHOPPING_CART_REQUEST',
    UPDATE_SHOPPING_CART_SUCCESS: 'USERS_UPDATE_SHOPPING_CART_SUCCESS',
    UPDATE_SHOPPING_CART_FAILURE: 'USERS_UPDATE_SHOPPING_CART_FAILURE',

    RESET_UPDATED : 'USERS_RESET_UPDATED',


    UPDATE_ADDRESS_REQUEST: 'USERS_UPDATE_ADDRESS_REQUEST',
    UPDATE_ADDRESS_SUCCESS: 'USERS_UPDATE_ADDRESS_SUCCESS',
    UPDATE_ADDRESS_FAILURE: 'USERS_UPDATE_ADDRESS_FAILURE',

    GET_NEW_TOKEN_REQUEST: 'USERS_GET_NEW_TOKEN_REQUEST',
    GET_NEW_TOKEN_SUCCESS: 'USERS_GET_NEW_TOKEN_SUCCESS',
    GET_NEW_TOKEN_FAILURE: 'USERS_GET_NEW_TOKEN_FAILURE',
   
};
